import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Button } from 'antd';
import Layout from '../../components/layout';

const NotFoundPage = () => {
  const history = useHistory();
  return (
    <Layout
      header={{
        middle: <></>,
        right: <></>,
      }}
    >
      <Row justify="center" align="middle" className="section-container">
        <Col span={24}>
          <Row justify="center" className="summary p-1 mt-4">
            <Col span={24}>
              <Row className="m-2" justify="center">
                <Typography.Text className="text-1">Halaman tidak ditemukan.</Typography.Text>
              </Row>
            </Col>
          </Row>
          <Row className="mv-2">
            <Col span={24}>
              <Button block type="primary" size="large" onClick={() => history.push('/')}>
                Kembali ke Beranda
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundPage);
