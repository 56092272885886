import React, { Component } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import AccountParticle from '@evos/account-particle';
import GeneralParticle from '@evos/general-particle';
import history from '../../utils/history';
import ENV from '../../utils/environment';

const { GetToken, GetCurrent } = AccountParticle.API.User;
const { Encrypt } = GeneralParticle.Utils.Crypt;

class PrivateRoute extends Route<RouteProps> {
  componentDidMount() {
    const isLoggedin = !!GetToken(ENV.PARTICLE_ENV);
    if (!isLoggedin) {
      history.push('/');
    } else {
      GetCurrent(ENV.PARTICLE_ENV).then((res) => {
        if (res) {
          if (!res.isEmailValid) {
            window.location.href = `${ENV.MY_URL}/verification/email?referrer=${Encrypt(
              '',
              window.location.href,
            )}`;
          }
        } else {
          history.push('/');
        }
      });
    }
  }

  render() {
    const isLoggedin = !!GetToken(ENV.PARTICLE_ENV);
    return (
      <Route
        {...this.props}
        render={(props: any) => (isLoggedin ? <Component {...props} /> : <Redirect to="/" />)}
      />
    );
  }
}

export default PrivateRoute;
