import { Reducer } from 'redux';
import ActionTypes from '../../constants/action-types';
import BaseStateDefault from '../../constants/state';
import { IGroupState } from '../../interfaces/states/group';

const initialState: IGroupState = BaseStateDefault;

const reducer: Reducer<any> = (state: IGroupState = initialState, action): IGroupState => {
  switch (action.type) {
    case ActionTypes.group.GET_GROUP_LIST_REQUEST:
    case ActionTypes.group.GET_GROUP_BY_ID_REQUEST: {
      return {
        ...state,
        requesting: true,
        errors: undefined,
      };
    }
    case ActionTypes.group.GET_GROUP_LIST_FAILED:
    case ActionTypes.group.GET_GROUP_BY_ID_FAILED: {
      return {
        ...state,
        requesting: false,
        errors: action.payload.error,
      };
    }
    case ActionTypes.group.GET_GROUP_LIST_SUCCESS: {
      return {
        ...state,
        requesting: false,
        list: action.payload.list,
      };
    }
    case ActionTypes.group.GET_GROUP_BY_ID_SUCCESS: {
      return {
        ...state,
        requesting: false,
        data: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
