import { action } from 'typesafe-actions';
import IBaseResponse from '@evos/general-particle/dist/interfaces/api/result';
import ICup from '@evos/general-particle/dist/interfaces/efc/cup';
import { Dispatch, AnyAction } from 'redux';
import history from '../../utils/history';
import Api from '../apis';
import Base from './base';
import ActionTypes from '../../constants/action-types';
import { CUP_OWNER, CUP_SECTION } from '../../constants/cup-section';

const GetList = (
  cupSection: CUP_SECTION,
  cupOwner: CUP_OWNER,
  page?: number,
  limit?: number,
  isDummy?: boolean,
  callback?: Function,
) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(
    dispatch,
    Api.cup.GetList(cupSection, cupOwner, page, limit, isDummy),
    () => {
      return action(ActionTypes.cup.GET_CUP_LIST_REQUEST, {
        cupSection,
        cupOwner,
      });
    },
    (response: IBaseResponse<Array<ICup>>) => {
      return action(ActionTypes.cup.GET_CUP_LIST_SUCCESS, {
        cupSection,
        cupOwner,
        list: response.data,
        paging: response.paging,
      });
    },
    (error: string) => {
      return action(ActionTypes.cup.GET_CUP_LIST_FAILED, {
        error,
        cupSection,
        cupOwner,
      });
    },
    callback,
  );
};

const GetDetailRequest = () => action(ActionTypes.cup.GET_CUP_DETAIL_REQUEST);
const GetDetailFailed = (error: string) => {
  if (error.includes('Data not found')) history.push('/not-found');
  return action(ActionTypes.cup.GET_CUP_DETAIL_FAILED, { error });
};
const GetDetailSuccess = (response: IBaseResponse<Array<ICup>>) =>
  action(ActionTypes.cup.GET_CUP_DETAIL_SUCCESS, { data: response.data });

const GetDetail = (id: string | number, isDummy?: boolean, callback?: Function) => (
  dispatch: Dispatch<AnyAction>,
) => {
  Base.CallAction(
    dispatch,
    typeof id === 'string'
      ? Api.cup.GetDetailBySlug(id, isDummy)
      : Api.cup.GetDetailById(id, isDummy),
    GetDetailRequest,
    GetDetailSuccess,
    GetDetailFailed,
    callback,
  );
};

export default {
  GetList,
  GetDetail,
};
