import ENVIRONMENT from '@evos/account-particle/dist/constants/environment';

const env = process.env.REACT_APP_ENV || '';

const CONFIGS = {
  LOCAL: {
    MY_URL: 'http://localhost:3004',
    API_URL: 'https://api-gtw.dev.evos.gg/cups/v1',
    PARTICLE_ENV: 'local' as ENVIRONMENT,
    FS_ENABLE: false,
    IS_DEBUG: false,
    SENTRY_ENABLE: false,
    GA_ENABLE: false,
    FBPIXEL_ENABLE: false,
    COOKIE_DOMAIN: 'localhost',
    COOKIE_SUFFIX: '_local',
  },
  DEV: {
    MY_URL: 'https://my.dev.evos.gg',
    API_URL: 'https://api-gtw.dev.evos.gg/cups/v1',
    PARTICLE_ENV: 'development' as ENVIRONMENT,
    FS_ENABLE: false,
    IS_DEBUG: false,
    SENTRY_ENABLE: true,
    GA_ENABLE: false,
    FBPIXEL_ENABLE: false,
    COOKIE_DOMAIN: '.dev.evos.gg',
    COOKIE_SUFFIX: '_dev',
  },
  STAG: {
    MY_URL: 'https://my.stag.evos.gg',
    API_URL: 'https://api-gtw.stag.evos.gg/cups/v1',
    PARTICLE_ENV: 'staging' as ENVIRONMENT,
    FS_ENABLE: false,
    IS_DEBUG: false,
    SENTRY_ENABLE: true,
    GA_ENABLE: false,
    FBPIXEL_ENABLE: false,
    COOKIE_DOMAIN: '.stag.evos.gg',
    COOKIE_SUFFIX: '_stag',
  },
  PROD: {
    MY_URL: 'https://my.evos.gg',
    API_URL: 'https://api-gtw.evos.gg/cups/v1',
    PARTICLE_ENV: 'production' as ENVIRONMENT,
    FS_ENABLE: true,
    IS_DEBUG: false,
    SENTRY_ENABLE: true,
    GA_ENABLE: true,
    FBPIXEL_ENABLE: true,
    COOKIE_DOMAIN: '.evos.gg',
    COOKIE_SUFFIX: '',
  },
};

let CONFIG = CONFIGS.LOCAL;
if (env === 'DEV') {
  CONFIG = CONFIGS.DEV;
}
if (env === 'STAG') {
  CONFIG = CONFIGS.STAG;
}
if (env === 'PROD') {
  CONFIG = CONFIGS.PROD;
}

export default CONFIG;
