import { message } from 'antd';
import { Dispatch, AnyAction } from 'redux';
import { IResponse, IResult, IError } from '@evos/general-particle/dist/interfaces/api';
import AccountParticle from '@evos/account-particle';
import ENV from '../../utils/environment';

const { Logout } = AccountParticle.API.Auth;

const handleError = (
  error: string,
  onFailed: (message: string) => any,
  dispatch: Dispatch<AnyAction>,
  notify = false,
) => {
  if (notify) message.error(error);
  dispatch(onFailed(error));
};

const ErrorResponse = (
  error: any,
  dispatch: Dispatch<AnyAction>,
  onFailed: (message: string) => any,
) => {
  if (error.response?.data?.errors) {
    const errors = error.response.data?.errors.map((x: IError) => x.message).join(',');
    handleError(
      error.response.data?.errors.map((x: IError) => x.message).join(','),
      onFailed,
      dispatch,
      error.response.status === 400 && !errors.includes('not found'),
    );
  } else {
    handleError(typeof error === 'string' ? error : 'something went wrong', onFailed, dispatch);
  }
};

const CallAction = (
  dispatch: Dispatch<AnyAction>,
  api: Promise<IResponse<any>>,
  onRequested: () => any,
  onSuccess: (data: IResult<any>) => any,
  onFailed: (message: string) => any,
  callback?: Function,
) => {
  dispatch(onRequested());
  return api
    .then((response) => {
      if (response.data?.success) {
        dispatch(onSuccess(response.data));
      } else {
        throw new Error(response.data?.errors?.map((x) => x.message).join(','));
      }
      return response;
    })
    .catch((error: any) => {
      if (error.response?.status && [401, 403].includes(error.response.status)) {
        Logout(ENV.PARTICLE_ENV);
        window.location.href = '/';
      }
      ErrorResponse(error, dispatch, onFailed);
    })
    .then((response) => {
      if (callback) callback(response);
    });
};

export default {
  CallAction,
};
