/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Typography, Button, Divider, Modal, Input, List, Progress } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import moment from 'moment';
import Account from '@evos/account-particle';
import GeneralParticle from '@evos/general-particle';
import { IUser } from '@evos/general-particle/dist/interfaces/account';
import { ITeam, IPlayer } from '@evos/general-particle/dist/interfaces/efc';
import Layout from '../../components/layout';
import Actions from '../../store/actions';
import { ReduxState } from '../../store/reducers';
import { ICupState } from '../../interfaces/states/cup';
import Env from '../../utils/environment';
import ComponentForm from '../../components/form';
import { IRegisterPlayer } from '../../interfaces/requests/player';
import { IPlayerState } from '../../interfaces/states/player';
import { IField } from '../../interfaces/models/form';
import { ITeamState } from '../../interfaces/states/team';
import { player_role } from '../../constants/player';
import { DisplayPlayerData } from '../../utils/common';
import ENV from '../../utils/environment';

const { Numberize, Capitalize } = GeneralParticle.Utils.Format;
const { Encrypt } = GeneralParticle.Utils.Crypt;

interface IProps {
  cup: ICupState;
  getCupDetail: (slug: string) => Promise<any>;

  player: IPlayerState;
  registerPlayer: (data: IRegisterPlayer) => Promise<any>;

  team: ITeamState;
  getExistingTeamList: (
    gameID: number,
    limit?: number,
    page?: number,
    callback?: () => void,
  ) => Promise<any>;
  checkRegister: (cupId: string) => Promise<any>;
  editPlayer: (
    payload: any,
    teamId: number,
    playerId: number,
    callback?: (res: any) => void,
  ) => Promise<any>;
  resetPlayerList: () => void;
  resetTeamList: () => void;
}

enum SCREEN_MODE {
  form,
  survey,
}

enum MODAL_MODE {
  captain,
  team,
  additional,
  editPlayer,
}

const { GetCurrent, GetToken } = Account.API.User;

const CupPage = (props: IProps) => {
  const {
    getCupDetail,
    cup,
    registerPlayer,
    player,
    getExistingTeamList,
    team,
    checkRegister,
    resetPlayerList,
    resetTeamList,
    editPlayer,
  } = props;
  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();
  const isLoggedIn = !!GetToken(ENV.PARTICLE_ENV);
  const [captainAdditionalFields, setCaptainAdditionalFields] = React.useState<Array<any>>([]);
  const [captainAdditionalData, setCaptainAdditionalData] = React.useState<any>(undefined);
  const [teamAdditionalFields, setTeamAdditionalFields] = React.useState<Array<any>>([]);
  const [teamAdditionalData, setTeamAdditionalData] = React.useState<any>(undefined);
  const [backupPlayerData, setBackupPlayerData] = React.useState<any>(undefined);
  const [backupAdditionalFields, setBackupAdditionalFields] = React.useState<Array<any>>([]);
  const [editPlayerAdditionalFields, setEditPlayerAdditionalFields] = React.useState<Array<any>>(
    [],
  );
  const [editPlayerAdditionalData, setEditPlayerAdditionalData] = React.useState<any>(undefined);
  const [processedData, setProcessedData] = React.useState<any>(undefined);
  const [screenMode, setScreenMode] = React.useState(SCREEN_MODE.form);
  const [surveyAnswer, setSurveyAnswer] = React.useState<string | undefined>();
  const [currentUser, setCurrentUser] = React.useState<IUser | undefined>(undefined);
  const [step, setStep] = React.useState<number>(1);
  const [teamName, setTeamName] = React.useState<string>('');
  const [errorMessages, setErrorMessages] = React.useState<any>(undefined);
  const [mode, setMode] = React.useState<MODAL_MODE>(0);
  const [isModalVisible, setModalVisibility] = React.useState<boolean>(false);
  const [isVerifyEmailModalVisible, setIsVerifyEmailModalVisible] = React.useState<boolean>(false);
  const [teamPlayers, setTeamPlayers] = React.useState<Array<number> | undefined>(undefined);
  const [currentPlayer, setCurrentPlayer] = React.useState<number | undefined>(undefined);
  const [backupPlayers, setBackupPlayers] = React.useState<Array<number> | undefined>(undefined);
  const [currentBackupPlayer, setCurrentBackupPlayer] = React.useState<number | undefined>(
    undefined,
  );
  const [currentEditPlayer, setCurrentEditPlayer] = React.useState<
    { isTeam: boolean; value: number } | undefined
  >(undefined);
  const [selectedTeam, setSelectedTeam] = React.useState<ITeam | undefined>(undefined);
  const [captAdditionalFieldNames, setCaptAdditionalFieldNames] = React.useState<Array<string>>([]);
  const [backupAdditionalFieldNames, setBackupAdditionalFieldNames] = React.useState<Array<string>>(
    [],
  );
  const [editPlayerBuffer, setEditPlayerBuffer] = React.useState<{
    teamId: number;
    playerId: number;
  }>({ teamId: 0, playerId: 0 });

  const renderClosedRegistration = () => (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <img
              src={cup.data?.value?.image}
              alt={cup.data?.value?.name}
              width="100%"
              height="auto"
              className="image-container"
            />
          </Col>
        </Row>
        <Row className="section-container">
          <Col span={24}>
            <Row className="mt-2">
              <Typography.Text className="title-2">{cup.data?.value?.name}</Typography.Text>
            </Row>
            {cup.data?.value &&
            moment().isBetween(
              moment.unix(cup.data?.value.registrationEndAt),
              moment.unix(cup.data?.value.startAt),
            ) ? (
              <Row justify="start" align="middle" className="selection-process-tag">
                <Col span={24}>
                  <Row>
                    <Typography.Text>Dalam Proses Seleksi Tim</Typography.Text>
                  </Row>
                </Col>
              </Row>
            ) : undefined}
            <Row className="mt-1">
              <Typography.Text className="title-4">
                {cup.data?.value &&
                moment().isBetween(
                  moment.unix(cup.data?.value.registrationEndAt),
                  moment.unix(cup.data?.value.startAt),
                )
                  ? 'Tahap registrasi sudah selesai. Kami akan melakukan seleksi tim, Hanya tim terpilih yang akan dihubungi pihak EVOS FAMS.'
                  : ''}
                {cup.data?.value && moment().isAfter(moment.unix(cup.data?.value.startAt))
                  ? 'Tahap registrasi sudah selesai. Silahkan klik tombol dibawah untuk melihat klasemen.'
                  : ''}
                {cup.data?.value &&
                moment().isBefore(moment.unix(cup.data?.value.registrationStartAt))
                  ? 'Tahap registrasi belum dimulai.'
                  : ''}
              </Typography.Text>
            </Row>
            {cup.data?.value && moment().isAfter(moment.unix(cup.data?.value.startAt)) ? (
              <Row className="mt-1">
                <Col span={24}>
                  <Button
                    block
                    type="primary"
                    size="large"
                    onClick={() => {
                      history.push(`/${cup.data?.value?.slug}/standings/${cup.data?.value?.id}`);
                    }}
                  >
                    Lihat Klasemen
                  </Button>
                </Col>
              </Row>
            ) : undefined}
            <Row className="mt-1 mb-2">
              <Col span={24}>
                <Button
                  block
                  ghost={cup.data?.value && moment().isAfter(moment.unix(cup.data?.value.startAt))}
                  type="primary"
                  size="large"
                  onClick={() => history.push('/')}
                >
                  Kembali ke Beranda
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  React.useEffect(() => {
    if (isLoggedIn) {
      GetCurrent(Env.PARTICLE_ENV).then((x) => {
        if (x) {
          if (x.isEmailValid) {
            setCurrentUser(x);
          } else {
            window.location.href = `${ENV.MY_URL}/verification/email?referrer=${Encrypt(
              '',
              window.location.href,
            )}`;
          }
        }
      });
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    if (!cup.data.requesting && !cup.data.value) {
      getCupDetail(slug);
    } else if (cup.data.value) {
      if (
        isLoggedIn &&
        moment().isBetween(
          moment.unix(cup.data?.value.registrationStartAt),
          moment.unix(cup.data?.value.registrationEndAt),
        )
      ) {
        checkRegister(cup.data?.value.id.toString());
      }

      if (isLoggedIn && cup.data.value.game) {
        resetPlayerList();
        resetTeamList();
        getExistingTeamList(cup.data?.value?.game.id, 3, 1);
      }
    }
  }, [
    checkRegister,
    cup.data,
    getCupDetail,
    getExistingTeamList,
    isLoggedIn,
    resetPlayerList,
    resetTeamList,
    slug,
  ]);

  React.useEffect(() => {
    if (currentUser) {
      setCaptainAdditionalData((c: any) => {
        return {
          ...c,
          captainEmail: currentUser.email,
          phoneNumber: currentUser.phoneNumber.replace('+62', '0'),
        };
      });
    }
  }, [currentUser]);

  const handleCaptainData = (e: any) => {
    setCaptainAdditionalData(e);
    setErrorMessages({ ...errorMessages, playerData: '' });
    toggleModalCaptainData();
  };

  const handleTeamData = (e: any) => {
    const temp = [...teamAdditionalData];
    if (currentPlayer) temp[currentPlayer - 1] = e;
    setTeamAdditionalData(temp);
    setErrorMessages({ ...errorMessages, playerData: '' });
    toggleModalTeamData();
  };

  const handleBackupPlayerData = (e: any) => {
    const temp = [...backupPlayerData];
    if (currentBackupPlayer) temp[currentBackupPlayer - 1] = e;
    setBackupPlayerData(temp);
    toggleModalBackupPlayerData();
  };

  const handleEditPlayerData = (e: any) => {
    const temp: { email?: string; additionalData?: string } = {};
    Object.entries(e).forEach(([key, value]) => {
      if (key.includes('playerEmail') && value) {
        temp.email = value as string;
      }
      if (key.includes('inGameName') && value) {
        temp.additionalData = JSON.stringify({
          inGameName: value as string,
        });
      }
    });
    editPlayer(temp, editPlayerBuffer.teamId, editPlayerBuffer.playerId, (res) => {
      if (res?.data?.success) {
        const tempData = editPlayerAdditionalData;
        Object.entries(e).forEach(([key, value]) => {
          if (key.includes('playerEmail') && value && currentEditPlayer && teamPlayers) {
            temp.email = value as string;
            if (currentEditPlayer.isTeam) {
              tempData[currentEditPlayer.value - 1][
                `editTeamplayerEmail${currentEditPlayer?.value}`
              ] = value;
            } else {
              tempData[currentEditPlayer.value + teamPlayers.length - 1][
                `editBackupplayerEmail${currentEditPlayer?.value}`
              ] = value;
            }
          }
          if (key.includes('inGameName') && value && currentEditPlayer && teamPlayers) {
            temp.additionalData = JSON.stringify({
              inGameName: value as string,
            });
            if (currentEditPlayer.isTeam) {
              tempData[currentEditPlayer.value - 1][
                `editTeaminGameName${currentEditPlayer?.value}`
              ] = value;
            } else {
              tempData[currentEditPlayer.value + teamPlayers.length - 1][
                `editBackupinGameName${currentEditPlayer?.value}`
              ] = value;
            }
          }
        });
        setIsVerifyEmailModalVisible(true);
        setEditPlayerAdditionalData(tempData);
      }
    });
    toggleModalEditPlayer();
  };

  // store captain, team, and backup player's additional fields
  React.useEffect(() => {
    if (cup.data.value) {
      let temp = JSON.parse(cup.data.value.game?.additionalFields || '[]');
      const tempCaptAdditionalFieldNames: any = [];
      const tempCaptAdditionalFields: any = [];
      const tempTeamAdditionalFieldNames: any = [];
      const tempTeamAdditionalFields: any = [];
      const tempBackupAdditionalFieldNames: any = [];
      const tempBackupAdditionalFields: any = [];
      const tempEditPlayerAdditionalFields: any = [];
      if (temp && temp.length > 0) {
        temp.forEach((x: any) => {
          if (x.applyTo && x.applyTo.length > 0 && x.applyTo.includes(player_role.captain)) {
            tempCaptAdditionalFieldNames.push(x.name);
            tempCaptAdditionalFields.push(x);
          }
          if (x.applyTo && x.applyTo.length > 0 && x.applyTo.includes(player_role.member)) {
            tempTeamAdditionalFieldNames.push(x.name);
            tempTeamAdditionalFields.push(x);
          }
          if (x.applyTo && x.applyTo.length > 0 && x.applyTo.includes(player_role.backup)) {
            tempBackupAdditionalFieldNames.push(x.name);
            tempBackupAdditionalFields.push(x);
          }
          if (!x.applyTo || (x.applyTo && x.applyTo.length === 0)) {
            tempCaptAdditionalFieldNames.push(x.name);
            tempCaptAdditionalFields.push(x);
            tempTeamAdditionalFieldNames.push(x.name);
            tempTeamAdditionalFields.push(x);
            tempBackupAdditionalFieldNames.push(x.name);
            tempBackupAdditionalFields.push(x);
          }
          if (x.name.includes('inGameName')) {
            tempEditPlayerAdditionalFields.push(x);
          }
        });
      }
      setCaptAdditionalFieldNames(tempCaptAdditionalFieldNames);
      setBackupAdditionalFieldNames(tempBackupAdditionalFieldNames);

      // captain's additionalFields
      const captainAdditionalFieldsTemp: Array<IField> = [
        {
          name: 'captainEmail',
          type: 'email',
          label: 'Email Kapten',
          disabled: true,
        },
        {
          name: 'phoneNumber',
          type: 'tel',
          label: 'Nomor HP Aktif',
          helpText: 'Kami akan menghubungi Kamu melalui SMS / WhatsApp',
        },
        {
          name: 'gender',
          type: 'select',
          label: 'Gender',
          options: [
            { value: 'm', label: 'Pria (Male)' },
            { value: 'f', label: 'Wanita (Female)' },
          ],
        },
        {
          name: 'age',
          type: 'number',
          label: 'Umur (Age)',
        },
        ...tempCaptAdditionalFields,
      ];
      setCaptainAdditionalFields(captainAdditionalFieldsTemp);

      let tempPlayerEmail: any = [];
      if (cup.data.value?.isCollectingEmail) {
        tempPlayerEmail = [
          {
            name: 'playerEmail',
            type: 'email',
            label: 'Email',
          },
        ];
      }

      // Team member's additionalFields
      const teamAdditionalFieldsTemp = [...tempPlayerEmail, ...tempTeamAdditionalFields];
      setTeamAdditionalFields(teamAdditionalFieldsTemp);
      const tempTeamPlayers: Array<number> = [];
      const tempTeamPlayersDataArray: Array<any> = [];
      for (let i = 0; i < (cup.data?.value?.game?.playersPerTeam || 1) - 1; i++) {
        const tempTeamPlayersData: { [x: string]: any } = {};
        tempTeamPlayers.push(i + 1);
        teamAdditionalFieldsTemp.forEach((f) => {
          tempTeamPlayersData[`${f.name}${i + 1}`] = '';
        });
        tempTeamPlayersDataArray.push(tempTeamPlayersData);
      }
      setTeamPlayers(tempTeamPlayers);
      setTeamAdditionalData(tempTeamPlayersDataArray);

      // Backup player's additionalFields
      const backupAdditionalFieldsTemp = [...tempPlayerEmail, ...tempBackupAdditionalFields];
      setBackupAdditionalFields(backupAdditionalFieldsTemp);
      if (
        cup.data?.value?.game?.backupPlayersPerTeam &&
        cup.data?.value?.game?.backupPlayersPerTeam > 0
      ) {
        const tempBackupPlayers: Array<number> = [];
        const tempBackupPlayersDataArray: Array<any> = [];
        for (let i = 0; i < cup.data?.value?.game?.backupPlayersPerTeam; i++) {
          const tempBackupPlayersData: { [x: string]: any } = {};
          tempBackupPlayers.push(i + 1);
          backupAdditionalFieldsTemp.forEach((f) => {
            tempBackupPlayersData[`additional${f.name}${i + 1}`] = '';
          });
          tempBackupPlayersDataArray.push(tempBackupPlayersData);
        }
        setBackupPlayers(tempBackupPlayers);
        setBackupPlayerData(tempBackupPlayersDataArray);
      }

      // Edit player's additionalFields
      const editPlayerAdditionalFieldsTemp = [
        ...tempEditPlayerAdditionalFields,
        ...tempPlayerEmail,
      ];
      setEditPlayerAdditionalFields(editPlayerAdditionalFieldsTemp);
      let playerData: Array<IPlayer> | undefined = undefined;
      if (player.list && player.list.length > 0) {
        playerData = player.list;
      } else if (player.data) {
        playerData = player.data.team?.players;
      }
      const nonCaptData = playerData?.filter((p) => !p.isCaptain);
      const teamMemberData = nonCaptData
        ?.filter((p) => {
          const temp = JSON.parse(p.additionalData);
          if (!temp.isAdditionalPlayer) return p;
        })
        .sort((a, b) => (a.id <= b.id ? -1 : 1));
      const backupPlayerData = nonCaptData?.filter((p) => {
        const temp = JSON.parse(p.additionalData);
        if (temp.isAdditionalPlayer) return p;
      });
      const tempArray: Array<any> = [];
      teamMemberData?.forEach((x, i) => {
        const temp: { [y: string]: any } = {};
        const parsedAdditionalData = x.additionalData ? JSON.parse(x.additionalData) : undefined;
        editPlayerAdditionalFields.forEach((z) => {
          if (z.name.includes('playerEmail')) temp[`editTeam${z.name + (i + 1)}`] = x.invitedEmail;
          if (z.name.includes('inGameName'))
            temp[`editTeam${z.name + (i + 1)}`] = parsedAdditionalData
              ? parsedAdditionalData['inGameName']
              : undefined;
        });
        tempArray.push(temp);
      });
      backupPlayerData?.forEach((x, i) => {
        const temp: { [y: string]: any } = {};
        const parsedAdditionalData = x.additionalData ? JSON.parse(x.additionalData) : undefined;
        editPlayerAdditionalFields.forEach((z) => {
          if (z.name.includes('playerEmail'))
            temp[`editBackup${z.name + (i + 1)}`] = x.invitedEmail;
          if (z.name.includes('inGameName'))
            temp[`editBackup${z.name + (i + 1)}`] = parsedAdditionalData
              ? parsedAdditionalData['inGameName']
              : undefined;
        });
        tempArray.push(temp);
      });
      setEditPlayerAdditionalData(tempArray);
    }
  }, [
    JSON.stringify(cup.data),
    JSON.stringify(editPlayerAdditionalFields),
    JSON.stringify(player.data),
    JSON.stringify(player.list),
  ]);

  const toggleModalCaptainData = () => {
    setModalVisibility(!isModalVisible);
    setMode(MODAL_MODE.captain);
  };

  const toggleModalTeamData = (p?: number) => {
    setModalVisibility(!isModalVisible);
    setMode(MODAL_MODE.team);
    if (p) setCurrentPlayer(p);
  };

  const toggleModalBackupPlayerData = (p?: number) => {
    setModalVisibility(!isModalVisible);
    setMode(MODAL_MODE.additional);
    if (p) setCurrentBackupPlayer(p);
  };

  const toggleModalEditPlayer = (
    p?: number,
    teamId?: number,
    playerId?: number,
    isTeam?: boolean,
  ) => {
    setModalVisibility(!isModalVisible);
    setMode(MODAL_MODE.editPlayer);
    if (p && isTeam) setCurrentEditPlayer({ isTeam, value: p });
    if (teamId && playerId) setEditPlayerBuffer({ teamId, playerId });
  };

  const renderEmpty = () => (
    <Row justify="center" className="empty-container">
      <Col span={24}>
        <Row className="m-2" justify="center">
          <Typography.Text className="text-1">
            Belum ada tim yang terdaftar sebelumnya.
          </Typography.Text>
        </Row>
      </Col>
    </Row>
  );

  const handleCreateNew = () => {
    setStep(2);
    setSelectedTeam(undefined);
    setErrorMessages(undefined);
    setTeamName('');
    const temp = JSON.parse(cup.data?.value?.game?.additionalFields || '[]');
    const tempCaptData: { [x: string]: any } = {};
    if (temp && temp.length > 0) temp.forEach((x: any) => (tempCaptData[`${x.name}`] = ''));
    if (currentUser) {
      setCaptainAdditionalData({
        ...tempCaptData,
        captainEmail: currentUser.email,
        phoneNumber: currentUser.phoneNumber.replace('+62', '0'),
      });
    }
    const tempTeamPlayersDataArray: Array<any> = [];
    for (let i = 0; i < (cup.data?.value?.game?.playersPerTeam || 1) - 1; i++) {
      const tempTeamPlayersData: { [x: string]: any } = {};
      teamAdditionalFields.forEach((f) => {
        tempTeamPlayersData[`${f.name}${i + 1}`] = '';
      });
      tempTeamPlayersDataArray.push(tempTeamPlayersData);
    }
    setTeamAdditionalData(tempTeamPlayersDataArray);
  };

  const handleSelectTeam = (team: ITeam) => {
    setTeamName(team.name);
    const tempCaptainData = team.players?.find((x) => x.isCaptain);
    const tempCaptainAddData = JSON.parse(tempCaptainData?.additionalData || '{}');
    setCaptainAdditionalData({
      ...tempCaptainAddData,
      phoneNumber: tempCaptainData?.phoneNumber,
      captainEmail: tempCaptainData?.invitedEmail,
    });
    const tempPlayersData = team.players?.filter((x) => !x.isCaptain);
    const tempTeamPlayersDataArray: Array<any> = [];
    if (tempPlayersData) {
      for (let i = 0; i < tempPlayersData?.length; i++) {
        const tempPlayerData = tempPlayersData[i];
        const tempPlayersAddData = JSON.parse(tempPlayerData.additionalData);
        const tempTeamPlayersData: { [x: string]: any } = {};
        teamAdditionalFields.forEach((f) => {
          let data = tempPlayerData.invitedEmail;
          Object.entries(tempPlayersAddData).forEach(([key, value]) => {
            if (key === f.name) data = value as string;
          });
          tempTeamPlayersData[`${f.name}${i + 1}`] = data;
        });
        tempTeamPlayersDataArray.push(tempTeamPlayersData);
      }
    }
    setTeamAdditionalData(tempTeamPlayersDataArray);
    setSelectedTeam(team);
    setErrorMessages(undefined);
    setStep(2);
  };

  const renderStepOne = () => {
    return (
      <Row>
        <Col span={24}>
          <Row wrap={false} className="progress-wrapper">
            <Col flex="100px">
              <Row align="middle">
                <Typography.Text className="common-text">Langkah 1 / 3</Typography.Text>
              </Row>
            </Col>
            <Col flex="auto">
              <Row align="middle">
                <Progress
                  strokeLinecap="square"
                  strokeColor="#62E05F"
                  percent={33}
                  showInfo={false}
                  className="progress-bar"
                />
              </Row>
            </Col>
          </Row>
          <Row className="padding-progress-bar">
            <Col span={24}>
              <img
                src={cup.data?.value?.image}
                alt={cup.data?.value?.name}
                width="100%"
                height="auto"
              />
            </Col>
          </Row>
          <Row className="section-container">
            <Col span={24}>
              <Row className="mv-2">
                <Typography.Text className="title-2">{cup.data?.value?.name}</Typography.Text>
              </Row>
              <Row className="card-container" wrap={false}>
                <Col span={24}>
                  <Row>
                    <Col flex="60px">
                      <Row justify="start" className="mh-1 mt-1">
                        <div className="icon-add-team text-size-60" />
                      </Row>
                    </Col>
                    <Col flex="auto" className="column-right">
                      <Row className="mt-1 mr-1">
                        <Typography.Text className="title-2">Daftar tim baru</Typography.Text>
                      </Row>
                      <Row className="mt-1 mr-1">
                        <Typography.Text className="common-text">
                          Buat tim baru dengan mudah
                        </Typography.Text>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="m-1">
                    <Button block type="primary" size="large" onClick={handleCreateNew}>
                      Buat tim baru
                    </Button>
                  </Row>
                </Col>
              </Row>
              <Row className="list-container-cup mv-2">
                <Col span={24}>
                  <List
                    grid={{
                      column: 1,
                    }}
                    dataSource={team.list}
                    renderItem={(item: ITeam) => {
                      return (
                        <List.Item>
                          <Row className="card-container">
                            <Col span={24}>
                              <Row className="m-1">
                                <Typography.Text className="title-2">{item.name}</Typography.Text>
                              </Row>
                              <Row className="mh-1">
                                <Typography.Text className="common-text">
                                  {item.players?.map((x, i) => {
                                    const data = JSON.parse(x.additionalData);
                                    if (i === (item.players && item.players?.length - 1)) {
                                      if (x.isCaptain) return ` ${data.inGameName} (c)`;
                                      return ` ${data.inGameName}`;
                                    }
                                    if (x.isCaptain) return `${data.inGameName} (c), `;
                                    return `${data.inGameName}, `;
                                  })}
                                </Typography.Text>
                              </Row>
                              <Row className="m-1">
                                <Button
                                  block
                                  type="primary"
                                  size="large"
                                  onClick={() => handleSelectTeam(item)}
                                >
                                  Gunakan Tim
                                </Button>
                              </Row>
                            </Col>
                          </Row>
                        </List.Item>
                      );
                    }}
                    header={
                      <Row className="list-header">
                        <Col>
                          <Row justify="start">
                            <Typography.Text>Tim Anda Sebelumnya</Typography.Text>
                          </Row>
                        </Col>
                      </Row>
                    }
                    loading={team.requesting}
                    footer={
                      team.paging?.hasNext ? (
                        <Button
                          ghost
                          type="primary"
                          size="large"
                          block
                          onClick={() =>
                            cup.data.value?.game &&
                            team.paging &&
                            getExistingTeamList(
                              cup.data.value?.game?.id,
                              team.paging?.limit,
                              team.paging?.currentPage + 1,
                            )
                          }
                        >
                          Tampilkan lebih banyak
                        </Button>
                      ) : undefined
                    }
                    locale={{ emptyText: renderEmpty() }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const renderStepTwo = () => {
    const captAdditionalDatas = Object.keys(captainAdditionalData)
      .filter((key) => !['ingameid', 'ingamename', 'captainemail'].includes(key.toLowerCase()))
      .map((key) => captainAdditionalData[key]);
    return (
      <Row>
        <Col span={24}>
          <Row wrap={false} className="progress-wrapper">
            <Col flex="100px">
              <Row align="middle">
                <Typography.Text className="common-text">Langkah 2 / 3</Typography.Text>
              </Row>
            </Col>
            <Col flex="auto">
              <Row align="middle">
                <Progress
                  strokeLinecap="square"
                  strokeColor="#62E05F"
                  percent={66}
                  showInfo={false}
                  className="progress-bar"
                />
              </Row>
            </Col>
          </Row>
          <Row className="section-container padding-progress-bar">
            <Col span={24}>
              <Row className="mt-2 mb-1 pb--5">
                <Typography.Text className="title-1">
                  {selectedTeam ? 'Gunakan Tim Sebelumnya' : 'Buat Tim Baru'}
                </Typography.Text>
              </Row>
              <Row className="mb-1">
                <Typography.Text className="text-size-18 text-height-21 text-weight-regular">
                  Tim
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text className="title-4">
                  Nama Tim<span className="text-color-red">*</span>
                </Typography.Text>
              </Row>
              <Row className="mt-1">
                <Input
                  onChange={(e) => {
                    setTeamName(e.target.value);
                    setErrorMessages({ ...errorMessages, teamName: '' });
                  }}
                  value={teamName}
                  disabled={!!selectedTeam}
                  placeholder="Masukkan nama tim..."
                />
              </Row>
              {errorMessages?.teamName ? (
                <Row className="mt--5">
                  <Typography.Text className="error-text">{errorMessages.teamName}</Typography.Text>
                </Row>
              ) : undefined}
              <Row className="mt-2">
                <Typography.Text className="text-size-18 text-height-21 text-weight-regular">
                  Pemain<span className="text-color-red">*</span>
                </Typography.Text>
              </Row>
              {errorMessages?.playerData ? (
                <Row className="mt--5">
                  <Typography.Text className="error-text">
                    {errorMessages.playerData}
                  </Typography.Text>
                </Row>
              ) : undefined}
              <Row className="player-card-container" onClick={() => toggleModalCaptainData()}>
                <Col span={24}>
                  <Row wrap={false} className="m-1">
                    <Col flex="auto">
                      <Row align="middle" className="mb--5">
                        <Typography.Text className="title-3">{`${
                          (captainAdditionalData && captainAdditionalData['inGameName']) ||
                          (captAdditionalFieldNames.includes('inGameName') ? 'Pemain 1' : 'Kapten')
                        } ${
                          (captainAdditionalData &&
                            captainAdditionalData['inGameID'] &&
                            `(${captainAdditionalData['inGameID']})`) ||
                          `${captAdditionalFieldNames.includes('inGameID') ? 'ID' : ''}`
                        }`}</Typography.Text>
                        &nbsp;
                        <div className="icon-captain-badge text-color-orange text-size-16" />
                      </Row>
                      <Row className="word-break">
                        <Typography.Text className="subtitle">{`${
                          (captainAdditionalData && captainAdditionalData['captainEmail']) ||
                          'email'
                        }`}</Typography.Text>
                      </Row>
                      {captAdditionalDatas[0] ? (
                        <Row className="word-break">
                          <Typography.Text className="subtitle">
                            {captAdditionalDatas.join(' - ')}
                          </Typography.Text>
                        </Row>
                      ) : undefined}
                    </Col>
                    <Col flex="40px">
                      <Row className="mv-1" justify="center">
                        <div className="icon-pen text-size-18" />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {teamPlayers?.map((p) => {
                const additionalDatas = Object.keys(teamAdditionalData[p - 1])
                  .filter(
                    (key) =>
                      ![`ingameid${p}`, `ingamename${p}`, `playeremail${p}`].includes(
                        key.toLowerCase(),
                      ),
                  )
                  .map((key) => teamAdditionalData[p - 1][key]);
                return (
                  <Row
                    className="player-card-container"
                    onClick={() => toggleModalTeamData(p)}
                    key={p}
                  >
                    <Col span={24}>
                      <Row wrap={false} className="m-1">
                        <Col flex="auto">
                          <Row className="mb--5">
                            <Typography.Text className="title-3">{`${
                              (teamAdditionalData && teamAdditionalData[p - 1][`inGameName${p}`]) ||
                              `Pemain ${p + 1}`
                            } ${
                              (teamAdditionalData &&
                                teamAdditionalData[p - 1][`inGameID${p}`] &&
                                `(${teamAdditionalData[p - 1][`inGameID${p}`]})`) ||
                              ''
                            }`}</Typography.Text>
                          </Row>
                          {cup.data.value?.isCollectingEmail ? (
                            <Row className="word-break">
                              <Typography.Text className="subtitle">
                                {(teamAdditionalData &&
                                  teamAdditionalData[p - 1][`playerEmail${p}`]) ||
                                  'email'}
                              </Typography.Text>
                            </Row>
                          ) : undefined}
                          {additionalDatas[0] ? (
                            <Row className="word-break">
                              <Typography.Text className="subtitle">
                                {additionalDatas.join(' - ')}
                              </Typography.Text>
                            </Row>
                          ) : undefined}
                        </Col>
                        <Col flex="40px">
                          <Row className="mv-1" justify="center">
                            <div className="icon-pen text-size-18" />
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
              {cup.data?.value?.game?.backupPlayersPerTeam &&
              cup.data?.value?.game?.backupPlayersPerTeam > 0 ? (
                <>
                  <Row className="mt-2 mb-1">
                    <Typography.Text className="text-size-18 text-height-21 text-weight-regular">
                      Pemain Cadangan (Opsional)
                    </Typography.Text>
                  </Row>
                  {backupPlayers?.map((p) => {
                    const additionalDatas = Object.keys(backupPlayerData[p - 1])
                      .filter(
                        (key) =>
                          ![`ingameid${p}`, `ingamename${p}`, `additionalplayeremail${p}`].includes(
                            key.toLowerCase(),
                          ),
                      )
                      .map((key) => backupPlayerData[p - 1][key]);
                    return (
                      <Row
                        key={p}
                        className="player-card-container"
                        onClick={() => toggleModalBackupPlayerData(p)}
                      >
                        <Col span={24}>
                          <Row wrap={false} className="m-1">
                            <Col flex="auto">
                              <Row className="mb--5">
                                <Typography.Text className="title-3">{`${
                                  (backupPlayerData &&
                                    backupPlayerData[p - 1][`additionalinGameName${p}`]) ||
                                  (cup.data?.value?.game?.backupPlayersPerTeam &&
                                  cup.data?.value?.game?.backupPlayersPerTeam > 1
                                    ? `Pemain Cadangan ${p}`
                                    : 'Pemain Cadangan')
                                } ${
                                  (backupPlayerData &&
                                    backupPlayerData[p - 1][`additionalinGameID${p}`] &&
                                    `(${backupPlayerData[p - 1][`additionalinGameID${p}`]})`) ||
                                  `${backupAdditionalFieldNames.includes('inGameID') ? 'ID' : ''}`
                                }`}</Typography.Text>
                              </Row>
                              {cup.data.value?.isCollectingEmail ? (
                                <Row className="word-break">
                                  <Typography.Text className="subtitle">
                                    {(backupPlayerData &&
                                      backupPlayerData[p - 1][`additionalplayerEmail${p}`]) ||
                                      'email'}
                                  </Typography.Text>
                                </Row>
                              ) : undefined}
                              {additionalDatas[0] ? (
                                <Row className="word-break">
                                  <Typography.Text className="subtitle">
                                    {additionalDatas.join(' - ')}
                                  </Typography.Text>
                                </Row>
                              ) : undefined}
                            </Col>
                            <Col flex="40px">
                              <Row className="mv-1" justify="center">
                                <div className="icon-pen text-size-18" />
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              ) : undefined}
              <Row className="mt-4">
                <Button
                  type="primary"
                  size="large"
                  block
                  htmlType="submit"
                  loading={player.requesting}
                  disabled={player.requesting}
                  onClick={onComplete}
                >
                  {cup.data?.value?.surveyQuestion && cup.data?.value?.surveyQuestion.length > 0
                    ? 'Lanjutkan'
                    : 'Kirim'}
                </Button>
              </Row>
              <Row className="mt-1 mb-2">
                <Button ghost block size="large" type="primary" onClick={() => setStep(1)}>
                  Kembali
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const renderModal = () => {
    const fields:
      | IField[]
      | { name: string; label: string; required: boolean; type: string }[] = [];
    let title = '';
    const initialValues: { [x: string]: any } = {};
    captainAdditionalFields.forEach(
      (x) =>
        (initialValues[x.name] =
          (captainAdditionalData && captainAdditionalData[x.name]) || undefined),
    );
    teamPlayers?.forEach((i) => {
      teamAdditionalFields.forEach(
        (x) =>
          (initialValues[x.name + i] =
            (teamAdditionalData &&
              currentPlayer &&
              teamAdditionalData[i - 1] &&
              teamAdditionalData[i - 1][`${x.name}${i}`]) ||
            undefined),
      );
    });
    backupPlayers?.forEach((i) => {
      backupAdditionalFields.forEach(
        (x) =>
          (initialValues[`additional${x.name}${i}`] =
            (currentBackupPlayer &&
              backupPlayerData &&
              backupPlayerData[i - 1] &&
              backupPlayerData[i - 1][`additional${x.name}${i}`]) ||
            undefined),
      );
    });
    editPlayerAdditionalData?.forEach((x: any) => {
      Object.entries(x).forEach(([key, value]) => {
        initialValues[key] = value;
      });
    });
    if (mode === MODAL_MODE.captain) {
      title = 'Data Kapten';
      captainAdditionalFields.forEach((x) => {
        fields.push({
          name: x.name,
          label: x.label,
          required: x.name === 'captainEmail' ? false : true,
          type: x.type,
          rules: x.rules || [],
          helpText: x.helpText,
          disabled: x.disabled,
          options: x.options,
        });
      });
    } else if (mode === MODAL_MODE.team) {
      title = 'Data Pemain Inti';
      teamAdditionalFields.forEach((x) => {
        fields.push({
          name: `${x.name}${currentPlayer}`,
          label: `${x.label}`,
          required: true,
          rules: x.rules || [],
          type: x.type,
          helpText: x.helpText,
          disabled: x.disabled,
        });
      });
    } else if (mode === MODAL_MODE.additional) {
      title = 'Data Pemain Cadangan';
      backupAdditionalFields.forEach((x) => {
        fields.push({
          name: `additional${x.name}${currentBackupPlayer}`,
          label: `${x.label}`,
          required: false,
          type: x.type,
          rules: x.rules || [],
          helpText: x.helpText,
          disabled: x.disabled,
        });
      });
    } else {
      title = `Ganti Pemain ${currentEditPlayer?.value}`;
      editPlayerAdditionalFields.forEach((x) => {
        fields.push({
          name: `${currentEditPlayer?.isTeam ? 'editTeam' : 'editBackup'}${x.name}${
            currentEditPlayer?.value
          }`,
          label: `${x.label}`,
          required: true,
          type: x.type,
          rules: x.rules || [],
          helpText: x.helpText,
          disabled: x.disabled,
        });
      });
    }
    return (
      <Modal
        title={title}
        visible={isModalVisible}
        footer={null}
        onCancel={() =>
          mode === MODAL_MODE.captain
            ? toggleModalCaptainData()
            : mode === MODAL_MODE.team
            ? toggleModalTeamData()
            : mode === MODAL_MODE.additional
            ? toggleModalBackupPlayerData()
            : toggleModalEditPlayer()
        }
        style={{ maxWidth: 420 }}
        className="captain-modal"
        transitionName=""
        maskTransitionName=""
      >
        <Row justify="center">
          <Col span={24}>
            <ComponentForm
              onFinish={
                mode === MODAL_MODE.captain
                  ? handleCaptainData
                  : mode === MODAL_MODE.team
                  ? handleTeamData
                  : mode === MODAL_MODE.additional
                  ? handleBackupPlayerData
                  : handleEditPlayerData
              }
              fields={fields}
              initialValues={initialValues}
            >
              <Row className="mt-4">
                <Button type="primary" size="large" block htmlType="submit">
                  Simpan
                </Button>
              </Row>
            </ComponentForm>
          </Col>
        </Row>
      </Modal>
    );
  };

  const renderModalVerifyEmail = () => {
    const newEmail =
      currentEditPlayer &&
      teamPlayers &&
      editPlayerAdditionalData[
        currentEditPlayer.isTeam
          ? currentEditPlayer.value - 1
          : currentEditPlayer.value - teamPlayers.length - 1
      ][`edit${currentEditPlayer.isTeam ? 'Team' : 'Backup'}playerEmail${currentEditPlayer.value}`];
    return (
      <Modal
        title="Verifikasi Email"
        visible={isVerifyEmailModalVisible}
        footer={null}
        onCancel={() => setIsVerifyEmailModalVisible(false)}
      >
        <Row>
          <Typography.Paragraph>
            Kami telah mengirimkan permintaan verifikasi email ke email yang baru dimasukan. Silakan
            cek inbox (kotak masuk) email dibawah untuk verifikasi.
          </Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph strong>{newEmail}</Typography.Paragraph>
        </Row>
        <Row className="mt-1">
          <Button
            block
            size="large"
            type="primary"
            onClick={() => setIsVerifyEmailModalVisible(false)}
          >
            Tutup
          </Button>
        </Row>
      </Modal>
    );
  };

  const renderPlayerCard = (p: IPlayer, index: number, teamId: number, isTeam: boolean) => (
    <Row className="player-card mb-1" key={p.id}>
      <Col span={24}>
        <Row className="m-1">
          <Typography.Text className="text-size-16 text-height-24 text-weight-medium">
            {`${JSON.parse(p?.additionalData || '{}')?.inGameName || `Pemain ${index + 1}`} ${
              JSON.parse(p?.additionalData || '{}')?.inGameID
                ? `(${JSON.parse(p?.additionalData || '{}')?.inGameID})`
                : ''
            }`}
          </Typography.Text>
        </Row>
        {cup.data.value?.isCollectingEmail ? (
          <Row wrap={false}>
            <Col flex="auto">
              <Row className="ml-1 mb-1">
                <Typography.Text className="text-size-12 text-height-18 text-weight-medium">
                  {p?.invitedEmail}
                </Typography.Text>
              </Row>
            </Col>
            <Col flex="100px">
              {!p.isEmailConfirmed && player.data?.isCaptain ? (
                <Row
                  justify="end"
                  align="bottom"
                  className="mr-1 mb-1 use-pointer"
                  onClick={() => toggleModalEditPlayer(index + 1, teamId, p.id, isTeam)}
                >
                  <Typography.Text underline>Ganti Pemain</Typography.Text>
                </Row>
              ) : undefined}
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={24}>
              <Row className="mh-1 mb-1">
                <Typography.Text className="text-size-12 text-height-18 text-weight-medium">
                  {DisplayPlayerData(p)}
                </Typography.Text>
              </Row>
            </Col>
          </Row>
        )}
        {cup.data.value?.isCollectingEmail ? (
          p.isEmailConfirmed ? (
            <Row className="mh-1 mb-1">
              <Typography.Text className="text-size-12 text-height-18 text-color-blue">
                ● Undangan Diterima
              </Typography.Text>
            </Row>
          ) : (
            <Row className="mh-1 mb-1">
              <Typography.Text className="text-size-12 text-height-18 text-color-orange">
                ● Undangan Dikirim
              </Typography.Text>
            </Row>
          )
        ) : undefined}
      </Col>
    </Row>
  );

  const renderRegisterSuccess = () => {
    let playerData: Array<IPlayer> | undefined = undefined;
    let teamData: ITeam | undefined = undefined;
    if (player.list && player.list.length > 0) {
      playerData = player.list;
      teamData = player.list[0].team;
    } else if (player.data) {
      playerData = player.data.team?.players;
      teamData = player.data.team;
    }
    const captainData = playerData?.find((p) => p.isCaptain);
    const nonCaptData = playerData?.filter((p) => !p.isCaptain);
    const teamMemberData = nonCaptData
      ?.filter((p) => {
        const temp = JSON.parse(p.additionalData);
        if (!temp.isAdditionalPlayer) return p;
      })
      .sort((a, b) => (a.id <= b.id ? -1 : 1));
    const backupPlayerData = nonCaptData?.filter((p) => {
      const temp = JSON.parse(p.additionalData);
      if (temp.isAdditionalPlayer) return p;
    });
    const tempArray: Array<any> = [];
    teamMemberData?.forEach((x, i) => {
      const temp: { [y: string]: any } = {};
      const parsedAdditionalData = x.additionalData ? JSON.parse(x.additionalData) : undefined;
      editPlayerAdditionalFields.forEach((z) => {
        if (z.name.includes('playerEmail')) temp[z.name + (i + 1)] = x.invitedEmail;
        if (z.name.includes('inGameName'))
          temp[z.name + (i + 1)] = parsedAdditionalData
            ? parsedAdditionalData['inGameName']
            : undefined;
        tempArray.push(temp);
      });
    });
    return (
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <img
                src={cup.data?.value?.image}
                alt={cup.data?.value?.name}
                width="100%"
                height="auto"
                className="image-container"
              />
            </Col>
          </Row>
          <Row className="subtitle-container">
            <Col span={24}>
              <Row className="mh-2 mt-2 mb-1">
                <Typography.Text className="text-size-18 text-height-21 text-weight-bold">
                  {cup.data?.value?.name}
                </Typography.Text>
              </Row>
              <Row className="mh-2 mb-1">
                <Col span={12}>
                  <Row className="mb-1">
                    <Typography.Text className="text-size-12 text-height-18 text-weight-regular">
                      Hadiah
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Text className="text-size-14 text-height-21 text-weight-medium">
                      {Numberize(cup.data?.value?.totalPrize || 0, '.', 'Rp') || '-'}
                    </Typography.Text>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row className="mb-1">
                    <Typography.Text className="text-size-12 text-height-18 text-weight-regular">
                      Peraturan
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Link
                      href={`/${cup.data?.value?.slug}`}
                      className="text-color-blue text-size-14 text-height-16 text-weight-medium"
                    >
                      Lihat
                      <ArrowRightOutlined className="ml--5" />
                    </Typography.Link>
                  </Row>
                </Col>
              </Row>
              <Row className="register-success-info-card">
                <Col span={24}>
                  <Row className="m-1">
                    <Typography.Text className="success-text">
                      {`Tim terdaftar, silakan menunggu hasil seleksi. ${
                        cup.data?.value?.pointReward && cup.data?.value?.pointReward > 0
                          ? 'Kamu mendapatkan '
                          : ''
                      }`}
                      {cup.data?.value?.pointReward && cup.data?.value?.pointReward > 0 ? (
                        <span>&nbsp;{`${Numberize(cup.data?.value?.pointReward, '.')} EMP.`}</span>
                      ) : undefined}
                    </Typography.Text>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="section-container">
            <Col span={24}>
              {cup.data.value?.isCollectingEmail ? (
                <Row className="reminder-card">
                  <Col span={24}>
                    <Row className="m-1" justify="center">
                      <Typography.Text className="text-center">
                        Mohon ingatkan teman satu tim anda untuk konfirmasi keikutsertaan melalui
                        email
                      </Typography.Text>
                    </Row>
                  </Col>
                </Row>
              ) : undefined}
              <Row className="mt-2 mb-1">
                <Typography.Text className="text-size-18 text-height-21 text-weight-bold">
                  {teamData?.name}
                </Typography.Text>
              </Row>
              <Row className="mb-2">
                <Typography.Text className="text-size-14 text-height-21 text-weight-regular">
                  {captainData?.phoneNumber}
                </Typography.Text>
              </Row>
              <Row className="player-card mb-1">
                <Col span={24}>
                  <Row className="m-1" align="middle">
                    <Typography.Text className="text-size-16 text-height-24 text-weight-medium">
                      {`${
                        JSON.parse(captainData?.additionalData || '{}')?.inGameName || 'Kapten'
                      } ${
                        JSON.parse(captainData?.additionalData || '{}')?.inGameID
                          ? `(${JSON.parse(captainData?.additionalData || '{}')?.inGameID})`
                          : ''
                      }`}
                    </Typography.Text>
                    {JSON.parse(captainData?.additionalData || '{}')?.gender ? (
                      <Typography.Text className="text-size-16 text-height-24 text-weight-medium">
                        &nbsp;{`- ${JSON.parse(captainData?.additionalData || '{}')?.gender}`}
                      </Typography.Text>
                    ) : (
                      <></>
                    )}
                    {JSON.parse(captainData?.additionalData || '{}')?.age ? (
                      <Typography.Text className="text-size-16 text-height-24 text-weight-medium">
                        &nbsp;{`- ${JSON.parse(captainData?.additionalData || '{}')?.age}`}
                      </Typography.Text>
                    ) : (
                      <></>
                    )}
                    &nbsp;
                    <div className="icon-captain-badge text-color-orange text-size-16" />
                  </Row>
                  <Row className="mh-1 mb-1">
                    <Typography.Text className="text-size-12 text-height-18 text-weight-medium">
                      {captainData?.invitedEmail}
                    </Typography.Text>
                  </Row>
                </Col>
              </Row>
              {teamMemberData &&
                teamMemberData.map(
                  (x, i) => teamData && renderPlayerCard(x, i, teamData?.id, true),
                )}
              {backupPlayerData && backupPlayerData.length > 0 ? (
                <Row className="mt-2 mb-1">
                  <Typography.Text className="text-size-18 text-height-21 text-weight-regular">
                    Pemain Cadangan
                  </Typography.Text>
                </Row>
              ) : undefined}
              {backupPlayerData &&
                backupPlayerData.map(
                  (x, i) => teamData && renderPlayerCard(x, i, teamData.id, false),
                )}
              <Row className="mt-2">
                <div
                  className="text-size-14 text-height-21 text-weight-regular"
                  dangerouslySetInnerHTML={{ __html: cup.data?.value?.descriptionClose || '' }}
                />
              </Row>
              <Row className="mv-3">
                <Button block size="large" type="primary" onClick={() => history.push('/')}>
                  Kembali ke Beranda
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const validateStepTwo = () => {
    let isValidPlayerData = true;
    let isValidTeamName = true;
    if (teamName === '') {
      isValidTeamName = false;
      setErrorMessages({
        teamName: 'Nama tim tidak boleh kosong',
        playerData: '',
      });
    } else {
      setErrorMessages({
        teamName: '',
        playerData: '',
      });
    }
    isValidPlayerData = Object.values(captainAdditionalData).find((value) => !value) === undefined;
    if (isValidPlayerData) {
      for (let i = 0; i < (cup.data?.value?.game?.playersPerTeam || 1) - 1; i++) {
        isValidPlayerData = !teamAdditionalFields.find(
          (f) => !teamAdditionalData[i][`${f.name}${i + 1}`],
        );
      }
    }
    if (!isValidPlayerData) {
      setErrorMessages({
        teamName: isValidTeamName ? '' : 'Nama tim tidak boleh kosong',
        playerData: 'Data pemain tidak boleh kosong',
      });
    }
    return isValidPlayerData && isValidTeamName;
  };

  const onComplete = () => {
    const isValid = validateStepTwo();
    const tempCaptAddFields: { [x: string]: any } = {};
    captainAdditionalFields.forEach((f) => {
      if (f.name !== 'phoneNumber' && f.name !== 'captainEmail') {
        tempCaptAddFields[f.name] = captainAdditionalData[f.name];
      }
    });
    const tempTeamMemberAddFields: Array<{ email?: any; additionalData: string }> = [];
    for (let i = 0; i < (cup.data?.value?.game?.playersPerTeam || 1) - 1; i++) {
      const temp: { [x: string]: any } = {};
      teamAdditionalFields.forEach((f) => {
        if (!(f.name as string).includes('playerEmail')) {
          temp[f.name] = teamAdditionalData[i][`${f.name}${i + 1}`];
        }
      });
      temp.isAdditionalPlayer = false;
      if (cup.data.value?.isCollectingEmail) {
        tempTeamMemberAddFields.push({
          email: teamAdditionalData[i][`playerEmail${i + 1}`],
          additionalData: JSON.stringify(temp),
        });
      } else {
        tempTeamMemberAddFields.push({
          additionalData: JSON.stringify(temp),
        });
      }
    }
    if (
      cup.data?.value?.game?.backupPlayersPerTeam &&
      cup.data?.value?.game?.backupPlayersPerTeam > 0
    ) {
      for (let i = 0; i < cup.data?.value?.game?.backupPlayersPerTeam; i++) {
        const isEmptyField = !!teamAdditionalFields.find(
          (f) => !backupPlayerData[i][`additional${f.name}${i + 1}`],
        );
        if (!isEmptyField) {
          const temp: { [x: string]: any } = {};
          backupAdditionalFields.forEach((f) => {
            if (!(f.name as string).includes('playerEmail')) {
              temp[f.name] = backupPlayerData[i][`additional${f.name}${i + 1}`];
            }
          });
          temp.isAdditionalPlayer = true;
          if (cup.data.value?.isCollectingEmail) {
            tempTeamMemberAddFields.push({
              email: backupPlayerData[i][`additionalplayerEmail${i + 1}`],
              additionalData: JSON.stringify(temp),
            });
          } else {
            tempTeamMemberAddFields.push({
              additionalData: JSON.stringify(temp),
            });
          }
        }
      }
    }
    const tempProcessedData = {
      cupId: cup.data?.value?.id as number,
      teamName: teamName,
      phoneNumber: captainAdditionalData.phoneNumber,
      captainEmail: captainAdditionalData.captainEmail,
      captainAdditionalData: JSON.stringify(tempCaptAddFields),
      teamMembers: tempTeamMemberAddFields.length > 0 ? tempTeamMemberAddFields : [],
    };
    setProcessedData(tempProcessedData);
    if (isValid) {
      if (cup.data?.value?.surveyQuestion && cup.data?.value?.surveyQuestion.length > 0) {
        setScreenMode(SCREEN_MODE.survey);
      } else {
        const data: IRegisterPlayer = {
          ...tempProcessedData,
          surveyResponse: '',
        };
        if (data) registerPlayer(data);
      }
    }
  };

  const onSubmitSurvey = (values: Store) => {
    const data: IRegisterPlayer = {
      ...processedData,
      surveyResponse: values.surveyResponse,
    };
    if (data) registerPlayer(data);
  };

  const onChangeSurvey = (e: any) => {
    setSurveyAnswer(e.target.value);
  };

  const renderSurvey = () => {
    const surverField = [
      {
        name: 'surveyResponse',
        label: Capitalize(cup.data?.value?.surveyQuestion || ''),
        type: 'textarea',
        rows: 8,
        placeholder: 'Tulis jawaban Kamu disini...',
        rules: [{ required: true, message: `Survei harus diisi` }],
        initialValue: surveyAnswer,
      },
    ];
    return (
      <Row>
        <Col span={24}>
          <Row wrap={false} className="progress-wrapper">
            <Col flex="100px">
              <Row align="middle">
                <Typography.Text className="common-text">Langkah 3 / 3</Typography.Text>
              </Row>
            </Col>
            <Col flex="auto">
              <Row align="middle">
                <Progress
                  strokeLinecap="square"
                  strokeColor="#62E05F"
                  percent={100}
                  showInfo={false}
                  className="progress-bar"
                />
              </Row>
            </Col>
          </Row>
          <Row className="section-container padding-progress-bar">
            <Col span={24}>
              <Row className="mt-2 mb-1 pb--5">
                <Col>
                  <Typography.Text className="survey-title">Survei</Typography.Text>
                </Col>
              </Row>
              <Row className="mb--5">
                <Typography.Text className="title-2">{cup.data?.value?.name}</Typography.Text>
              </Row>
              <Row className="survey-container">
                <Col span={24}>
                  <ComponentForm
                    onFinish={onSubmitSurvey}
                    onChange={onChangeSurvey}
                    fields={surverField}
                    initialValues={{}}
                  >
                    <Row className="mt-4 mb-1">
                      <Button
                        type="primary"
                        size="large"
                        block
                        htmlType="submit"
                        loading={player.requesting}
                        disabled={player.requesting}
                      >
                        Kirim
                      </Button>
                    </Row>
                    <Row className="mb-2">
                      <Button
                        ghost
                        block
                        type="primary"
                        size="large"
                        onClick={() => setScreenMode(SCREEN_MODE.form)}
                      >
                        Kembali
                      </Button>
                    </Row>
                  </ComponentForm>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const renderCupGuest = () => (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <img
              src={cup.data?.value?.image}
              alt={cup.data?.value?.name}
              width="100%"
              height="auto"
              className="image-container"
            />
          </Col>
        </Row>
        <Row className="section-container" justify="center">
          <Col span={24}>
            <Row className="mt-4">
              <Typography.Text className="title-2">{cup.data?.value?.name}</Typography.Text>
            </Row>
            <Divider />
            <Row className="mb-5" justify="center">
              <Col>
                <Typography.Text className="text-size-14 text-height-21 text-weight-regular">
                  Untuk mendaftar cup diperlukan akun EVOS Network. Silahkan buat akun atau log in
                  jika sudah memiliki akun.
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col span={24}>
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={() =>
                    (window.location.href = `${Env.MY_URL}/login?referrer=${Encrypt(
                      '',
                      window.location.href,
                    )}`)
                  }
                >
                  Masuk
                </Button>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col span={24}>
                <Button
                  block
                  ghost
                  type="primary"
                  size="large"
                  onClick={() =>
                    (window.location.href = `${Env.MY_URL}/register?referrer=${Encrypt(
                      '',
                      window.location.href,
                    )}`)
                  }
                >
                  Daftar
                </Button>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col span={24}>
                <Button
                  block
                  type="text"
                  size="large"
                  className="text-button"
                  onClick={() => history.push('/')}
                >
                  Kembali ke Beranda
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <Layout
      header={{
        right: <></>,
      }}
    >
      {isLoggedIn &&
      !player.data &&
      !player.list &&
      screenMode === SCREEN_MODE.survey &&
      cup.data?.value &&
      moment().isBetween(
        moment.unix(cup.data?.value.registrationStartAt),
        moment.unix(cup.data?.value.registrationEndAt),
      )
        ? renderSurvey()
        : undefined}
      {isLoggedIn &&
      (player.data || (player.list?.length && player.list?.length > 0)) &&
      cup.data?.value &&
      moment().isBetween(
        moment.unix(cup.data?.value.registrationStartAt),
        moment.unix(cup.data?.value.registrationEndAt),
      )
        ? renderRegisterSuccess()
        : undefined}
      {isLoggedIn &&
      cup.data?.value?.registrationStartAt &&
      cup.data?.value?.registrationEndAt &&
      !moment().isBetween(
        moment.unix(cup.data?.value?.registrationStartAt),
        moment.unix(cup.data?.value?.registrationEndAt),
      )
        ? renderClosedRegistration()
        : undefined}
      {isLoggedIn === false ? renderCupGuest() : undefined}
      {renderModal()}
      {isLoggedIn &&
      !player.data &&
      !player.list &&
      step === 1 &&
      screenMode === SCREEN_MODE.form &&
      cup.data?.value &&
      moment().isBetween(
        moment.unix(cup.data?.value.registrationStartAt),
        moment.unix(cup.data?.value.registrationEndAt),
      )
        ? renderStepOne()
        : undefined}
      {isLoggedIn &&
      !player.data &&
      !player.list &&
      step === 2 &&
      screenMode === SCREEN_MODE.form &&
      cup.data?.value &&
      moment().isBetween(
        moment.unix(cup.data?.value.registrationStartAt),
        moment.unix(cup.data?.value.registrationEndAt),
      )
        ? renderStepTwo()
        : undefined}
      {renderModalVerifyEmail()}
    </Layout>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  cup: state.cup,
  player: state.player,
  team: state.team,
});

const mapDispatchToProps = (dispatch: any) => ({
  getCupDetail: (slug: string) => dispatch(Actions.cup.GetDetail(slug, false)),
  registerPlayer: (data: IRegisterPlayer) => dispatch(Actions.player.Register(data, false)),
  getExistingTeamList: (gameID: number, limit?: number, page?: number, callback?: () => void) =>
    dispatch(Actions.team.GetList(gameID, limit, page, false, callback)),
  checkRegister: (cupId: string) => dispatch(Actions.player.CheckRegister(cupId, false)),
  resetPlayerList: () => dispatch(Actions.player.RemovePlayerList()),
  resetTeamList: () => dispatch(Actions.team.RemoveTeamList()),
  editPlayer: (payload: any, teamId: number, playerId: number, callback?: (res: any) => void) =>
    dispatch(Actions.player.EditPlayer(payload, teamId, playerId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CupPage);
