import { Reducer } from 'redux';
import ActionTypes from '../../constants/action-types';
import BaseStateDefault from '../../constants/state';
import { ITeamState } from '../../interfaces/states/team';

const initialState: ITeamState = BaseStateDefault;

const reducer: Reducer<any> = (state: ITeamState = initialState, action): ITeamState => {
  switch (action.type) {
    case ActionTypes.team.GET_EXISTING_TEAM_LIST_REQUEST: {
      return {
        ...state,
        requesting: true,
        errors: undefined,
      };
    }
    case ActionTypes.team.GET_EXISTING_TEAM_LIST_FAILED: {
      return {
        ...state,
        requesting: false,
        errors: action.payload.error,
      };
    }
    case ActionTypes.team.GET_EXISTING_TEAM_LIST_SUCCESS: {
      const { list, paging } = action.payload;
      return {
        ...state,
        paging,
        requesting: false,
        list: paging.currentPage === 1 ? list : [...(state.list || []), ...list],
      };
    }
    case ActionTypes.team.REMOVE_TEAM_LIST: {
      return {
        ...state,
        requesting: false,
        errors: undefined,
        list: [],
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
