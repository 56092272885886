import moment from 'moment';
import GeneralParticle from '@evos/general-particle';
import IBaseResponse from '@evos/general-particle/dist/interfaces/api/result';
import { IGroup, ITeamLog } from '@evos/general-particle/dist/interfaces/efc';
import CUP from './cup';
import GAME from './game';
import STAGE from './stage';
import TEAMLOG from './team-log';

const { STAGE_TYPE } = GeneralParticle.Constants.EFC;

const GenerateSingleGroup = (id?: number, withTeam?: boolean): IGroup => {
  const usedId = id || 1;
  const stage = STAGE.GenerateSingleStage(usedId);
  const totalRound = withTeam ? (stage.type === STAGE_TYPE.bracket ? 8 : 1) : 0;
  const teamLogs: Array<ITeamLog> = [];

  for (let i = 0; i < Math.pow(2, totalRound); i++) {
    teamLogs.push(TEAMLOG.GenerateSingleTeamLog(i + 1));
  }
  for (let i = 2; i <= totalRound + 1; i++) {
    const lastRoundTeamLogs = teamLogs.filter((x) => x.round === i - 1);

    for (let l = 0; l < ~~(lastRoundTeamLogs.length / 2); l++) {
      if (Math.random() >= 0.5) {
        const min = l * 2;
        const max = min + 1;
        const index = Math.random() >= 0.5 ? min : max;
        const newTeamLog = { ...lastRoundTeamLogs[index] };
        newTeamLog.round = i;
        teamLogs.push(newTeamLog);
      }
    }
  }

  return {
    id: usedId,
    cup: CUP.getDetailResponseDummy.data,
    game: GAME.GenerateSingleGame(usedId),
    name: `Grup ${usedId}`,
    stage,
    totalRound: totalRound + 1,
    teamLogs,
    createdAt: moment().unix(),
    updatedAt: moment().unix(),
  };
};

const GetList = (numberOfData: number): IBaseResponse<Array<IGroup>> => {
  const data: Array<IGroup> = [];
  for (let i = 0; i < numberOfData; i++) {
    const newData = GenerateSingleGroup(i + 1);
    data.push(newData);
  }
  return {
    success: true,
    data,
    paging: {
      hasNext: false,
      currentPage: 1,
      totalData: numberOfData,
      totalPages: 1,
      limit: numberOfData,
    },
  };
};

const GetById = (groupId: number): IBaseResponse<IGroup> => {
  return {
    success: true,
    data: GenerateSingleGroup(groupId, true),
  };
};

export default { GenerateSingleGroup, GetList, GetById };
