export default {
  REGISTER_PLAYER_REQUEST: 'REGISTER_PLAYER_REQUEST',
  REGISTER_PLAYER_FAILED: 'REGISTER_PLAYER_FAILED',
  REGISTER_PLAYER_SUCCESS: 'REGISTER_PLAYER_SUCCESS',

  CHECK_REGISTER_PLAYER_REQUEST: 'CHECK_REGISTER_PLAYER_REQUEST',
  CHECK_REGISTER_PLAYER_FAILED: 'CHECK_REGISTER_PLAYER_FAILED',
  CHECK_REGISTER_PLAYER_SUCCESS: 'CHECK_REGISTER_PLAYER_SUCCESS',

  CONFIRM_INVITATION_REQUEST: 'CONFIRM_INVITATION_REQUEST',
  CONFIRM_INVITATION_FAILED: 'CONFIRM_INVITATION_FAILED',
  CONFIRM_INVITATION_SUCCESS: 'CONFIRM_INVITATION_SUCCESS',

  REMOVE_PLAYER_LIST_SUCCESS: 'REMOVE_PLAYER_LIST_SUCCESS',

  GET_PLAYER_LIST_REQUEST: 'GET_PLAYER_LIST_REQUEST',
  GET_PLAYER_LIST_FAILED: 'GET_PLAYER_LIST_FAILED',
  GET_PLAYER_LIST_SUCCESS: 'GET_PLAYER_LIST_SUCCESS',

  CHECK_REGISTER_REQUEST: 'CHECK_REGISTER_REQUEST',
  CHECK_REGISTER_FAILED: 'CHECK_REGISTER_FAILED',
  CHECK_REGISTER_SUCCESS: 'CHECK_REGISTER_SUCCESS',

  EDIT_PLAYER_REQUEST: 'EDIT_PLAYER_REQUEST',
  EDIT_PLAYER_FAILED: 'EDIT_PLAYER_FAILED',
  EDIT_PLAYER_SUCCESS: 'EDIT_PLAYER_SUCCESS',
};
