/* eslint-disable no-undef */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Typography, Spin, Button, Image } from 'antd';
import AccountParticle from '@evos/account-particle';
import GeneralParticle from '@evos/general-particle';
import { IResponse } from '@evos/general-particle/dist/interfaces/api';
import Layout from '../../components/layout';
import ENV from '../../utils/environment';
import Actions from '../../store/actions';
import { ReduxState } from '../../store/reducers';
import { IPlayerState } from '../../interfaces/states/player';
import { DisplayPlayerData } from '../../utils/common';

const { Encrypt } = GeneralParticle.Utils.Crypt;
const { Numberize } = GeneralParticle.Utils.Format;
const { GetToken } = AccountParticle.API.User;

interface IProps {
  player: IPlayerState;
  confirmInvitation: (slug: string, playerId: string, signature: string) => Promise<any>;
  validateSignature: (
    slug: string,
    playerId: string,
    signature: string,
  ) => Promise<IResponse<null>>;
}

const AcceptInvitationPage = (props: IProps) => {
  const { confirmInvitation, validateSignature, player } = props;
  const [to, setTo] = useState<number>(60);
  const { cupSlug, signature, playerId } = useParams<{
    cupSlug: string;
    signature: string;
    playerId: string;
  }>();
  const isLoggedIn = !!GetToken(ENV.PARTICLE_ENV);

  React.useEffect(() => {
    if (player.data) {
      if (to > 0) {
        setTimeout(() => {
          setTo(to - 1);
        }, 1000);
      } else {
        window.location.href = `${ENV.MY_URL}/login?referrer=${Encrypt(
          '',
          window.location.href + '/' + cupSlug + '/join',
        )}`;
      }
    }
  }, [cupSlug, player, setTo, to]);

  React.useEffect(() => {
    if (cupSlug && playerId && signature) {
      validateSignature(cupSlug, playerId, signature).then((res) => {
        if (res.data?.success) confirmInvitation(cupSlug, playerId, signature);
      });
    }
  }, [confirmInvitation, playerId, signature, cupSlug, validateSignature]);

  return (
    <Layout>
      {player.requesting ? (
        <Row justify="center">
          <Spin />
        </Row>
      ) : (
        <Row>
          <Col span={24} className="ph-2">
            {player.data ? (
              <Row className="mv-2">
                <Typography.Text className="text-size-24 text-height-36 text-weight-bold">
                  Berhasil Bergabung dalam Tim
                </Typography.Text>
              </Row>
            ) : (
              <Row className="mv-2">
                <Typography.Text className="text-size-24 text-height-36 text-weight-bold text-color-blue">
                  Kesalahan Link
                </Typography.Text>
              </Row>
            )}

            {player.data ? (
              <>
                <Row className="m-2">
                  <Image
                    src="https://uploads.dev.evos.gg/cup/confirm-invitation.png"
                    width="100%"
                    preview={false}
                  />
                </Row>
                <Row className="mv-2">
                  <Typography.Paragraph className="text-size-16 text-height-24">
                    {`Kamu menerima undangan dari ${DisplayPlayerData(
                      player.data,
                      true,
                    )} untuk mengikuti `}
                    <Typography.Text strong className="text-size-16 text-height-24">
                      {player.data.cup?.name}
                    </Typography.Text>
                    {player.data.cup?.pointReward && player.data.cup?.pointReward > 0 ? (
                      <>
                        <Typography.Text className="text-size-16 text-height-24">
                          {` dan mendapatkan `}
                        </Typography.Text>
                        <Typography.Text className="text-color-orange text-size-16 text-height-24 text-weight-bold">
                          {`${Numberize(player.data.cup?.pointReward, '.')} EMP`}
                        </Typography.Text>
                      </>
                    ) : (
                      ''
                    )}
                    .
                  </Typography.Paragraph>
                </Row>
                <Row className="mv-2">
                  <Typography.Text className="text-size-16 text-height-24">
                    <strong>{`Persiapkan dirimu untuk mengikuti pertandingan. Silahkan lihat klasemen pada halaman kompetisi dan baca syarat dan ketentuan pertandingan.`}</strong>
                  </Typography.Text>
                </Row>
              </>
            ) : (
              <Row className="mv-2">
                <Typography.Text className="text-size-16 text-height-24">
                  Link undangan yang kamu terima salah. Mohon hubungi kapten tim untuk mengecek
                  kembali data pemain yang didaftarkan atau silahkan cek kembali email yang kami
                  kirimkan kepada setiap pemain terdaftar.
                </Typography.Text>
              </Row>
            )}

            <Row className="mb-2 mt-4">
              <Button
                type="primary"
                size="large"
                block
                href={
                  isLoggedIn
                    ? `/profile`
                    : `${ENV.MY_URL}/login?referrer=${Encrypt(
                        '',
                        window.location.href + '/' + cupSlug + '/join',
                      )}`
                }
              >
                {isLoggedIn ? `Kembali ke profil` : 'Masuk Sekarang'}
              </Button>
            </Row>
            {player.data ? (
              <Row className="mv-2" justify="center">
                <Typography.Text>Halaman akan dialihknan dalam {to} detik</Typography.Text>
              </Row>
            ) : undefined}
          </Col>
        </Row>
      )}
    </Layout>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  player: state.player,
});

const mapDispatchToProps = (dispatch: any) => ({
  confirmInvitation: (slug: string, playerId: string, signature: string) =>
    dispatch(Actions.player.ConfirmInvitation(slug, playerId, signature)),
  validateSignature: (slug: string, playerId: string, signature: string) =>
    dispatch(Actions.player.ValidateSignature(slug, playerId, signature)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptInvitationPage);
