import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Typography, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Layout from '../../components/layout';
import Actions from '../../store/actions';
import { ReduxState } from '../../store/reducers';
import { ICupState } from '../../interfaces/states/cup';
import { CUP_OWNER, CUP_SECTION, CUP_SECTION_HEADER } from '../../constants/cup-section';
import CupListComponent from '../../components/cup-list';

interface IProps {
  cup: ICupState;
  getCupList: (cupSection: CUP_SECTION, page?: number, limit?: number) => Promise<any>;
}

const cupSectionList = Object.values(CUP_SECTION).filter(
  (v) => typeof v === 'string' && v !== 'undefined',
);

const CupListPage = (props: IProps) => {
  const { getCupList, cup } = props;
  const history = useHistory();
  const { sectionSlug } = useParams<{ sectionSlug: string }>();
  const [header, setHeader] = React.useState<string>('');
  React.useEffect(() => {
    if (cupSectionList.includes(sectionSlug)) {
      setHeader(CUP_SECTION_HEADER[CUP_SECTION[sectionSlug as keyof typeof CUP_SECTION]]);
      getCupList(CUP_SECTION[sectionSlug as keyof typeof CUP_SECTION], 1, 4);
    }
  }, [getCupList, sectionSlug]);

  const renderNotFound = () => (
    <Row justify="center" className="summary p-1">
      <Col span={24}>
        <Row justify="center">
          <Typography.Text className="text-color-white-70 text-size-14 text-height-21">
            Halaman tidak ditemukan.
          </Typography.Text>
        </Row>
      </Col>
    </Row>
  );

  const renderList = () => (
    <Row className="list-container mv-2" justify="center">
      <Col span={24}>
        <CupListComponent
          state={
            cup.list[`${CUP_OWNER.public}_${CUP_SECTION[sectionSlug as keyof typeof CUP_SECTION]}`]
          }
          cupSection={CUP_SECTION[sectionSlug as keyof typeof CUP_SECTION]}
          loadMore={() =>
            getCupList(
              CUP_SECTION[sectionSlug as keyof typeof CUP_SECTION],
              (cup.list[
                `${CUP_OWNER.public}_${CUP_SECTION[sectionSlug as keyof typeof CUP_SECTION]}`
              ]?.paging?.currentPage || 1) + 1,
              cup.list[
                `${CUP_OWNER.public}_${CUP_SECTION[sectionSlug as keyof typeof CUP_SECTION]}`
              ]?.paging?.limit,
            )
          }
        />
      </Col>
    </Row>
  );

  return (
    <Layout
      header={{
        left: (
          <Row className="custom-left-header">
            <Col span={24}>
              <Button
                ghost
                icon={<ArrowLeftOutlined />}
                size="large"
                onClick={() => {
                  history.push('/');
                }}
              />
              <Typography.Text className="text-size-18 text-height-21 text-weight-medium ml--5">
                {header}
              </Typography.Text>
            </Col>
          </Row>
        ),
        middle: <></>,
        right: <></>,
      }}
    >
      {cupSectionList.includes(sectionSlug) ? renderList() : renderNotFound()}
    </Layout>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  cup: state.cup,
});

const mapDispatchToProps = (dispatch: any) => ({
  getCupList: (cupSection: CUP_SECTION, page?: number, limit?: number) =>
    dispatch(Actions.cup.GetList(cupSection, CUP_OWNER.public, page, limit, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CupListPage);
