import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Typography } from 'antd';
import Layout from '../../components/layout';
import Actions from '../../store/actions';
import { ReduxState } from '../../store/reducers';
import { ICupState } from '../../interfaces/states/cup';
import { CUP_OWNER, CUP_SECTION, CUP_SECTION_HEADER } from '../../constants/cup-section';
import CupListComponent from '../../components/cup-list';

interface IProps {
  cup: ICupState;
  getCupList: (cupSection: CUP_SECTION, page?: number, limit?: number) => Promise<any>;
}

const HomePage = (props: IProps) => {
  const { getCupList, cup } = props;
  const history = useHistory();
  React.useEffect(() => {
    getCupList(CUP_SECTION.upcoming, 1, 2);
    getCupList(CUP_SECTION.opening, 1, 3);
    getCupList(CUP_SECTION.running, 1, 3);
    getCupList(CUP_SECTION.past, 1, 2);
  }, [getCupList]);

  return (
    <Layout
      header={{
        middle: <></>,
        right: <></>,
      }}
      bottomNav
    >
      <Row justify="center" className="mv-3">
        <Col span={24}>
          <Row justify="center">
            <img src="/images/evos-logo.png" alt="evos logo" width={80} height={80} />
          </Row>
          <Row justify="center" className="mt-1">
            <Typography.Text className="home-title">EVOS FAMS CUP</Typography.Text>
          </Row>
          <Row className="mt-1" justify="center">
            <Typography.Text className="text-size-14 text-height-16 text-center">
              Silahkan pilih kompetisi yang ingin Kamu ikuti!
            </Typography.Text>
          </Row>
        </Col>
      </Row>
      {[CUP_SECTION.opening, CUP_SECTION.running, CUP_SECTION.upcoming, CUP_SECTION.past].map(
        (l: number) => {
          return (
            <Row key={l} className="list-container mb-2" justify="center">
              <Col span={24}>
                <CupListComponent
                  state={cup.list[`${CUP_OWNER.public}_${l}`]}
                  cupSection={l}
                  loadMore={() => history.push(`/cups/${CUP_SECTION[l]}`)}
                  header={CUP_SECTION_HEADER[l]}
                />
              </Col>
            </Row>
          );
        },
      )}
    </Layout>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  cup: state.cup,
});

const mapDispatchToProps = (dispatch: any) => ({
  getCupList: (cupSection: CUP_SECTION, page?: number, limit?: number) =>
    dispatch(Actions.cup.GetList(cupSection, CUP_OWNER.public, page, limit, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
