import { IPlayer, ITeam } from '@evos/general-particle/dist/interfaces/efc';
import { Reducer } from 'redux';
import ActionTypes from '../../constants/action-types';
import BaseStateDefault from '../../constants/state';
import { IPlayerState } from '../../interfaces/states/player';

const initialState: IPlayerState = BaseStateDefault;

const reducer: Reducer<any> = (state: IPlayerState = initialState, action): IPlayerState => {
  switch (action.type) {
    case ActionTypes.player.EDIT_PLAYER_REQUEST:
    case ActionTypes.player.REGISTER_PLAYER_REQUEST:
    case ActionTypes.player.CHECK_REGISTER_REQUEST:
    case ActionTypes.player.CONFIRM_INVITATION_REQUEST:
    case ActionTypes.player.GET_PLAYER_LIST_REQUEST: {
      return {
        ...state,
        requesting: true,
        errors: undefined,
      };
    }
    case ActionTypes.player.EDIT_PLAYER_FAILED:
    case ActionTypes.player.REGISTER_PLAYER_FAILED:
    case ActionTypes.player.CHECK_REGISTER_FAILED:
    case ActionTypes.player.CONFIRM_INVITATION_FAILED:
    case ActionTypes.player.GET_PLAYER_LIST_FAILED: {
      return {
        ...state,
        requesting: false,
        errors: action.payload.error,
      };
    }
    case ActionTypes.player.REGISTER_PLAYER_SUCCESS: {
      return {
        ...state,
        requesting: false,
        list: action.payload.list,
        data: action.payload.list?.find((x: IPlayer) => x.isCaptain),
      };
    }
    case ActionTypes.player.GET_PLAYER_LIST_SUCCESS: {
      return {
        ...state,
        requesting: false,
        list: action.payload.list,
      };
    }
    case ActionTypes.player.EDIT_PLAYER_SUCCESS: {
      return {
        ...state,
        requesting: false,
        list: undefined,
        data: {
          ...state.data,
          team: {
            ...state.data?.team,
            players: [...action.payload.data.team.players],
          } as ITeam,
        } as IPlayer,
      };
    }
    case ActionTypes.player.CHECK_REGISTER_SUCCESS:
    case ActionTypes.player.CONFIRM_INVITATION_SUCCESS: {
      return {
        ...state,
        requesting: false,
        data: action.payload.data,
      };
    }
    case ActionTypes.player.REMOVE_PLAYER_LIST_SUCCESS: {
      return {
        ...state,
        requesting: false,
        list: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
