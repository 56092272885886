enum CUP_SECTION {
  undefined,
  upcoming,
  opening,
  running,
  past,
}

enum CUP_OWNER {
  public,
  user,
}

const CUP_SECTION_HEADER = {
  [CUP_SECTION.upcoming as number]: 'Segera Hadir',
  [CUP_SECTION.opening as number]: 'Registrasi Sedang Dibuka',
  [CUP_SECTION.running as number]: 'Kompetisi Berjalan',
  [CUP_SECTION.past as number]: 'Kompetisi Sebelumnya',
};

export { CUP_SECTION, CUP_OWNER, CUP_SECTION_HEADER };
