import { Reducer } from 'redux';
import ActionTypes from '../../constants/action-types';
import BaseStateDefault from '../../constants/state';
import { ITeamLogState } from '../../interfaces/states/team-log';

const initialState: ITeamLogState = BaseStateDefault;

const reducer: Reducer<any> = (state: ITeamLogState = initialState, action): ITeamLogState => {
  switch (action.type) {
    case ActionTypes.teamLog.GET_TEAM_LOG_LIST_REQUEST:
    case ActionTypes.teamLog.GET_LATEST_TEAM_LOG_REQUEST: {
      return {
        ...state,
        requesting: true,
        errors: undefined,
      };
    }
    case ActionTypes.teamLog.GET_TEAM_LOG_LIST_SUCCESS: {
      const { list, paging } = action.payload;
      return {
        ...state,
        list: paging?.currentPage > 1 ? [...(state.list || []), ...list] : list,
        requesting: false,
        paging,
      };
    }
    case ActionTypes.teamLog.GET_TEAM_LOG_LIST_FAILED:
    case ActionTypes.teamLog.GET_LATEST_TEAM_LOG_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        requesting: false,
        errors: error,
        list: [],
      };
    }
    case ActionTypes.teamLog.GET_LATEST_TEAM_LOG_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        requesting: false,
        data,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
