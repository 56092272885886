import Base from './base';
import Dummy from '../../constants/dummy';

export default {
  GetSummary: async (isDummy?: boolean): Promise<any> => {
    if (isDummy) {
      if (isDummy) {
        const dummyData = Dummy.dashboard.GetSummary();
        return new Promise((resolve) => {
          var blob = new Blob([JSON.stringify(dummyData, null, 2)], { type: 'application/json' });
          resolve(new Response(blob));
        });
      }
    }
    return Base().get('/private/cups/user/dashboard');
  },
};
