import AccountParticle from '@evos/account-particle';
import GeneralParticle from '@evos/general-particle';
import ENV from '../../utils/environment';

const { GetToken } = AccountParticle.API.User;
const { Utils } = GeneralParticle;

export default function () {
  const customHeader = new Headers();
  const token = GetToken(ENV.PARTICLE_ENV);
  if (token) {
    customHeader.append('authorization', `Bearer ${token}`);
  }
  return Utils.API({
    baseURL: ENV.API_URL,
    customHeader,
  });
}
