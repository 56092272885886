import { Reducer } from 'redux';
import ActionTypes from '../../constants/action-types';
import BaseStateDefault from '../../constants/state';
import { IStageState } from '../../interfaces/states/stage';

const initialState: IStageState = BaseStateDefault;

const reducer: Reducer<any> = (state: IStageState = initialState, action): IStageState => {
  switch (action.type) {
    case ActionTypes.stage.GET_STAGE_LIST_REQUEST:
    case ActionTypes.stage.GET_STAGE_BY_ID_REQUEST: {
      return {
        ...state,
        requesting: true,
        errors: undefined,
      };
    }
    case ActionTypes.stage.GET_STAGE_LIST_FAILED:
    case ActionTypes.stage.GET_STAGE_BY_ID_FAILED: {
      return {
        ...state,
        requesting: false,
        errors: action.payload.error,
      };
    }
    case ActionTypes.stage.GET_STAGE_LIST_SUCCESS: {
      return {
        ...state,
        requesting: false,
        list: action.payload.list,
      };
    }
    case ActionTypes.stage.GET_STAGE_BY_ID_SUCCESS: {
      return {
        ...state,
        requesting: false,
        data: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
