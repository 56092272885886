import { Reducer } from 'redux';
import ActionTypes from '../../constants/action-types';
import BaseStateDefault from '../../constants/state';
import { IDashboardState } from '../../interfaces/states/dashboard';

const initialState: IDashboardState = BaseStateDefault;

const reducer: Reducer<any> = (state: IDashboardState = initialState, action): IDashboardState => {
  switch (action.type) {
    case ActionTypes.dashboard.GET_DASHBOARD_SUMMARY_REQUEST: {
      return {
        ...state,
        requesting: true,
        errors: undefined,
      };
    }
    case ActionTypes.dashboard.GET_DASHBOARD_SUMMARY_FAILED: {
      return {
        ...state,
        requesting: false,
        errors: action.payload.error,
      };
    }
    case ActionTypes.dashboard.GET_DASHBOARD_SUMMARY_SUCCESS: {
      return {
        ...state,
        requesting: false,
        data: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
