import IBaseResponse from '@evos/general-particle/dist/interfaces/api/result';
import { IPlayer, ITeam } from '@evos/general-particle/dist/interfaces/efc';
import { IResponse } from '@evos/general-particle/dist/interfaces/api';
import Base from './base';
import { IRegisterPlayer } from '../../interfaces/requests/player';
import Dummy from '../../constants/dummy';

export default {
  Register: async (data: IRegisterPlayer, isDummy?: boolean): Promise<any> => {
    const random = Math.floor(Math.random() * 100);
    if (isDummy) {
      let dummyData = {};
      if (random > 94) {
        dummyData = Dummy.cup.registerErrorResponse1Dummy;
      } else if (random > 89) {
        dummyData = Dummy.cup.registerErrorResponse2Dummy;
      } else {
        dummyData = Dummy.cup.registerSuccessResponseDummy;
      }
      return new Promise((resolve) => {
        var blob = new Blob([JSON.stringify(dummyData, null, 2)], { type: 'application/json' });
        resolve(new Response(blob));
      });
    }
    return Base().post('/private/cups/register', data);
  },
  CheckRegister: async (cupId: string, isDummy?: boolean): Promise<any> => {
    if (isDummy) {
      let dummyData: any = Dummy.cup.checkRegisterResponseDummy;
      if (cupId === 'ff-s3') dummyData = Dummy.cup.registerSuccessResponseDummy;
      return new Promise((resolve) => {
        var blob = new Blob([JSON.stringify(dummyData, null, 2)], { type: 'application/json' });
        resolve(new Response(blob));
      });
    }
    return Base().get(`/private/cups/register-check/${cupId}`);
  },
  ConfirmInvitation: async (cupSlug: string, playerId: string, signature: string): Promise<any> => {
    return Base().post(`/public/cups/${cupSlug}/confirm-invitation/${playerId}/${signature}`);
  },
  ValidateSignature: async (
    cupSlug: string,
    playerId: string,
    signature: string,
  ): Promise<IResponse<null>> => {
    return Base().get(
      `/public/cups/validate-signature-email-invitation/${cupSlug}/${playerId}/${signature}`,
    );
  },
  GetList: async (
    teamId: number,
    limit?: number,
    page?: number,
    isDummy?: boolean,
  ): Promise<any> => {
    const query = `${limit ? `limit=${limit}` : ''}${page ? `&page=${page}` : ''}`;
    if (isDummy) {
      const dummyData: IBaseResponse<Array<IPlayer>> = Dummy.player.GetList(
        Math.floor(Math.random() * Math.floor(limit || 5)),
      );
      dummyData.data = dummyData.data?.map((d) => ({
        ...d,
        team: {
          ...d.team,
          id: teamId,
          name: `Team ${teamId}`,
          slug: `team-${teamId}`,
        } as ITeam,
      }));
      return new Promise((resolve) => {
        var blob = new Blob([JSON.stringify(dummyData, null, 2)], { type: 'application/json' });
        resolve(new Response(blob));
      });
    }
    return Base().get(`/private/teams/${teamId}/players?${query}`);
  },
  EditPlayer: async (payload: any, teamId: number, playerId: number): Promise<any> => {
    return Base().put(`/private/teams/${teamId}/players/${playerId}`, payload);
  },
};
