import { Button, Col, List, Row, Typography, Image, Popover, Dropdown, Menu } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import AccountParticle from '@evos/account-particle';
import GeneralParticle from '@evos/general-particle';
import ICup from '@evos/general-particle/dist/interfaces/efc/cup';
import '@evos/account-particle/dist/index.css';
import moment from 'moment';
import Layout from '../../components/layout';
import { ReduxState } from '../../store/reducers';
import Actions from '../../store/actions';
import { IDashboardState } from '../../interfaces/states/dashboard';
import ENV from '../../utils/environment';
import { CUP_OWNER, CUP_SECTION, CUP_SECTION_HEADER } from '../../constants/cup-section';
import { ICupState } from '../../interfaces/states/cup';

const { Logout } = AccountParticle.API.Auth;
const { Numberize } = GeneralParticle.Utils.Format;
const { AccountBadge } = AccountParticle.Component;
const { Encrypt } = GeneralParticle.Utils.Crypt;

interface IProps {
  dashboard: IDashboardState;
  getSummary: () => Promise<any>;

  cup: ICupState;
  getCupList: (cupSection: CUP_SECTION, page?: number) => Promise<any>;
}

const DashboardPage = (props: IProps) => {
  const { dashboard, getSummary, cup, getCupList } = props;
  const history = useHistory();
  const { location } = window;

  React.useEffect(() => {
    getSummary();
    getCupList(CUP_SECTION.running, 1);
    getCupList(CUP_SECTION.past, 1);
  }, [getCupList, getSummary]);

  const renderEmpty = (section: CUP_SECTION) => (
    <Row justify="center" align="middle" className="summary ph-1 pv-2">
      <Col span={24}>
        {section === CUP_SECTION.past ? (
          <Row justify="center">
            <Typography.Text className="text-size-14 text-height-21 text-center">
              Bagian ini akan menampilkan kompetisi yang pernah Kamu ikuti sebelumnya
            </Typography.Text>
          </Row>
        ) : undefined}
        {section === CUP_SECTION.running ? (
          <>
            <Row justify="center">
              <div className="icon-trophy text-size-40" />
            </Row>
            <Row justify="center">
              <Typography.Text className="text-size-14 text-height-21 text-center">
                Saat ini Kamu sedang tidak berpartisipasi dalam kompetisi apapun
              </Typography.Text>
            </Row>
            <Row justify="center" className="mt--5">
              <Button type="primary" href="/" size="large">
                Lihat Kompetisi
              </Button>
            </Row>
          </>
        ) : undefined}
      </Col>
    </Row>
  );

  return (
    <Layout
      header={{
        left: (
          <Button
            ghost
            icon={<ArrowLeftOutlined />}
            size="small"
            onClick={() => {
              history.goBack();
            }}
            className="menu-button"
          >
            PROFIL
          </Button>
        ),
        middle: <></>,
        right: (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item className="pv--75">
                  <a href={`${ENV.MY_URL}/settings`}>
                    <Row>
                      <div className="icon-settings text-size-20 mr--5" />
                      Pengaturan
                    </Row>
                  </a>
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    Logout(ENV.PARTICLE_ENV);
                    history.push('/');
                  }}
                  className="pv--75"
                >
                  <Row>
                    <div className="icon-log-out text-size-20 mr--5" />
                    Keluar
                  </Row>
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
            arrow
            trigger={['click', 'hover']}
            overlayClassName="header-dropdown"
          >
            <Button type="link" className="p-0">
              <div className="icon-more-vertical text-size-18" />
            </Button>
          </Dropdown>
        ),
      }}
      bottomNav
    >
      <Row className="mh-1 mv-2" align="middle" wrap={false}>
        <Col span={24}>
          <AccountBadge
            language="id"
            env={ENV.PARTICLE_ENV}
            onClickMember={() => (location.href = `${ENV.MY_URL}/profile/package`)}
            onClickEMP={() => (location.href = `${ENV.MY_URL}/profile/point-history`)}
            onClickAddPhone={() => (location.href = `${ENV.MY_URL}/verification/sms`)}
            onVerify={() =>
              (location.href = `${ENV.MY_URL}/verification/email/?referrer=${Encrypt(
                '',
                location.origin,
              )}`)
            }
          />
        </Col>
      </Row>
      <Row className="m-1">
        <Typography.Text className="text-size-18 text-height-21">Dashboard</Typography.Text>
      </Row>
      <Row className="m-1">
        <Col span={24} className="summary p-1">
          <Row className="mb-1">
            <Typography.Text className="text-size-14 text-height-21 text-weight-bold">
              Statistik Saya
            </Typography.Text>
          </Row>
          <Row>
            <Col span={24}>
              <Row className="mb--5" align="middle">
                <Col span={12}>
                  <Popover
                    content={
                      <>
                        <Row>
                          <Typography.Paragraph className="text-size-14 text-height-21">
                            Total seluruh hadiah yang telah Kamu menangkan (termasuk nilai konversi
                            hadiah non-tunai)
                          </Typography.Paragraph>
                        </Row>
                      </>
                    }
                    placement="rightBottom"
                  >
                    <Button type="link" className="p-0">
                      <Typography.Text className="text-size-14 text-height-21 text-color-blue">
                        Jumlah Hadiah
                      </Typography.Text>
                    </Button>
                  </Popover>
                </Col>
                <Col span={12}>
                  <Typography.Text className="text-size-14 text-height-21">
                    Jumlah Partisipasi
                  </Typography.Text>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Typography.Text strong className="text-size-16 text-height-24 text-weight-bold">
                    {dashboard.data?.totalPrizes && dashboard.data?.totalPrizes > 0
                      ? Numberize(dashboard.data.totalPrizes, '.', 'Rp')
                      : 'Rp 0'}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text strong className="text-size-16 text-height-24 text-weight-bold">
                    {dashboard.data?.totalCupParticipations &&
                    dashboard.data?.totalCupParticipations > 0
                      ? Numberize(dashboard.data.totalCupParticipations)
                      : '-'}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {[CUP_SECTION.running, CUP_SECTION.past].map((l: number) => {
        const list = cup.list[`${CUP_OWNER.user}_${l}`];
        return (
          <Row key={l} className="m-1" justify="center">
            <Col span={24}>
              <List
                className="team-log-list"
                grid={{
                  column: 1,
                }}
                dataSource={list?.value}
                renderItem={(item: ICup) => {
                  return (
                    <List.Item>
                      <a
                        className="team-log-item"
                        href={`/${item.slug}/standings/${item.id}/${item.teamLog?.stage?.id}/${item.teamLog?.group?.id}`}
                      >
                        <Row className="p-1" wrap={false}>
                          <Col flex="90px">
                            <Image
                              src={item.image || '/images/default.png'}
                              width={90}
                              height={90}
                              preview={false}
                            />
                          </Col>
                          <Col flex="auto" className="ml-1">
                            {item.registrationStartAt ? (
                              <Row>
                                <Typography.Text className="text-size-12 text-height-18">
                                  {moment.unix(item.registrationStartAt).format('DD MMM YYYY')}
                                </Typography.Text>
                              </Row>
                            ) : undefined}
                            <Row>
                              <Typography.Text className="text-size-14 text-height-21 text-weight-bold">
                                {item.name}
                              </Typography.Text>
                            </Row>
                            {item.teamLog?.team?.name ? (
                              <>
                                <Row className="mt--5">
                                  <Typography.Text className="text-size-12 text-height-18">
                                    Tim
                                  </Typography.Text>
                                </Row>
                                <Row align="middle">
                                  <Typography.Text className="text-size-14 text-height-21">
                                    {item.teamLog?.team?.name}
                                  </Typography.Text>
                                  {!item.teamLog?.team?.isEligible || !item.teamLog?.teamReward ? (
                                    <div className="icon-alert-attention text-color-orange text-size-14" />
                                  ) : undefined}
                                </Row>
                              </>
                            ) : undefined}
                            {!item.teamLog?.team?.isEligible ? (
                              <Row>
                                <Typography.Text className="text-color-orange text-size-12 text-height-18">
                                  Belum memenuhi syarat
                                </Typography.Text>
                              </Row>
                            ) : undefined}
                            {item.teamLog?.teamReward ? (
                              <Row className="mt--5">
                                <Typography.Text className="text-size-12 text-height-18">
                                  Posisi
                                </Typography.Text>
                              </Row>
                            ) : undefined}
                            <Row>
                              <Typography.Text
                                className={`text-color-${
                                  item.teamLog?.teamReward ? 'green' : 'orange'
                                } text-size-14 text-height-21 text-weight-medium`}
                              >
                                {item.teamLog?.teamReward?.rewardTitle ||
                                  `${
                                    item.teamLog?.stage && item.teamLog?.group
                                      ? `${item.teamLog?.stage?.name}, ${item.teamLog?.group?.name}`
                                      : ''
                                  }`}
                              </Typography.Text>
                            </Row>
                          </Col>
                        </Row>
                        {item.teamLog?.teamReward ? (
                          <Row className="team-log-item-footer p-1">
                            <Col span={24}>
                              <Row>
                                <Typography.Text className="text-size-12 text-height-18">{`Hadiah senilai ${Numberize(
                                  item.teamLog?.teamReward?.rewardAmount || 0,
                                  '.',
                                  'Rp',
                                )}`}</Typography.Text>
                              </Row>
                              <Row>
                                <Typography.Text className="text-weight-medium text-size-16 text-height-24">
                                  {item.teamLog?.teamReward?.rewardDescription || '-'}
                                </Typography.Text>
                              </Row>
                              <Row className="mt--5">
                                <Typography.Text className="text-size-12 text-height-18">
                                  Status
                                </Typography.Text>
                              </Row>
                              <Row>
                                {item.teamLog?.teamReward?.rewardStatus ? (
                                  <Typography.Text className="text-color-green text-size-14 text-height-21 text-weight-medium">
                                    {item.teamLog?.teamReward?.rewardStatus}
                                  </Typography.Text>
                                ) : (
                                  <Typography.Text className="text-size-14 text-height-21 text-weight-medium">
                                    {'-'}
                                  </Typography.Text>
                                )}
                              </Row>
                            </Col>
                          </Row>
                        ) : undefined}
                      </a>
                    </List.Item>
                  );
                }}
                header={
                  <Row className="list-header">
                    <Col>
                      <Row justify="start">
                        <Typography.Text className="text-size-18 text-height-21 text-weight-regular">
                          {CUP_SECTION_HEADER[l]}
                        </Typography.Text>
                      </Row>
                    </Col>
                  </Row>
                }
                loading={list?.requesting}
                footer={
                  list?.paging?.hasNext ? (
                    <Button
                      ghost
                      type="primary"
                      block
                      size="large"
                      onClick={() => {
                        getCupList(l, (list.paging?.currentPage || 1) + 1);
                      }}
                    >
                      Tampilkan lebih banyak
                    </Button>
                  ) : undefined
                }
                locale={{ emptyText: renderEmpty(l) }}
              />
            </Col>
          </Row>
        );
      })}
    </Layout>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  dashboard: state.dashboard,
  cup: state.cup,
});

const mapDispatchToProps = (dispatch: any) => ({
  getSummary: () => dispatch(Actions.dashboard.GetSummary(false)),
  getCupList: (cupSection: CUP_SECTION, page?: number) =>
    dispatch(Actions.cup.GetList(cupSection, CUP_OWNER.user, page, undefined, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
