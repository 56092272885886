import Base from './base';
import Dummy from '../../constants/dummy';
import { CUP_OWNER, CUP_SECTION } from '../../constants/cup-section';

export default {
  GetList: async (
    cupSection: CUP_SECTION,
    cupOwner: CUP_OWNER,
    page?: number,
    limit?: number,
    isDummy?: boolean,
  ): Promise<any> => {
    const query = `limit=${limit || 10}&page=${page || 1}${
      cupSection ? `&cupSection=${cupSection}` : ''
    }`;
    if (isDummy) {
      let dummyData = Dummy.cup.getListOpeningResponseDummy;
      if (cupSection === CUP_SECTION.running) dummyData = Dummy.cup.getListRunningResponseDummy;
      if (cupSection === CUP_SECTION.past) dummyData = Dummy.cup.getListPastResponseDummy;

      return new Promise((resolve) => {
        var blob = new Blob([JSON.stringify(dummyData, null, 2)], { type: 'application/json' });
        resolve(new Response(blob));
      });
    }
    return Base().get(
      `${cupOwner === CUP_OWNER.user ? '/private/cups/user' : '/public/cups'}?${query}`,
    );
  },
  GetDetailById: async (cupId: number, isDummy?: boolean): Promise<any> => {
    if (isDummy) {
      let dummyData = Dummy.cup.getDetailResponseDummy;
      return new Promise((resolve) => {
        var blob = new Blob([JSON.stringify(dummyData, null, 2)], { type: 'application/json' });
        resolve(new Response(blob));
      });
    }
    return Base().get(`/public/cups/${cupId}`);
  },
  GetDetailBySlug: async (slug: string, isDummy?: boolean): Promise<any> => {
    if (isDummy) {
      let dummyData = Dummy.cup.getDetailResponseDummy;
      if (slug === 'ff-s3') dummyData = Dummy.cup.getDetailResponseDummy2;
      return new Promise((resolve) => {
        var blob = new Blob([JSON.stringify(dummyData, null, 2)], { type: 'application/json' });
        resolve(new Response(blob));
      });
    }
    return Base().get(`/public/cups/slug/${slug}`);
  },
};
