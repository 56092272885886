import moment from 'moment';
import { IPlayer } from '@evos/general-particle/dist/interfaces/efc';
import IBaseResponse from '@evos/general-particle/dist/interfaces/api/result';
import CUP from './cup';
import GAME from './game';
import TEAM from './team';

const playerNames = [
  'Evillious Energy',
  'Duke of Doom',
  'Eat Bullets',
  'Monstrous Michel',
  'Collateral Damage',
  'Gabriel Groomer',
  'Shoot 2 Kill',
  'Annihilator',
  'Veteran of Death',
  'Walk Alone Bravely',
  'Tonight Gamer',
  'Walking Pegasus',
  'Dead Deal',
  'Killer Romeo',
  'Overkill',
  'Best Bluster',
  'Brute Fact',
  'Green Ghost',
  'Bloss Flop',
  'Terrific Tornado',
  'Tango Boss',
  'Accurate Arrow',
  'Kill Spree',
  'Optimal Aces',
  'Dark Knight',
  'Inimical Thugs',
  'Knuckle Duster',
  'Local Back Stabber',
  'Happy Killing',
  'Fear Butchers',
  'Guncap Slingbad',
  'Highlander Monk',
  'Left Divide',
  'Jack The Ripper',
  'Hog Butcher',
  'Psychedelic Servicemen',
  'Militaristic Fighting Machine',
  'Keen Team Six',
  'Junkyard Dog',
  'Fuzzy Pack',
  'Straight Gangsters',
  'Mortified Coercion',
  'Lyrical Armed Services',
  'Outrageous Dominance',
  'Brash Thugs',
  'Complex Slayers',
  'Faulty Devils',
  'Odd Hooligans',
  'Organic Punks',
  'Hungry Admirals',
  'Cloudy Perpetrator',
  'Keen Team Six',
  'PUBJESUS',
  'Inimical Thugs',
  'Agent Hercules',
  'Metal Star',
  'Night Magnet',
  'Headshooter',
  'Optimal Aces',
  'Alpha Returns',
  'Dancing Madman',
  'Abnormal Vigor',
  'Ball Blaster',
  'Kill Switch',
  'Pixie Soldier',
  'Pro Headshot',
  'Grave Digger',
  'Cool Shooter',
  'Dead Shot',
  'Thunderbeast',
  'Bad soldier',
  'Local Grim Reaper',
  'Captain Jack Sparrow',
  'Outrageous Dominance',
  'Agent 47',
  'Militaristic Fighting Machine',
  'PUBGian',
  'Lyrical Armed Services',
  'Quarrelsome Strategy',
  'Hungry Admirals',
  'Cloudy Perpetrator',
  'Fuzzy Pack',
  'Straight Gangsters',
  'Psychedelic Servicemen',
  'Homely Sharpshooters',
  'Plain Privileg',
  'Annoyed Power',
  'Demonic Criminals',
  'Fear Butchers',
  'Left Divide',
];

const GenerateSinglePlayer = (id?: number): IPlayer => {
  const usedId = id || 1;
  const playerName =
    playerNames[Math.floor(Math.random() * Math.floor(playerNames.length))] || `player${usedId}`;
  return {
    id: usedId,
    userId: `player${usedId}`,
    team: TEAM.GenerateSingleTeam(usedId),
    cup: CUP.getDetailResponseDummy.data,
    game: GAME.GenerateSingleGame(usedId),
    phoneNumber: '08xxxxxxxx',
    fullName: playerName,
    invitedEmail: `${playerName}@player.gg`,
    isEmailConfirmed: true,
    isCaptain: false,
    surveyResponse: '',
    additionalData: `{"inGameName": "${playerName}"}`,
    createdAt: moment().unix(),
    updatedAt: moment().unix(),
  };
};

const GetList = (numberOfData: number): IBaseResponse<Array<IPlayer>> => {
  const data: Array<IPlayer> = [];
  for (let i = 0; i < numberOfData; i++) {
    const newData = GenerateSinglePlayer(i + 1);
    data.push(newData);
  }
  return {
    success: true,
    data,
    paging: {
      hasNext: false,
      currentPage: 1,
      totalData: numberOfData,
      totalPages: 1,
      limit: numberOfData,
    },
  };
};

export default { GenerateSinglePlayer, GetList };
