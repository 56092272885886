import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import IBaseResponse from '@evos/general-particle/dist/interfaces/api/result';
import IPlayer from '@evos/general-particle/dist/interfaces/efc/player';
import Api from '../apis';
import Base from './base';
import ActionTypes from '../../constants/action-types';

const GetList = (
  gameID: number,
  limit?: number,
  page?: number,
  isDummy?: boolean,
  callback?: Function,
) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(
    dispatch,
    Api.team.GetList(gameID, limit, page, isDummy),
    () => {
      return action(ActionTypes.team.GET_EXISTING_TEAM_LIST_REQUEST);
    },
    (response: IBaseResponse<Array<IPlayer>>) => {
      return action(ActionTypes.team.GET_EXISTING_TEAM_LIST_SUCCESS, {
        list: response.data,
        paging: response.paging,
      });
    },
    (error: string) => {
      return action(ActionTypes.team.GET_EXISTING_TEAM_LIST_FAILED, {
        error,
      });
    },
    callback,
  );
};

const RemoveTeamList = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch(action(ActionTypes.team.REMOVE_TEAM_LIST));
};

export default {
  GetList,
  RemoveTeamList,
};
