import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import IBaseResponse from '@evos/general-particle/dist/interfaces/api/result';
import IDashboard from '@evos/general-particle/dist/interfaces/efc/dashboard';
import Api from '../apis';
import Base from './base';
import ActionTypes from '../../constants/action-types';

const GetSummary = (isDummy?: boolean, callback?: Function) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(
    dispatch,
    Api.dashboard.GetSummary(isDummy),
    () => action(ActionTypes.dashboard.GET_DASHBOARD_SUMMARY_REQUEST),
    (response: IBaseResponse<IDashboard>) => {
      return action(ActionTypes.dashboard.GET_DASHBOARD_SUMMARY_SUCCESS, {
        data: response.data,
      });
    },
    (error: string) => {
      return action(ActionTypes.dashboard.GET_DASHBOARD_SUMMARY_FAILED, {
        error,
      });
    },
    callback,
  );
};

export default {
  GetSummary,
};
