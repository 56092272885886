import { combineReducers } from 'redux';
import cup from './cup';
import dashboard from './dashboard';
import group from './group';
import player from './player';
import stage from './stage';
import teamLog from './team-log';
import team from './team';
import { ICupState } from '../../interfaces/states/cup';
import { IPlayerState } from '../../interfaces/states/player';
import { IDashboardState } from '../../interfaces/states/dashboard';
import { ITeamLogState } from '../../interfaces/states/team-log';
import { IStageState } from '../../interfaces/states/stage';
import { IGroupState } from '../../interfaces/states/group';
import { ITeamState } from '../../interfaces/states/team';

export interface ReduxState {
  cup: ICupState;
  dashboard: IDashboardState;
  group: IGroupState;
  player: IPlayerState;
  stage: IStageState;
  teamLog: ITeamLogState;
  team: ITeamState;
}

export const rootReducer = combineReducers<ReduxState>({
  cup,
  dashboard,
  group,
  player,
  stage,
  teamLog,
  team,
});
