import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import IBaseResponse from '@evos/general-particle/dist/interfaces/api/result';
import IPlayer from '@evos/general-particle/dist/interfaces/efc/player';
import Api from '../apis';
import Base from './base';
import ActionTypes from '../../constants/action-types';
import { IRegisterPlayer } from '../../interfaces/requests/player';

const Register = (data: IRegisterPlayer, isDummy?: boolean, callback?: Function) => (
  dispatch: Dispatch<AnyAction>,
) => {
  Base.CallAction(
    dispatch,
    Api.player.Register(data, isDummy),
    () => action(ActionTypes.player.REGISTER_PLAYER_REQUEST),
    (response: IBaseResponse<Array<IPlayer>>) => {
      return action(ActionTypes.player.REGISTER_PLAYER_SUCCESS, {
        list: response.data,
      });
    },
    (error: string) => {
      return action(ActionTypes.player.REGISTER_PLAYER_FAILED, {
        error,
      });
    },
    callback,
  );
};

const CheckRegister = (cupId: string, isDummy?: boolean, callback?: Function) => (
  dispatch: Dispatch<AnyAction>,
) => {
  Base.CallAction(
    dispatch,
    Api.player.CheckRegister(cupId, isDummy),
    () => action(ActionTypes.player.CHECK_REGISTER_REQUEST),
    (response: IBaseResponse<Array<IPlayer>>) => {
      return action(ActionTypes.player.CHECK_REGISTER_SUCCESS, {
        data: response.data,
      });
    },
    (error: string) => {
      return action(ActionTypes.player.CHECK_REGISTER_FAILED, {
        error,
      });
    },
    callback,
  );
};

const EditPlayer = (payload: any, teamId: number, playerId: number, callback?: Function) => (
  dispatch: Dispatch<AnyAction>,
) => {
  Base.CallAction(
    dispatch,
    Api.player.EditPlayer(payload, teamId, playerId),
    () => action(ActionTypes.player.EDIT_PLAYER_REQUEST),
    (response: IBaseResponse<Array<IPlayer>>) => {
      return action(ActionTypes.player.EDIT_PLAYER_SUCCESS, {
        data: response.data,
      });
    },
    (error: string) => {
      return action(ActionTypes.player.EDIT_PLAYER_FAILED, {
        error,
      });
    },
    callback,
  );
};

const ConfirmInvitation = (
  cupSlug: string,
  playerId: string,
  signature: string,
  callback?: Function,
) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(
    dispatch,
    Api.player.ConfirmInvitation(cupSlug, playerId, signature),
    () => action(ActionTypes.player.CONFIRM_INVITATION_REQUEST),
    (response: IBaseResponse<Array<IPlayer>>) => {
      return action(ActionTypes.player.CONFIRM_INVITATION_SUCCESS, {
        data: response.data,
      });
    },
    (error: string) => {
      return action(ActionTypes.player.CONFIRM_INVITATION_FAILED, {
        error,
      });
    },
    callback,
  );
};

const ValidateSignature = (cupSlug: string, playerId: string, signature: string) => async () => {
  return Api.player
    .ValidateSignature(cupSlug, playerId, signature)
    .then((res) => res)
    .catch((err) => err.response);
};

const RemovePlayerList = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch(action(ActionTypes.player.REMOVE_PLAYER_LIST_SUCCESS));
};

const GetList = (
  teamId: number,
  limit?: number,
  page?: number,
  isDummy?: boolean,
  callback?: Function,
) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(
    dispatch,
    Api.player.GetList(teamId, limit, page, isDummy),
    () => {
      return action(ActionTypes.player.GET_PLAYER_LIST_REQUEST);
    },
    (response: IBaseResponse<Array<IPlayer>>) => {
      return action(ActionTypes.player.GET_PLAYER_LIST_SUCCESS, {
        list: response.data,
        paging: response.paging,
      });
    },
    (error: string) => {
      return action(ActionTypes.player.GET_PLAYER_LIST_FAILED, {
        error,
      });
    },
    callback,
  );
};

export default {
  Register,
  CheckRegister,
  ConfirmInvitation,
  RemovePlayerList,
  GetList,
  ValidateSignature,
  EditPlayer,
};
