import moment from 'moment';
import ICup from '@evos/general-particle/dist/interfaces/efc/cup';
import IBaseResponse from '@evos/general-particle/dist/interfaces/api/result';

const now = new Date();
const random = Math.floor(Math.random() * 10);

// eslint-disable-next-line no-undef
const getListOpeningResponseDummy: IBaseResponse<Array<ICup>> = {
  success: true,
  data: [
    {
      id: 3,
      game: {
        id: 1,
        name: 'Fortnite Deadly Duo',
        label: '',
        isActive: true,
        playersPerTeam: 2,
        additionalFields: '[{"name": "inGameID", "type": "text", "label": "Player ID"}]',
        sort: 1,
        createdAt: 0,
        updatedAt: 0,
      },
      name: 'Fortnite Deadly',
      subtitle: '<p>Registrasi gratis!!</p>',
      image:
        'https://staticr1.blastingcdn.com/media/photogallery/2019/12/7/660x290/b_502x220/15-million-fortnite-tournaments-are-coming-this-month-image-source-bugha-youtube_2366478.jpg',
      slug: 'fortnite-deadly-duo',
      bracketUrl: '',
      descriptionOpen: 'opening',
      descriptionClose: 'closing',
      pointReward: 250,
      registrationStartAt: moment(now).subtract(1, 'day').unix(),
      registrationEndAt: moment(now).add(1, 'day').unix(),
      startAt: moment(now).add(2, 'day').unix(),
      endAt: moment(now).add(4, 'day').unix(),
      surveyQuestion: 'question?',
      isDeleted: false,
      createdAt: moment().unix(),
      updatedAt: moment().unix(),
      totalPrize: Math.floor(Math.random() * Math.floor(10000000)),
    },
    {
      id: 2,
      game: {
        id: 1,
        name: 'Free Fire Season 3',
        label: '',
        isActive: true,
        playersPerTeam: 2,
        additionalFields: '[{"name": "inGameID", "type": "text", "label": "Player ID"}]',
        sort: 1,
        createdAt: 0,
        updatedAt: 0,
      },
      name: 'Free Fire Season 3',
      subtitle: '<p>Registrasi gratis!!</p>',
      image: 'https://ivpl.co.id/app/uploads/2018/12/Garena.jpg',
      slug: 'ff-s3',
      bracketUrl: '',
      descriptionOpen: 'opening',
      descriptionClose: 'closing',
      pointReward: 250,
      registrationStartAt: moment(now).subtract(1, 'day').unix(),
      registrationEndAt: moment(now).add(1, 'day').unix(),
      startAt: moment(now).add(2, 'day').unix(),
      endAt: moment(now).add(4, 'day').unix(),
      surveyQuestion: 'question?',
      isDeleted: false,
      createdAt: 0,
      updatedAt: 0,
      totalPrize: Math.floor(Math.random() * Math.floor(10000000)),
    },
  ],
  paging: { hasNext: false, currentPage: 1, totalPages: 1, totalData: 3, limit: 10 },
};

const getListRunningResponseDummy: IBaseResponse<Array<ICup>> = {
  success: true,
  data: [
    {
      id: 3,
      game: {
        id: 1,
        name: 'Auto Chess President Cup',
        label: '',
        isActive: true,
        playersPerTeam: 2,
        additionalFields: '[{"name": "inGameID", "type": "text", "label": "Player ID"}]',
        sort: 1,
        createdAt: 0,
        updatedAt: 0,
      },
      name: 'Auto Chess President Cup',
      subtitle: '<p>Format Elimination Bracket</p>',
      image: 'https://cdn.medcom.id/dynamic/content/2020/05/29/1148697/Az3hb4m9DK.jpg?w=480',
      slug: 'auto-checc-president-cup',
      bracketUrl: '',
      descriptionOpen: 'opening',
      descriptionClose: 'closing',
      pointReward: 250,
      registrationStartAt: moment(now).subtract(2, 'day').unix(),
      registrationEndAt: moment(now).subtract(1, 'day').unix(),
      startAt: moment(now).add(2, 'day').unix(),
      endAt: moment(now).add(4, 'day').unix(),
      surveyQuestion: 'question?',
      isDeleted: false,
      createdAt: moment().unix(),
      updatedAt: moment().unix(),
      totalPrize: Math.floor(Math.random() * Math.floor(10000000)),
    },
    {
      id: 2,
      game: {
        id: 1,
        name: 'Weekly War Magic Chess Season 1',
        label: '',
        isActive: true,
        playersPerTeam: 2,
        additionalFields: '[{"name": "inGameID", "type": "text", "label": "Player ID"}]',
        sort: 1,
        createdAt: 0,
        updatedAt: 0,
      },
      name: 'Weekly War Magic Chess Season 1',
      subtitle: '<p>Hadiah Total Rp20.000.000!!</p><p>Registrasi hingga 31 Januari 2021</p>',
      image:
        'https://esportsnesia.com/wp-content/uploads/2020/05/magic-chess-bang-bang-696x464.jpg',
      slug: 'weekly-war-magic-chess-s1',
      bracketUrl: '',
      descriptionOpen: 'opening',
      descriptionClose: 'closing',
      pointReward: 250,
      registrationStartAt: moment(now).subtract(2, 'day').unix(),
      registrationEndAt: moment(now).subtract(1, 'day').unix(),
      startAt: moment(now).add(2, 'day').unix(),
      endAt: moment(now).add(4, 'day').unix(),
      surveyQuestion: 'question?',
      isDeleted: false,
      createdAt: 0,
      updatedAt: 0,
      totalPrize: Math.floor(Math.random() * Math.floor(10000000)),
    },
  ],
  paging: { hasNext: false, currentPage: 1, totalPages: 1, totalData: 3, limit: 10 },
};

const getListPastResponseDummy: IBaseResponse<Array<ICup>> = {
  success: true,
  data: [
    {
      id: 3,
      game: {
        id: 1,
        name: 'Fortnite Deadly Duo',
        label: '',
        isActive: true,
        playersPerTeam: 2,
        additionalFields: '[{"name": "inGameID", "type": "text", "label": "Player ID"}]',
        sort: 1,
        createdAt: 0,
        updatedAt: 0,
      },
      name: 'Fortnite Deadly',
      subtitle: '<p>Registrasi gratis!!</p>',
      image:
        'https://staticr1.blastingcdn.com/media/photogallery/2019/12/7/660x290/b_502x220/15-million-fortnite-tournaments-are-coming-this-month-image-source-bugha-youtube_2366478.jpg',
      slug: 'fortnite-deadly-duo',
      bracketUrl: '',
      descriptionOpen: 'opening',
      descriptionClose: 'closing',
      pointReward: 250,
      registrationStartAt: moment(now).subtract(3, 'day').unix(),
      registrationEndAt: moment(now).subtract(2, 'day').unix(),
      startAt: moment(now).subtract(12, 'day').unix(),
      endAt: moment(now).subtract(10, 'hour').unix(),
      surveyQuestion: 'question?',
      isDeleted: false,
      createdAt: moment().unix(),
      updatedAt: moment().unix(),
      totalPrize: Math.floor(Math.random() * Math.floor(10000000)),
    },
    {
      id: 2,
      game: {
        id: 1,
        name: 'PUBGM Duo',
        label: '',
        isActive: true,
        playersPerTeam: 2,
        additionalFields: '[{"name": "inGameID", "type": "text", "label": "Player ID"}]',
        sort: 1,
        createdAt: 0,
        updatedAt: 0,
      },
      name: 'PUBGM Lively Duo',
      subtitle: '<p>Total Prize: IDR 5.000.000</p><p>31 Januari 2021</p>',
      image: 'https://i.ytimg.com/vi/PzIgvEy5Pxg/maxresdefault.jpg',
      slug: 'deadly-duo',
      bracketUrl: '',
      descriptionOpen: 'opening',
      descriptionClose: 'closing',
      pointReward: 250,
      registrationStartAt: moment(now).subtract(4, 'day').unix(),
      registrationEndAt: moment(now).subtract(3, 'day').unix(),
      startAt: moment(now).subtract(4, 'day').unix(),
      endAt: moment(now).subtract(2, 'day').unix(),
      surveyQuestion: 'question?',
      isDeleted: false,
      createdAt: 0,
      updatedAt: 0,
      totalPrize: Math.floor(Math.random() * Math.floor(10000000)),
    },
  ],
  paging: { hasNext: false, currentPage: 1, totalPages: 1, totalData: 3, limit: 10 },
};

const getDetailResponseDummy: IBaseResponse<ICup> = {
  success: true,
  data: {
    id: 2,
    game: {
      id: 1,
      name: 'PUBGM Duo',
      label: '',
      isActive: true,
      playersPerTeam: 2,
      additionalFields:
        '[{"name":"inGameName","type":"text","label":"Nickname","rules":[{"max":15,"message":"Panjang maksimal 15 karakter"}]},{"name":"inGameID","type":"text","label":"Player ID","rules":[]}]',
      sort: 1,
      createdAt: 0,
      updatedAt: 0,
    },
    name: 'PUBGM Lively Duo',
    subtitle: 'html',
    image: 'https://i.ytimg.com/vi/PzIgvEy5Pxg/maxresdefault.jpg',
    slug: 'deadly-duo',
    bracketUrl: 'url to spreadsheet',
    descriptionOpen:
      '<p><strong>Halo Fams!</strong></p><p>Kali ini EVOS FAMS CUP (EFC) mengadakan Weekly War Magic Chess Season 1.</p><p>&nbsp;</p><p><strong>Prizepool:</strong></p><p>20,000,000 EVOS MEMBERSHIP POINTS</p><p>&nbsp;</p><p><strong>Juara 1</strong></p><p>10,000,000 EMP + E-Certificate Official EVOS Family&nbsp;</p><p><strong>Juara 2</strong></p><p>6,000,000 EMP + E-Certificate Official EVOS Family&nbsp;</p><p><strong>Juara 3</strong></p><p>3.000,000 EMP + E-Certificate Official EVOS Family</p><p>&nbsp;</p><p><strong>MostKill</strong></p><ol><li>1,000,000 + E-Certificate Official EVOS Family</li></ol><p>&nbsp;</p><p>&nbsp;</p>',
    descriptionClose:
      '<p>Terima kasih sudah mendaftar di <strong>EVOS FAMS Weekly War #1</strong>, <strong>Technical Meeting (Pembagian Group dan Jadwal Tournament) </strong>akan di share di <strong>WhatsApp Group</strong> setelah <strong>Pendaftaran ditutup.</strong></p><p>&nbsp;</p><p>Silahkan tunggu team kami yang akan menginvite kalian di Group WhatsApp, pastikan nomor kalian aktif.</p>',
    pointReward: 250,
    registrationStartAt: moment(now).subtract(1, 'day').unix(),
    registrationEndAt: moment(now).add(1, 'day').unix(),
    startAt: moment(now).add(2, 'day').unix(),
    endAt: moment(now).add(4, 'day').unix(),
    surveyQuestion: 'Mengapa kamu ingin bermain di EFC?',
    isDeleted: false,
    createdAt: 0,
    updatedAt: 0,
    totalPrize: Math.floor(Math.random() * Math.floor(10000000)),
  },
};

const getDetailResponseDummy2: IBaseResponse<ICup> = {
  success: true,
  data: {
    id: 2,
    game: {
      id: 1,
      name: 'PUBGM Duo',
      label: '',
      isActive: true,
      playersPerTeam: 2,
      additionalFields:
        '[{"name":"inGameName","type":"text","label":"Nickname","rules":[{"max":15,"message":"Panjang maksimal 15 karakter"}]},{"name":"inGameID","type":"text","label":"Player ID","rules":[]}]',
      sort: 1,
      createdAt: 0,
      updatedAt: 0,
    },
    name: 'PUBGM Lively Duo',
    subtitle: 'html',
    image: 'https://i.ytimg.com/vi/PzIgvEy5Pxg/maxresdefault.jpg',
    slug: 'ff-s3',
    bracketUrl: 'url to spreadsheet',
    descriptionOpen:
      '<p><strong>Halo Fams!</strong></p><p>Kali ini EVOS FAMS CUP (EFC) mengadakan Weekly War Magic Chess Season 1.</p><p>&nbsp;</p><p><strong>Prizepool:</strong></p><p>20,000,000 EVOS MEMBERSHIP POINTS</p><p>&nbsp;</p><p><strong>Juara 1</strong></p><p>10,000,000 EMP + E-Certificate Official EVOS Family&nbsp;</p><p><strong>Juara 2</strong></p><p>6,000,000 EMP + E-Certificate Official EVOS Family&nbsp;</p><p><strong>Juara 3</strong></p><p>3.000,000 EMP + E-Certificate Official EVOS Family</p><p>&nbsp;</p><p><strong>MostKill</strong></p><ol><li>1,000,000 + E-Certificate Official EVOS Family</li></ol><p>&nbsp;</p><p>&nbsp;</p>',
    descriptionClose:
      '<p>Terima kasih sudah mendaftar di <strong>EVOS FAMS Weekly War #1</strong>, <strong>Technical Meeting (Pembagian Group dan Jadwal Tournament) </strong>akan di share di <strong>WhatsApp Group</strong> setelah <strong>Pendaftaran ditutup.</strong></p><p>&nbsp;</p><p>Silahkan tunggu team kami yang akan menginvite kalian di Group WhatsApp, pastikan nomor kalian aktif.</p>',
    pointReward: 250,
    registrationStartAt: moment(now).subtract(1, 'day').unix(),
    registrationEndAt: moment(now).add(1, 'day').unix(),
    startAt: moment(now).add(2, 'day').unix(),
    endAt: moment(now).add(4, 'day').unix(),
    surveyQuestion: 'Mengapa kamu ingin bermain di EFC?',
    isDeleted: false,
    createdAt: 0,
    updatedAt: 0,
    totalPrize: Math.floor(Math.random() * Math.floor(10000000)),
  },
};

const getDetailClosedResponseDummy: IBaseResponse<ICup> = {
  success: true,
  data: {
    id: 2,
    game: {
      id: 1,
      name: 'PUBGM Duo closed',
      label: '',
      isActive: true,
      playersPerTeam: 2,
      additionalFields:
        '[{"name":"inGameName","type":"text","label":"Nickname","rules":[{"max":15,"message":"Panjang maksimal 15 karakter"}]},{"name":"inGameID","type":"text","label":"Player ID","rules":[]}]',
      sort: 1,
      createdAt: 0,
      updatedAt: 0,
    },
    name: 'PUBGM Lively Duo',
    subtitle: 'html',
    image: 'https://i.ytimg.com/vi/PzIgvEy5Pxg/maxresdefault.jpg',
    slug: 'deadly-duo',
    bracketUrl: 'url to spreadsheet',
    descriptionOpen:
      '<p>Halo Fams!<br />Kali ini EVOS FAMS CUP (EFC) mengadakan</p><p>Prizepool<br />2,000,000 EVOS MEMBERSHIP POINTS</p><p>Juara 1<br />1,000,000 EMP + E-Certificate Official EVOS Family <br />Juara 2<br />500,000 EMP + E-Certificate Official EVOS Family <br />Juara 3<br />250,000 EMP + E-Certificate Official EVOS Family</p><p>MostKill<br />250,000 + E-Certificate Official EVOS Family</p><hr /><p>Syarat:</p><ul><li><span style="white-space: pre-wrap;">Daftarkan team dan anggota kalian dengan lengkap </span></li><li><span style="white-space: pre-wrap;">Team yang pertama kali mendaftar akan mendapatkan slot</span></li><li><span style="white-space: pre-wrap;">Pastikan setiap kapten memiliki discord</span></li><li><span style="white-space: pre-wrap;">Technical Meeting seputar Info tournament, shuffle pot &amp; bracket di adakan di </span><span style="white-space: pre-wrap;">discord EFC PUBGM pada tanggal 11 October 2020 pukul 20:00WIB</span></li></ul><hr /><p>Periode Pendaftaran<br />08 - 11 October 2020</p><p>Qualifier<br />12 October 2020</p><p>Semi Final<br />16 - 17 October 2020</p><p>Final<br />18 October 2020</p><p>FINAL AKAN LIVE DI YOUTUBE EVOS TV PUKUL 14:00 WIB</p><p>GOODLUCK FAMS!!! EVOS ROAR!!!</p>',
    descriptionClose: 'closing html',
    pointReward: 250,
    registrationStartAt: moment(now).subtract(3, 'day').unix(),
    registrationEndAt: moment(now).subtract(2, 'day').unix(),
    startAt: moment(now).subtract(2, 'day').unix(),
    endAt: moment(now).subtract(1, 'day').unix(),
    surveyQuestion: 'question?',
    isDeleted: false,
    createdAt: 0,
    updatedAt: 0,
    totalPrize: Math.floor(Math.random() * Math.floor(10000000)),
  },
};

const registerErrorResponse1Dummy = {
  success: false,
  errors: [
    {
      code: 1001,
      status: 400,
      message: 'dummy2@gmail.com is already registered in another team.',
    },
  ],
  data: null,
};

const registerErrorResponse2Dummy = {
  success: false,
  errors: [{ code: 1001, status: 400, message: 'team name is taken' }],
  data: null,
};

const checkRegisterResponseDummy = {
  success: true,
  errors: null,
  data: null,
  paging: null,
};

const registerSuccessResponseDummy = {
  success: true,
  errors: null,
  data: [
    {
      id: 56,
      cup: {
        id: 3,
        game: null,
        name: 'PUBGM Lively Duo',
        subtitle: '<p>Total Hadia 30.000.000 EMP</p><p>#stayathome #warfromhome</p>',
        image: 'https://i.ytimg.com/vi/PzIgvEy5Pxg/maxresdefault.jpg',
        slug: 'deadly-duo',
        bracketUrl: 'https://www.google.com',
        descriptionOpen:
          '<p><strong>Halo Fams!</strong></p><p>Kali ini EVOS FAMS CUP (EFC) mengadakan Weekly War Magic Chess Season 1.</p><p>&nbsp;</p><p><strong>Prizepool:</strong></p><p>20,000,000 EVOS MEMBERSHIP POINTS</p><p>&nbsp;</p><p><strong>Juara 1</strong></p><p>10,000,000 EMP + E-Certificate Official EVOS Family&nbsp;</p><p><strong>Juara 2</strong></p><p>6,000,000 EMP + E-Certificate Official EVOS Family&nbsp;</p><p><strong>Juara 3</strong></p><p>3.000,000 EMP + E-Certificate Official EVOS Family</p><p>&nbsp;</p><p><strong>MostKill</strong></p><ol><li>1,000,000 + E-Certificate Official EVOS Family</li></ol><p>&nbsp;</p><p>&nbsp;</p>',
        descriptionClose:
          '<p>Terima kasih sudah mendaftar di <strong>EVOS FAMS Weekly War #1</strong>, <strong>Technical Meeting (Pembagian Group dan Jadwal Tournament) </strong>akan di share di <strong>WhatsApp Group</strong> setelah <strong>Pendaftaran ditutup.</strong></p><p>&nbsp;</p><p>Silahkan tunggu team kami yang akan menginvite kalian di Group WhatsApp, pastikan nomor kalian aktif.</p>',
        pointReward: 250,
        startAt: 1612524420,
        endAt: 1614166020,
        surveyQuestion: 'Mengapa kamu ingin bermain di EFC?',
        isDeleted: false,
        createdAt: 1612525117,
        updatedAt: 1612950740,
      },
      game: {
        id: 1,
        name: 'PUBGM Duo',
        isActive: true,
        playersPerTeam: 2,
        backupPlayersPerTeam: 1,
        additionalFields:
          '[{"name":"inGameName","type":"text","label":"Nickname","rules":[{"max":15,"message":"Panjang maksimal 15 karakter"}]},{"name":"inGameID","type":"text","label":"Player ID","rules":[]}]',
        sort: 1,
        createdAt: 0,
        updatedAt: 0,
      },
      team: {
        id: 16,
        createdById: 'kSrcq2XW',
        name: 'EVOS HUNTER TEAM',
        game: null,
        cup: null,
      },
      fullName: 'Michael',
      userId: 'REOVRFNq',
      email: 'dummy01@gmail.com',
      phoneNumber: '0812325555',
      isEmailConfirmed: false,
      isCaptain: true,
      surveyResponse: '',
      additionalData: '{"inGameName":"DrDisrespect","inGameID":"12345"}',
      createdAt: 1613027713,
      updatedAt: 1613027713,
    },
    {
      id: 57,
      cup: {
        id: 3,
        game: null,
        name: 'PUBGM Lively Duo',
        subtitle: '<p>Total Hadia 30.000.000 EMP</p><p>#stayathome #warfromhome</p>',
        image: 'https://i.ytimg.com/vi/PzIgvEy5Pxg/maxresdefault.jpg',
        slug: 'deadly-duo',
        bracketUrl: 'https://www.google.com',
        descriptionOpen:
          '<p><strong>Halo Fams!</strong></p><p>Kali ini EVOS FAMS CUP (EFC) mengadakan Weekly War Magic Chess Season 1.</p><p>&nbsp;</p><p><strong>Prizepool:</strong></p><p>20,000,000 EVOS MEMBERSHIP POINTS</p><p>&nbsp;</p><p><strong>Juara 1</strong></p><p>10,000,000 EMP + E-Certificate Official EVOS Family&nbsp;</p><p><strong>Juara 2</strong></p><p>6,000,000 EMP + E-Certificate Official EVOS Family&nbsp;</p><p><strong>Juara 3</strong></p><p>3.000,000 EMP + E-Certificate Official EVOS Family</p><p>&nbsp;</p><p><strong>MostKill</strong></p><ol><li>1,000,000 + E-Certificate Official EVOS Family</li></ol><p>&nbsp;</p><p>&nbsp;</p>',
        descriptionClose:
          '<p>Terima kasih sudah mendaftar di <strong>EVOS FAMS Weekly War #1</strong>, <strong>Technical Meeting (Pembagian Group dan Jadwal Tournament) </strong>akan di share di <strong>WhatsApp Group</strong> setelah <strong>Pendaftaran ditutup.</strong></p><p>&nbsp;</p><p>Silahkan tunggu team kami yang akan menginvite kalian di Group WhatsApp, pastikan nomor kalian aktif.</p>',
        pointReward: 250,
        startAt: 1612524420,
        endAt: 1614166020,
        surveyQuestion: '',
        isDeleted: false,
        createdAt: 1612525117,
        updatedAt: 1612950740,
      },
      game: {
        id: 1,
        name: 'PUBGM Duo',
        isActive: true,
        playersPerTeam: 2,
        backupPlayersPerTeam: 1,
        additionalFields:
          '[{"name":"inGameName","type":"text","label":"Nickname","rules":[{"max":15,"message":"Panjang maksimal 15 karakter"}]},{"name":"inGameID","type":"text","label":"Player ID","rules":[]}]',
        sort: 1,
        createdAt: 0,
        updatedAt: 0,
      },
      team: {
        id: 16,
        createdById: 'kSrcq2XW',
        name: 'EVOS HUNTER TEAM',
        game: null,
        cup: null,
      },
      fullName: 'Angelo',
      userId: 'REOVRFNq',
      email: 'dummy01@gmail.com',
      phoneNumber: '0812325555',
      isEmailConfirmed: random < 5 ? true : false,
      isCaptain: false,
      surveyResponse: '',
      additionalData: '{"inGameName":"Angel","inGameID":"55555"}',
      createdAt: 1613027713,
      updatedAt: 1613027713,
    },
  ],
  paging: null,
};

export default {
  getListOpeningResponseDummy,
  getListRunningResponseDummy,
  getListPastResponseDummy,
  getDetailResponseDummy,
  getDetailResponseDummy2,
  getDetailClosedResponseDummy,
  registerErrorResponse1Dummy,
  registerErrorResponse2Dummy,
  registerSuccessResponseDummy,
  checkRegisterResponseDummy,
};
