import moment from 'moment';
import GeneralParticle from '@evos/general-particle';
import IBaseResponse from '@evos/general-particle/dist/interfaces/api/result';
import IStage from '@evos/general-particle/dist/interfaces/efc/stage';
import CUP from './cup';
import GAME from './game';

const { STAGE_TYPE } = GeneralParticle.Constants.EFC;

const GenerateSingleStage = (id?: number): IStage => {
  const usedId = id || 1;
  return {
    id: usedId,
    cup: CUP.getDetailResponseDummy.data,
    game: GAME.GenerateSingleGame(usedId),
    name: `Babak ${usedId}`,
    type: usedId % 2 === 1 ? STAGE_TYPE.bracket : STAGE_TYPE.table,
    startAt: moment().unix(),
    endAt: moment().unix(),
    hasPrize: true,
    level: usedId,
    createdAt: moment().unix(),
    updatedAt: moment().unix(),
  };
};

const GetList = (numberOfData: number): IBaseResponse<Array<IStage>> => {
  const data: Array<IStage> = [];
  for (let i = 0; i < numberOfData; i++) {
    const newData = GenerateSingleStage(i + 1);
    data.push(newData);
  }
  return {
    success: true,
    data,
    paging: {
      hasNext: false,
      currentPage: 1,
      totalData: numberOfData,
      totalPages: 1,
      limit: numberOfData,
    },
  };
};

const GetById = (stageId: number): IBaseResponse<IStage> => {
  return {
    success: true,
    data: GenerateSingleStage(stageId),
  };
};

export default { GenerateSingleStage, GetList, GetById };
