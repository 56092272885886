import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Typography, Divider, Button, Spin } from 'antd';
import Layout from '../../components/layout';
import Actions from '../../store/actions';
import { ReduxState } from '../../store/reducers';
import { ICupState } from '../../interfaces/states/cup';

interface IProps {
  cup: ICupState;
  getCupDetail: (slug: string) => Promise<any>;
}

const CupDetailPage = (props: IProps) => {
  const { getCupDetail, cup } = props;
  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();
  React.useEffect(() => {
    getCupDetail(slug);
  }, [slug, getCupDetail]);

  const renderSnK = () => (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <img
              src={cup.data?.value?.image}
              alt={cup.data?.value?.name}
              width="100%"
              height="auto"
              className="image-container"
            />
          </Col>
        </Row>
        <Row className="section-container">
          <Col span={24}>
            <Row className="mt-2">
              <Typography.Text className="title-2">{cup.data?.value?.name}</Typography.Text>
            </Row>
            <Divider />
            <Row className="snk-container">
              <div
                className="snk"
                dangerouslySetInnerHTML={{
                  __html: cup.data?.value?.descriptionOpen
                    ? cup.data?.value?.descriptionOpen + '<br /><br /><br />'
                    : '',
                }}
              />
            </Row>
            <Row className="mt-1">
              <Col span={24}>
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={() => {
                    history.push(`/${cup.data?.value?.slug}/join`);
                  }}
                >
                  Setuju
                </Button>
              </Col>
            </Row>
            <Row className="mb-2 mt-1">
              <Col span={24}>
                <Button block ghost type="primary" size="large" onClick={() => history.push('/')}>
                  Kembali
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <Layout
      header={{
        right: <></>,
      }}
    >
      {cup.data.requesting ? (
        <Row justify="center" align="middle" className="spinner-container">
          <Spin size="large" />
        </Row>
      ) : (
        renderSnK()
      )}
    </Layout>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  cup: state.cup,
});

const mapDispatchToProps = (dispatch: any) => ({
  getCupDetail: (slug: string) => dispatch(Actions.cup.GetDetail(slug, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CupDetailPage);
