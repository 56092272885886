import Base from './base';
import Dummy from '../../constants/dummy';

export default {
  GetList: async (
    cupId?: number,
    stageId?: number,
    groupId?: number,
    isDummy?: boolean,
  ): Promise<any> => {
    const query = `${stageId ? `&stageID=${stageId}` : ''}${groupId ? `&groupID=${groupId}` : ''}`;
    if (isDummy) {
      const dummyData = Dummy.teamLog.GetListBySection(3);
      return new Promise((resolve) => {
        var blob = new Blob([JSON.stringify(dummyData, null, 2)], { type: 'application/json' });
        resolve(new Response(blob));
      });
    }
    if (stageId && groupId) return Base().get(`/public/cups/${cupId}/team-logs?${query}`);
    return Base().get(`/public/cups/${cupId}/winners`);
  },
  GetLatest: async (cupId: number, isDummy?: boolean): Promise<any> => {
    if (isDummy) {
      const dummyData = Dummy.teamLog.GetLatest();
      return new Promise((resolve) => {
        var blob = new Blob([JSON.stringify(dummyData, null, 2)], { type: 'application/json' });
        resolve(new Response(blob));
      });
    }
    return Base().get(`/public/cups/${cupId}/team-logs/latest`);
  },
};
