import moment from 'moment';
import IGame from '@evos/general-particle/dist/interfaces/efc/game';

const GenerateSingleGame = (id?: number): IGame => ({
  id: id || 1,
  name: `Game ${id || 1}`,
  label: '',
  isActive: true,
  playersPerTeam: 5,
  sort: 0,
  createdAt: moment().unix(),
  updatedAt: moment().unix(),
});

export default { GenerateSingleGame };
