import ICup from '@evos/general-particle/dist/interfaces/efc/cup';
import IPaging from '@evos/general-particle/dist/interfaces/api/paging';
import BaseStateDefault from '../../constants/state';

export interface ICupBase<T> {
  value?: T;
  requesting: boolean;
  paging?: IPaging;
  errors?: string;
}

export interface ICupState {
  data: ICupBase<ICup>;
  list: { [key: string]: ICupBase<Array<ICup>> };
}

export const BaseCupStateDefault: ICupState = {
  data: BaseStateDefault,
  list: {},
};
