import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import HomePage from './pages/home';
import CupPage from './pages/cup';
import CupListPage from './pages/cup-list';
import CupDetailPage from './pages/cup-detail';
import StandingPage from './pages/standing';
import ConfirmInvitationPage from './pages/confirm-invitation';
import DashboardPage from './pages/dashboard';
import NotFoundPage from './pages/not-found';
import history from './utils/history';
import './app.less';
import PrivateRoute from './components/private-route';

const App: React.FC = () => (
  <Router history={history}>
    <Switch>
      <Route exact path="/">
        <HomePage />
      </Route>
      <Route path="/cups/:sectionSlug">
        <CupListPage />
      </Route>
      <PrivateRoute path="/profile">
        <DashboardPage />
      </PrivateRoute>
      <Route path="/not-found">
        <NotFoundPage />
      </Route>
      <Route path="/:cupSlug/standings/:cupId/:stageId?/:groupId?">
        <StandingPage />
      </Route>
      <Route path="/:cupSlug/accept-invitation/:playerId/:signature">
        <ConfirmInvitationPage />
      </Route>
      <Route path="/:slug/join">
        <CupPage />
      </Route>
      <Route path="/:slug">
        <CupDetailPage />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  </Router>
);

export default App;
