import moment from 'moment';
import ITeam from '@evos/general-particle/dist/interfaces/efc/team';

const names = [
  'Garuda Revolution YoungBoys',
  'cassanova esport',
  'YONIF BLACK DANGER',
  'ALLIENCY TEAM',
  'Dandelie.ID',
  'Infinity Glory',
  'GoU Fury',
  'WATES EMPIRE',
  'WakeUpBaby',
  'Q Rangers',
  'TSa Glory',
  'PZM ESPORT',
  'Level Sea Star',
  'DORAKA E-SPORTS',
  'VorpalGodZ Morrigan',
  'SANES ESPORT',
  'Squad Fake',
  'Quartz',
  'RAJA GLORY',
  'BMW GT',
  'CREON VEIN',
  'L2D NO MERCY',
  'Megan zoro',
  'Veloci Raptor',
  'BIGETRON PROXIMA',
  'CYLO REDCODE',
  'Midnight rebels A',
  'BOSS LIQUID',
  'AB (ANAK BAIK)',
  'Dragon Blood Alpha V2',
  'Zephyrus e-sports',
  'LONE ESPORT',
  'NOVA PROCLAZ',
  'TEAM P.LABU',
  'FGID Kaizen',
  'RAJA FAVOUR',
  'IMUT NI BOSZ',
  'VNDメKingdom',
  'Midnight rebels B',
  'ELVO ORION ALPHA WOLF',
  'The Fixers Esport',
  'EVLOGIA RAVENS',
  'The Mitoz Seiryu',
  'AEROPRIME XT',
  'OPTIMU5 HAVOC',
  'SA IVORY',
  'Stay Or Go',
  'ONE KING NITROGEN',
  'GARUDA REVOLUTION',
  'HUNTER ESPORTS',
  'Stay Alive Rosenborg',
  'SharkID Esport',
  'Xtronom',
  'Lion esport',
  'NOVC Esport',
  'INSURGENT ID',
  'ECLiPSE',
  'ACIDx',
  'ZowldCrown',
  'DANI GAK ADA LAWAN¹⁷',
  'CAHAYA PRIMA CLASSIX (C6)',
  'NEFO TEAM',
  'CHRYSOUR ALPHA',
  'NotNull Esport',
  'TEAM F2G',
  'FAMILY TANTAN',
  'A6 MUGEN',
  'Genesis Gank Road',
  '〘WWH〙',
  'Duta',
  'SWB DARK',
  'TAHU TEMPE',
  'EAGLE 4STONG',
  'Eighteen Team',
  'GeoTeam',
  'INI Squad',
  'LMS ACE',
  'PARASYTE GANK',
  'Hunter Commando',
  'NEZARD Maxime',
  'LMS Fearless',
  'R6T',
  'Vendetta 4 Sekawan',
  'CHRYSOUR GALAXY',
  'TEAM ARION',
  'TEAM KALENG KALENG',
  'Acultie',
  'WPJ',
  'GNS end',
  'KillerClown',
  'GEDANG SQUAD',
  'RATU REANNV',
  'VOIN SOCIETY',
  'ARMED WARRIORS',
  "Genesis gank theboy's",
  'LMS NO MERCY',
  'ROSE SAVIOR',
  'Trouble Maker TQ',
  'TimTam',
  'ROCKET A6',
  'Delano Team',
  'MysTiQ',
  'BONAFIDE VULCAN',
  'Foxxy Nocure',
  'Berkah E-Sport II',
  'CuxCux Squad',
  'PSW SQUAD',
  'ACC TEAM',
  'KNTL BEGINS',
  'G-WOLF',
  'DREAM SAVIOR',
  "Joker's E-sport",
  'Singa Mania',
  'ROSE ID',
  'SOULCRASH TEAM',
  'Dream Killer',
  'SundanesLawak',
  'NOT SQUAD SNAIL',
  'ONE MERCY GOOD',
  'BTR NextWar',
  'Elite Force 9 Legend',
  'Kentang Only',
  'NeoGXCODE',
  'PARANOIA',
  'Merciness Agility',
  '666 OP',
  'PAPER VIPERS',
  'Elite Four',
  'HORYZONE NEVERMORE',
  'TEAM SOLO NOB',
  "SFZ Team's",
  'FAZE EVOKAMI',
  'Poseidon',
  'YOITEAM ESPORT',
  'ANTERNAL',
  'REX SOVERS',
  'ALJAES CHAMPION',
  'ROAR TEAM',
  'FOXES TEAM',
  'NERVOUS TEAM',
  'DIVERSO 2K',
  'LENTERA KAIZEN',
  'IDOLxTeam',
  'TBT TENERGY',
  'CHRYSOUR UNIQUE',
  'LENTERA GOD FATHER',
  'NEZARD ANDROMEDA',
  'TEAMELVO MVP',
  'Frontier',
  'redbulls',
  'Nhyte Sath',
  'KANG SQUAD',
  'AWF treons,',
  'Cafedangan Adem Ayem',
  'NASTY RABBITS',
  'BTF eSport',
  'LENTERA OPTIC',
  'MIXKCOMEBACKxxxx',
  'FLOWERS BLOOM',
  'Golden Fire Epic',
  'HORYZONE NEXUS',
  'Team NOT',
  'KRYPTON',
  'DBlood Kaizen',
  'VICE VERSA',
  'OXYGEN REX',
  'Unequaled-Squad',
  'WARL ESPORT',
  'DIAMOND MINE MARS',
  'Team Madness',
  'REMA FRAME PARAH',
  'Call 911',
  'Crush GeneTik Joker',
  'LIONG ESPORT',
  'IP GLADIATOR',
  'CLEOSA ROSE',
  'DIVIBE GENiUZ',
  'GICH CONQUEROR',
  'WILD SALVATOR',
  'KRJP x ILLUMINATE',
  'FGID BLAZZ',
  'OUTERS DC',
  'TPK48 FOUR REX',
  'NEZARD UNITY',
  'Diverso C1',
  'KCN Esport',
  'NewCokodidi',
  'King East',
  'GIDDY IMPOSTOR',
  'Lightnizz Destroyer',
  'PK TEAM',
  'LoyalGlority',
  'Nexus',
  'SPARTAN TEAM',
  'TIK E-sport',
  'Genesis Gank Bravo',
  'SLIPPY BOYZ',
  'CNMantap',
  'FGID MINION',
  'SANGTORAYAN',
  'Victim GM',
  'TEXAS SYNDICATE',
  'Raidon RSV',
  'BERKAH E-SPORT',
  'ELVO ORION BAHAMUT',
  'INDOSKY RA',
  'Team Pulu Pulu',
  'PARTY GANKK',
  'OverNightMare Elite',
  'NERS 7HAVEN',
  '4DREAMS GeneXus',
  'COLEEB ESPORT',
  'Cosmic Lupinero',
  'TEAM SKYS BLACK ALIENS',
  'RIOT BOYS',
  'TORAJA GAMING',
  'CACTUS NELO HERO',
  'CACTUS NEVER LOSE',
  'XxOD MiDAS',
  'RaiRaiTosoon',
  'ANSCx4STRONG',
  'Kang Villa',
  'NL BEONE',
  'NH RUDE BOYS',
  'PACARHALU ESPORT',
  "POLYTRON'88",
  'THUNDERS SUMEDANG',
  'XGLORIOUS Course',
  'BotLegend ACE',
  'Bigetron Allez',
  'KAGENORM E-SPORT',
  'KAMU KNTL',
  'ALX FAMILY',
  'Fabulouz',
  '5aS Team',
  'TPK48 TITAN',
  'NOTE ELITE',
  'Sinners',
  'NOTE EVO',
  'NOL DERAJAT 666',
  'NOCTURNAL HERO',
  'CASPER VENOM',
  'UNCLE X REDFLAG',
  'nightmare',
  'NOCTURNAL SOKIB',
  'BLP CULCA',
  'S1GxOP',
  'Pacaruik',
  'TERPAS FIGHTER',
  'INSOMNIA XCIRCLE',
  'Oleng Team',
  '4AceTeam',
  'WSBH',
  'TAPOPS',
  'BONAFIDE VOLTNINE',
  'INSANITY',
  'OverWorld',
  'SAOLOAN Team',
  'Co•team',
  'Fox',
];

const GenerateSingleTeam = (id?: number): ITeam => ({
  id: id || 1,
  name: names[id || 1] || `Team ${id || 1}`,
  isEligible: true,
  createdAt: moment().unix(),
  updatedAt: moment().unix(),
});

const GetExistingTeamList = () => {
  return {
    success: true,
    data: [
      {
        id: 1,
        name: 'EVOS HUNTER TEAM',
        game: {
          id: 1,
          name: 'PUBGM Duo',
          isActive: true,
          playersPerTeam: 2,
          backupPlayersPerTeam: 1,
          additionalFields:
            '[{"name":"inGameName","type":"text","label":"Nickname","rules":[{"max":15,"message":"Panjang maksimal 15 karakter"}]},{"name":"inGameID","type":"text","label":"Player ID","rules":[]}]',
          sort: 1,
          createdAt: 0,
          updatedAt: 0,
        },
        cup: {
          id: 2,
          game: null,
          name: 'PUBGM Lively Duo',
          subtitle: '',
          image: 'https://i.ytimg.com/vi/PzIgvEy5Pxg/maxresdefault.jpg',
          slug: 'deadly-duo',
          bracketUrl: '',
          descriptionOpen: 'opening',
          descriptionClose: 'closing',
          pointReward: 250,
          startAt: 0,
          endAt: 0,
          surveyQuestion: 'question?',
          isDeleted: false,
          createdAt: 0,
          updatedAt: 0,
        },
        createdById: 'user1',
        players: [
          {
            id: 23,
            userId: 'user1',
            team: null,
            game: null,
            cup: null,
            phoneNumber: '08912341234',
            email: 'dummy1@gmail.com',
            isCaptain: true,
            isEmailConfirmed: true,
            surveyResponse: 'response',
            additionalData: '{"inGameName":"DrDisrespect","inGameID":"12345"}',
            createdAt: 0,
            updatedAt: 0,
          },
          {
            id: 24,
            userId: 'user2',
            team: null,
            game: null,
            cup: null,
            phoneNumber: '08922224444',
            email: 'dummy2@gmail.com',
            isCaptain: false,
            isEmailConfirmed: false,
            surveyResponse: 'response',
            additionalData: '{"inGameName":"Angel","inGameID":"44444"}',
            createdAt: 0,
            updatedAt: 0,
          },
        ],
        isEligible: false,
        createdAt: 0,
        updatedAt: 0,
      },
      {
        id: 2,
        name: 'EVOS TOP GLOBAL',
        game: {
          id: 1,
          name: 'PUBGM Duo',
          isActive: true,
          playersPerTeam: 2,
          backupPlayersPerTeam: 1,
          additionalFields:
            '[{"name":"inGameName","type":"text","label":"Nickname","rules":[{"max":15,"message":"Panjang maksimal 15 karakter"}]},{"name":"inGameID","type":"text","label":"Player ID","rules":[]}]',
          sort: 1,
          createdAt: 0,
          updatedAt: 0,
        },
        cup: {
          id: 2,
          game: null,
          name: 'PUBGM Lively Duo',
          subtitle: '',
          image: 'https://i.ytimg.com/vi/PzIgvEy5Pxg/maxresdefault.jpg',
          slug: 'deadly-duo',
          bracketUrl: '',
          descriptionOpen: 'opening',
          descriptionClose: 'closing',
          pointReward: 250,
          startAt: 0,
          endAt: 0,
          surveyQuestion: 'question?',
          isDeleted: false,
          createdAt: 0,
          updatedAt: 0,
        },
        createdById: 'user3',
        players: [
          {
            id: 25,
            userId: 'user3',
            team: null,
            game: null,
            cup: null,
            phoneNumber: '08912341234',
            email: 'dummy3gmail.com',
            isCaptain: true,
            isEmailConfirmed: true,
            surveyResponse: 'response',
            additionalData: '{"inGameName":"Michael","inGameID":"22222"}',
            createdAt: 0,
            updatedAt: 0,
          },
          {
            id: 26,
            userId: 'user4',
            team: null,
            game: null,
            cup: null,
            phoneNumber: '08922224444',
            email: 'dummy4@gmail.com',
            isCaptain: false,
            isEmailConfirmed: false,
            surveyResponse: 'response',
            additionalData: '{"inGameName":"Angelo","inGameID":"33333"}',
            createdAt: 0,
            updatedAt: 0,
          },
        ],
        isEligible: false,
        createdAt: 0,
        updatedAt: 0,
      },
    ],
    paging: {
      hasNext: false,
      currentPage: 1,
      totalData: 1,
      totalPages: 1,
      limit: 10,
    },
  };
};

export default { GenerateSingleTeam, GetExistingTeamList };
