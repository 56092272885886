import cup from './cup';
import dashboard from './dashboard';
import group from './group';
import player from './player';
import stage from './stage';
import teamLog from './team-log';
import team from './team';

export default {
  cup,
  dashboard,
  group,
  player,
  stage,
  teamLog,
  team,
};
