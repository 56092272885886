import IPlayer from '@evos/general-particle/dist/interfaces/efc/player';

const DisplayPlayerData = (player?: IPlayer, isNickname?: boolean) => {
  if (!player) return '-';
  const additionalDatas: { [key: string]: string } = player.additionalData
    ? JSON.parse(player.additionalData)
    : [];
  const commonKeys = ['ingameid', 'ingamename'];
  let playerMeta = Object.keys(additionalDatas).filter((key) => {
    const index = [...commonKeys, ...(isNickname ? [] : ['isadditionalplayer'])].indexOf(
      key.toLowerCase(),
    );
    return isNickname ? index >= 0 : index < 0;
  });
  return playerMeta.length > 0
    ? playerMeta.map((key) => additionalDatas[key]).join(' - ')
    : player.invitedEmail || player.fullName;
};

export { DisplayPlayerData };
