import moment from 'moment';
import GeneralParticle from '@evos/general-particle';
import IBaseResponse from '@evos/general-particle/dist/interfaces/api/result';
import { ITeamLog, IStage, IGroup } from '@evos/general-particle/dist/interfaces/efc';
import CUP from './cup';
import TEAM from './team';

const { REWARD_STATUS, TEAM_LOG_STATUS } = GeneralParticle.Constants.EFC;

const GenerateSingleTeamLog = (id?: number) => ({
  id: id || 1,
  team: TEAM.GenerateSingleTeam(id || 1),
  cup: CUP.getDetailResponseDummy.data,
  stage: {
    id: id || 1,
    name: `Babak ${id || 1}`,
    level: id || 1,
  } as IStage,
  group: {
    id: id || 1,
    name: `Grup ${id || 1}`,
  } as IGroup,
  round: 1,
  score: Math.floor(Math.random() * Math.floor(100)),
  status: TEAM_LOG_STATUS.qualified,
  teamReward: {
    rewardTitle: 'Reward Title',
    rewardAmount: Math.floor(Math.random() * Math.floor(10000000)),
    rewardDescription: '',
    rewardStatus: REWARD_STATUS.pending,
  },
  createdAt: moment().unix(),
  updatedAt: moment().unix(),
});

const GetListBySection = (numberOfData: number): IBaseResponse<Array<ITeamLog>> => {
  const data: Array<ITeamLog> = [];
  for (let i = 0; i < numberOfData; i++) {
    const newData = GenerateSingleTeamLog(i + 1);
    data.push(newData);
  }
  return {
    success: true,
    data,
    paging: {
      hasNext: false,
      currentPage: 1,
      totalData: numberOfData,
      totalPages: 1,
      limit: numberOfData,
    },
  };
};

const GetLatest = (): IBaseResponse<ITeamLog> => {
  return {
    success: true,
    data: GenerateSingleTeamLog(),
  };
};

export default {
  GetListBySection,
  GetLatest,
  GenerateSingleTeamLog,
};
