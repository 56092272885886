import IBaseResponse from '@evos/general-particle/dist/interfaces/api/result';
import Base from './base';
import Dummy from '../../constants/dummy';
import { IGroup, IStage } from '@evos/general-particle/dist/interfaces/efc';

export default {
  GetList: async (
    stageId: number,
    limit?: number,
    page?: number,
    isDummy?: boolean,
  ): Promise<any> => {
    const query = `${limit ? `limit=${limit}` : ''}${page ? `&page=${page}` : ''}`;
    if (isDummy) {
      const dummyData: IBaseResponse<Array<IGroup>> = Dummy.group.GetList(limit || 3);
      dummyData.data = dummyData.data?.map((d) => ({
        ...d,
        stage: {
          ...d.stage,
          id: stageId,
          name: `Stage ${stageId}`,
          slug: `stage-${stageId}`,
        } as IStage,
      }));
      return new Promise((resolve) => {
        var blob = new Blob([JSON.stringify(dummyData, null, 2)], { type: 'application/json' });
        resolve(new Response(blob));
      });
    }
    return Base().get(`/public/stages/${stageId}/groups?${query}`);
  },
  GetById: async (groupId: number, isDummy?: boolean): Promise<any> => {
    if (isDummy) {
      const dummyData = Dummy.group.GetById(groupId);
      return new Promise((resolve) => {
        var blob = new Blob([JSON.stringify(dummyData, null, 2)], { type: 'application/json' });
        resolve(new Response(blob));
      });
    }
    return Base().get(`/public/groups/${groupId}`);
  },
};
