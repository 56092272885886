import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import IBaseResponse from '@evos/general-particle/dist/interfaces/api/result';
import ITeamLog from '@evos/general-particle/dist/interfaces/efc/team-log';
import Api from '../apis';
import Base from './base';
import ActionTypes from '../../constants/action-types';

const GetList = (
  cupId?: number,
  stageId?: number,
  groupId?: number,
  isDummy?: boolean,
  callback?: Function,
) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(
    dispatch,
    Api.teamLog.GetList(cupId, stageId, groupId, isDummy),
    () => {
      return action(ActionTypes.teamLog.GET_TEAM_LOG_LIST_REQUEST);
    },
    (response: IBaseResponse<Array<ITeamLog>>) => {
      return action(ActionTypes.teamLog.GET_TEAM_LOG_LIST_SUCCESS, {
        list: response.data,
        paging: response.paging,
      });
    },
    (error: string) => {
      return action(ActionTypes.teamLog.GET_TEAM_LOG_LIST_FAILED, {
        error,
      });
    },
    callback,
  );
};

const GetLatest = (cupId: number, isDummy?: boolean, callback?: Function) => (
  dispatch: Dispatch<AnyAction>,
) => {
  Base.CallAction(
    dispatch,
    Api.teamLog.GetLatest(cupId, isDummy),
    () => {
      return action(ActionTypes.teamLog.GET_LATEST_TEAM_LOG_REQUEST);
    },
    (response: IBaseResponse<ITeamLog>) => {
      return action(ActionTypes.teamLog.GET_LATEST_TEAM_LOG_SUCCESS, {
        data: response.data,
      });
    },
    (error: string) => {
      return action(ActionTypes.teamLog.GET_LATEST_TEAM_LOG_FAILED, {
        error,
      });
    },
    callback,
  );
};

export default {
  GetList,
  GetLatest,
};
