import React from 'react';
import { Layout, Row, Col, Button, Drawer, Typography, Divider } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import AccountParticle from '@evos/account-particle';
import GeneralParticle from '@evos/general-particle';
import ENV from '../../utils/environment';
import * as pkg from '../../../package.json';

const { Content } = Layout;
const { GetToken } = AccountParticle.API.User;
const { GetList } = AccountParticle.API.Network;
const { NetworkBar } = AccountParticle.Component;
const { Encrypt } = GeneralParticle.Utils.Crypt;

interface IProps {
  children: React.ReactNode;
  header?: {
    left?: React.ReactNode;
    middle?: React.ReactNode;
    right?: React.ReactNode;
  };
  bottomNav?: boolean;
}

interface IState {
  drawerVisible: boolean;
}

class LayoutComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      drawerVisible: false,
    };
  }

  toggleDrawer = () => {
    this.setState(
      (state) => ({
        drawerVisible: !state.drawerVisible,
      }),
      () => {
        const body = document.getElementsByTagName('body')[0];
        body.style.overflowY = this.state.drawerVisible ? 'hidden' : 'auto';
      },
    );
  };

  render() {
    const isLoggedin = !!GetToken(ENV.PARTICLE_ENV);
    const networks = GetList(ENV.PARTICLE_ENV);
    const { children, header, bottomNav } = this.props;
    return (
      <Layout>
        <Content className="layout-wrapper" style={{ paddingBottom: bottomNav ? 60 : 0 }}>
          <Row className="layout-header" align="middle">
            <Col span={8}>
              {header?.left ? (
                header.left
              ) : (
                <Button
                  ghost
                  icon={<MenuOutlined />}
                  size="small"
                  onClick={this.toggleDrawer}
                  className="menu-button"
                >
                  MENU
                </Button>
              )}
            </Col>
            <Col span={8}>
              {header?.middle ? (
                header.middle
              ) : (
                <Row justify="center">
                  <img src="/images/evos-logo.png" alt="evos logo" width={48} height={48} />
                </Row>
              )}
            </Col>
            <Col span={8}>
              <Row justify="end">
                {header?.right ? (
                  header.right
                ) : isLoggedin ? (
                  <Button ghost size="small" href="/profile">
                    DASHBOARD
                  </Button>
                ) : (
                  <Button
                    ghost
                    size="small"
                    href={`${ENV.MY_URL}/login?referrer=${Encrypt('', window.location.href)}`}
                  >
                    LOGIN
                  </Button>
                )}
              </Row>
            </Col>
          </Row>
          {bottomNav && (
            <Row className="layout-nav">
              <Col span={24}>
                <NetworkBar
                  env={ENV.PARTICLE_ENV}
                  language="id"
                  activeNetwork={window.location.pathname.includes('/profile') ? 'PROFILE' : 'EFC'}
                  profileUrl="/profile"
                />
              </Col>
            </Row>
          )}
          <Drawer
            placement="left"
            closable={false}
            onClose={this.toggleDrawer}
            visible={this.state.drawerVisible}
            getContainer={false}
            footer={
              <>
                <Row>
                  <Typography.Text className="text-size-16 text-height-24">
                    EVOS Network
                  </Typography.Text>
                </Row>
                <Row className="mt--5">
                  {networks.map((i, idx) => {
                    return (
                      <Typography.Link
                        key={i.link}
                        href={i.link}
                        className="text-size-12 text-height-18 text-color-dark-one"
                      >
                        {`${idx > 0 ? ' • ' : ''}${i.name}`}
                      </Typography.Link>
                    );
                  })}
                </Row>
                <Divider />
                <Row>
                  <Typography.Text className="text-size-12 text-height-18">
                    2020 © EVOS Esports. Hak Cipta dilindungi oleh undang-undang.
                  </Typography.Text>
                </Row>
                <Row className="mt-2">
                  <Typography.Text className="text-size-10 text-color-dark-three">
                    {`v.${pkg.version} - ${window?.navigator?.userAgent}`}
                  </Typography.Text>
                </Row>
              </>
            }
          >
            <Row>
              <img src="/images/evos-logo.png" alt="evos logo" width={56} height={56} />
            </Row>
            <Row className="mv-2">
              <a href="/">
                <Typography.Text strong className="text-size-24 text-height-28">
                  Beranda
                </Typography.Text>
              </a>
            </Row>
            <Row className="mv-2">
              <a
                href={
                  isLoggedin
                    ? '/profile'
                    : `${ENV.MY_URL}/login?referrer=${Encrypt('', window.location.href)}`
                }
              >
                <Typography.Text strong className="text-size-24 text-height-28">
                  {isLoggedin ? 'Profil' : 'Login'}
                </Typography.Text>
              </a>
            </Row>
            <Divider />
          </Drawer>
          {children}
        </Content>
      </Layout>
    );
  }
}

export default LayoutComponent;
