import Base from './base';
import Dummy from '../../constants/dummy';

export default {
  GetList: async (
    gameID: number,
    limit?: number,
    page?: number,
    isDummy?: boolean,
  ): Promise<any> => {
    const query = `${limit ? `limit=${limit}` : ''}${page ? `&page=${page}` : ''}`;
    if (isDummy) {
      const dummyData = Dummy.team.GetExistingTeamList();
      return new Promise((resolve) => {
        var blob = new Blob([JSON.stringify(dummyData, null, 2)], { type: 'application/json' });
        resolve(new Response(blob));
      });
    }
    return Base().get(`/private/games/${gameID}/teams?${query}`);
  },
};
