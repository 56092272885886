import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, List, Button, Tag } from 'antd';
import moment from 'moment';
import ICup from '@evos/general-particle/dist/interfaces/efc/cup';
import { CUP_SECTION } from '../../constants/cup-section';
import { ICupBase } from '../../interfaces/states/cup';

interface IProps {
  state: ICupBase<Array<ICup>>;
  cupSection: CUP_SECTION;
  loadMore: () => void;

  header?: string;
}

const CupListComponent = (props: IProps) => {
  const { state, cupSection, header, loadMore } = props;
  const history = useHistory();
  const now = new Date();

  const onSelectCup = (cup: ICup) => {
    history.push(
      `/${cup.slug}${moment().isAfter(moment.unix(cup.startAt)) ? `/standings/${cup.id}` : ''}`,
    );
  };

  const renderEmpty = (section: CUP_SECTION) => (
    <Row justify="center" className="summary p-1">
      <Col span={24}>
        <Row justify="center">
          <Typography.Text className="text-size-14 text-height-21">
            {section === CUP_SECTION.upcoming
              ? 'Saat ini tidak ada kompetisi yang akan berlangsung.'
              : ''}
            {section === CUP_SECTION.opening
              ? 'Saat ini tidak ada kompetisi yang sedang dalam masa registrasi.'
              : ''}
            {section === CUP_SECTION.running
              ? 'Saat ini tidak ada kompetisi yang sedang berlangsung.'
              : ''}
            {section === CUP_SECTION.past
              ? 'Bagian ini akan menampilkan kompetisi yang sudah selesai.'
              : ''}
          </Typography.Text>
        </Row>
      </Col>
    </Row>
  );

  return (
    <List
      grid={{
        column: 1,
      }}
      dataSource={state?.value}
      renderItem={(item: ICup) => {
        return (
          <List.Item>
            <Row
              className={`item-container p-1 ${
                cupSection === CUP_SECTION.past &&
                moment.duration(moment(now).diff(moment.unix(item.endAt))).asHours() > 24
                  ? 'past'
                  : ''
              }`}
              onClick={() => onSelectCup(item)}
              wrap={false}
            >
              <Col flex="90px">
                <Row justify="start">
                  <img src={item.image} alt={item.name} width={90} height={90} />
                </Row>
              </Col>
              <Col flex="auto">
                <Row justify="start" className="pl-1">
                  <Col span={24}>
                    <Row>
                      <Typography.Text className="text-size-14 text-height-21 text-weight-bold">
                        {item.name}
                      </Typography.Text>
                    </Row>
                    {cupSection === CUP_SECTION.running &&
                    moment().isBefore(moment.unix(item.startAt)) ? (
                      <Row className="mv--5">
                        <Tag>Dalam proses seleksi tim</Tag>
                      </Row>
                    ) : undefined}
                    <Row justify="start" className="mt--5">
                      <div
                        className="text-size-12 text-height-18 text-weight-medium"
                        dangerouslySetInnerHTML={{ __html: item.subtitle }}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </List.Item>
        );
      }}
      header={
        header ? (
          <Row justify="start" className="mv--5">
            <Typography.Text className="text-size-18 text-height-21">{header}</Typography.Text>
          </Row>
        ) : undefined
      }
      loading={state?.requesting}
      footer={
        state?.paging?.hasNext ? (
          <Button ghost type="primary" size="large" block onClick={loadMore}>
            Muat lebih banyak
          </Button>
        ) : undefined
      }
      locale={{ emptyText: renderEmpty(cupSection) }}
    />
  );
};
export default CupListComponent;
