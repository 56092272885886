import React from 'react';
import { Form, Row, Col, Typography, Input, DatePicker, Select } from 'antd';
import { Store } from 'antd/lib/form/interface';
import GeneralParticle from '@evos/general-particle';
import { IField } from '../../interfaces/models/form';

const { Option } = Select;
const { Email, PhoneNumber } = GeneralParticle.Utils.Validation;

interface Props {
  onFinish: (values: Store) => void;
  onChange?: (e: any) => void;
  fields: Array<IField>;
  initialValues: { [x: string]: any };
  children: React.ReactNode;
}

const ComponentForm = (props: Props) => {
  const { onFinish, onChange, fields, children, initialValues } = props;
  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);
  return (
    <Form layout="vertical" size="large" onFinish={onFinish} onChange={onChange} form={form}>
      {fields.map((f) => {
        let inputComponent = null;
        switch (f.type) {
          case 'select':
            inputComponent = (
              <Select allowClear placeholder={f.placeholder || f.label}>
                {f.options?.map((o) => (
                  <Option key={o.value} value={o.value}>
                    {o.label}
                  </Option>
                ))}
              </Select>
            );
            break;
          case 'date':
            inputComponent = <DatePicker placeholder={f.placeholder || f.label} />;
            break;
          case 'email':
            inputComponent = (
              <Input
                type={f.type}
                disabled={f.disabled}
                placeholder={f.placeholder || 'example@gmail.com'}
              />
            );
            break;
          case 'number':
          case 'tel':
          case 'password':
            inputComponent = (
              <Input type={f.type} disabled={f.disabled} placeholder={f.placeholder || f.label} />
            );
            break;
          case 'textarea':
            inputComponent = (
              <Input.TextArea
                rows={f.rows || 4}
                disabled={f.disabled}
                placeholder={f.placeholder || f.label}
              />
            );
            break;
          case 'gap':
            inputComponent = <Row className="mv-1" />;
            break;
          default:
            inputComponent = <Input disabled={f.disabled} placeholder={f.placeholder || f.label} />;
            break;
        }

        return (
          <Form.Item
            key={f.name}
            label={
              <Row>
                <Col>
                  <Row>
                    <Typography.Text className="label-text">{f.label}</Typography.Text>
                  </Row>
                </Col>
              </Row>
            }
            name={f.name}
            rules={[
              ...(f.rules || []),
              ...(f.required ? [{ required: true, message: `${f.label} tidak boleh kosong` }] : []),
              ...(f.type === 'email'
                ? [
                    {
                      validator: async (_: any, value: string) => {
                        if (value && !Email(value)) {
                          return Promise.reject(new Error('Email tidak valid'));
                        }
                      },
                      validateTrigger: 'onSubmit',
                    },
                  ]
                : []),
              ...(f.type === 'tel'
                ? [
                    {
                      validator: async (_: any, value: string) => {
                        if (!value || !PhoneNumber(value)) {
                          return Promise.reject(new Error('Nomor telepon tidak valid'));
                        }
                      },
                      validateTrigger: 'onSubmit',
                    },
                  ]
                : []),
            ]}
            help={f.helpText}
          >
            {inputComponent}
          </Form.Item>
        );
      })}
      {children}
    </Form>
  );
};

export default ComponentForm;
