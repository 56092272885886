import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import IBaseResponse from '@evos/general-particle/dist/interfaces/api/result';
import IGroup from '@evos/general-particle/dist/interfaces/efc/group';
import Api from '../apis';
import Base from './base';
import ActionTypes from '../../constants/action-types';

const GetList = (
  stageId: number,
  limit?: number,
  page?: number,
  isDummy?: boolean,
  callback?: Function,
) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(
    dispatch,
    Api.group.GetList(stageId, limit, page, isDummy),
    () => {
      return action(ActionTypes.group.GET_GROUP_LIST_REQUEST);
    },
    (response: IBaseResponse<Array<IGroup>>) => {
      return action(ActionTypes.group.GET_GROUP_LIST_SUCCESS, {
        list: response.data,
        paging: response.paging,
      });
    },
    (error: string) => {
      return action(ActionTypes.group.GET_GROUP_LIST_FAILED, {
        error,
      });
    },
    callback,
  );
};

const GetById = (groupId: number, isDummy?: boolean, callback?: Function) => (
  dispatch: Dispatch<AnyAction>,
) => {
  Base.CallAction(
    dispatch,
    Api.group.GetById(groupId, isDummy),
    () => {
      return action(ActionTypes.group.GET_GROUP_BY_ID_REQUEST);
    },
    (response: IBaseResponse<IGroup>) => {
      return action(ActionTypes.group.GET_GROUP_BY_ID_SUCCESS, {
        data: response.data,
      });
    },
    (error: string) => {
      return action(ActionTypes.group.GET_GROUP_BY_ID_FAILED, {
        error,
      });
    },
    callback,
  );
};

export default {
  GetList,
  GetById,
};
