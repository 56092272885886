import IBaseResponse from '@evos/general-particle/dist/interfaces/api/result';
import Base from './base';
import Dummy from '../../constants/dummy';
import { IStage, ICup } from '@evos/general-particle/dist/interfaces/efc';

export default {
  GetList: async (
    cupId: number,
    limit?: number,
    page?: number,
    isDummy?: boolean,
  ): Promise<any> => {
    const query = `${limit ? `limit=${limit}` : ''}${page ? `&page=${page}` : ''}`;
    if (isDummy) {
      const dummyData: IBaseResponse<Array<IStage>> = Dummy.stage.GetList(limit || 3);
      dummyData.data = dummyData.data?.map((d) => ({
        ...d,
        cup: {
          ...d.cup,
          id: cupId,
          name: `Cup ${cupId}`,
          slug: `cup-${cupId}`,
        } as ICup,
      }));
      return new Promise((resolve) => {
        var blob = new Blob([JSON.stringify(dummyData, null, 2)], { type: 'application/json' });
        resolve(new Response(blob));
      });
    }
    return Base().get(`/public/cups/${cupId}/stages?${query}`);
  },
  GetById: async (stageId: number, isDummy?: boolean): Promise<any> => {
    if (isDummy) {
      const dummyData = Dummy.stage.GetById(stageId);
      return new Promise((resolve) => {
        var blob = new Blob([JSON.stringify(dummyData, null, 2)], { type: 'application/json' });
        resolve(new Response(blob));
      });
    }
    return Base().get(`/public/stages/${stageId}`);
  },
};
