/* eslint-disable react/display-name */
import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Col,
  Divider,
  Empty,
  Image,
  List,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import moment from 'moment';
import { Bracket, RoundProps, Seed, SingleLineSeed, SeedItem, SeedTeam } from 'react-brackets';
import AccountParticle from '@evos/account-particle';
import GeneralParticle from '@evos/general-particle';
import {
  IGroup,
  IStage,
  ITeamLog,
  IPlayer,
  ICup,
  ITeam,
} from '@evos/general-particle/dist/interfaces/efc';
import Layout from '../../components/layout';
import { IGroupState } from '../../interfaces/states/group';
import { IStageState } from '../../interfaces/states/stage';
import Actions from '../../store/actions';
import { ReduxState } from '../../store/reducers';
import { ITeamLogState } from '../../interfaces/states/team-log';
import ENV from '../../utils/environment';
import { IPlayerState } from '../../interfaces/states/player';
import { ICupState } from '../../interfaces/states/cup';
import { DisplayPlayerData } from '../../utils/common';

const { Option } = Select;
const { GetToken } = AccountParticle.API.User;
const { Numberize } = GeneralParticle.Utils.Format;
const { STAGE_TYPE } = GeneralParticle.Constants.EFC;
const { Encrypt } = GeneralParticle.Utils.Crypt;

interface IProps {
  group: IGroupState;
  getGroupList: (stageId: number) => Promise<any>;

  stage: IStageState;
  getStageList: (cupId: number) => Promise<any>;

  teamLog: ITeamLogState;
  getLatestTeamLog: (cupId: number) => Promise<any>;
  getTeamLogList: (cupId: number, stageId?: number, groupId?: number) => Promise<any>;

  player: IPlayerState;
  getPlayerList: (teamId: number) => Promise<any>;

  cup: ICupState;
  getCupById: (cupId: number) => Promise<any>;
}

enum STANDING_MODAL_TYPE {
  undefined,
  player,
  links,
}

const winnerStage: IStage = {
  hasPrize: true,
  name: 'Pemenang',
  type: STAGE_TYPE.table,
  id: 0,
} as IStage;

const StandingPage = (props: IProps) => {
  const isLoggedin = !!GetToken(ENV.PARTICLE_ENV);
  const history = useHistory();
  const {
    group,
    getGroupList,
    stage,
    getStageList,
    teamLog,
    getLatestTeamLog,
    getTeamLogList,
    player,
    getPlayerList,
    cup,
    getCupById,
  } = props;
  const { cupSlug, cupId, stageId, groupId } = useParams<{
    cupSlug: string;
    cupId: string;
    stageId?: string;
    groupId?: string;
  }>();
  const [selectedStage, setSelectedStage] = React.useState<IStage>();
  const [selectedGroup, setSelectedGroup] = React.useState<IGroup>();
  const [selectedTeam, setSelectedTeam] = React.useState<ITeam>();
  const [sortedTeamLogs, setSortedTeamLogs] = React.useState<Array<ITeamLog>>();
  const [teamLogs, setTeamLogs] = React.useState<Array<ITeamLog>>();
  const [rounds, setRounds] = React.useState<Array<RoundProps>>([]);
  const [totalRound, setTotalRound] = React.useState(0);
  const [modalType, setModalType] = React.useState<STANDING_MODAL_TYPE>(
    STANDING_MODAL_TYPE.undefined,
  );
  const [positionColor, setPositionColor] = React.useState<string>('white');

  React.useEffect(() => {
    getStageList(parseInt(cupId));
    if (isLoggedin) getLatestTeamLog(parseInt(cupId, 10));
  }, [getLatestTeamLog, getStageList, cupId, isLoggedin]);

  React.useEffect(() => {
    let newSelectedStage: IStage | undefined;
    if (stageId) {
      if (stageId === winnerStage.id.toString()) {
        newSelectedStage = {
          ...(stage.list && stage.list.length > 0 ? stage.list[0] : {}),
          ...winnerStage,
        };
      } else newSelectedStage = stage.list?.find((s) => s.id.toString() === stageId);
    }
    if (!newSelectedStage && stage.list && stage.list[0]) {
      newSelectedStage = stage.list[0];
    }

    setSelectedStage(newSelectedStage);
  }, [stage.list, stageId]);

  React.useEffect(() => {
    if (selectedStage) {
      getGroupList(selectedStage.id);
    }
  }, [getGroupList, selectedStage]);

  React.useEffect(() => {
    let newSelectedGroup: IGroup | undefined;
    if (groupId) {
      newSelectedGroup = group.list?.find((g) => g.id.toString() === groupId);
    }
    if (!newSelectedGroup && group.list && group.list[0]) {
      newSelectedGroup = group.list[0];
    }

    setSelectedGroup(newSelectedGroup);
  }, [group.list, groupId]);

  React.useEffect(() => {
    getTeamLogList(parseInt(cupId, 10), selectedStage?.id, selectedGroup?.id);

    if (!selectedStage && !selectedGroup) {
      getCupById(parseInt(cupId, 10));
    }
  }, [selectedGroup, cupId, history, selectedStage, getCupById, getTeamLogList]);

  React.useEffect(() => {
    if (selectedGroup && selectedStage) {
      history.push(`/${cupSlug}/standings/${cupId}/${selectedStage?.id}/${selectedGroup.id}`);
    }
  }, [selectedGroup, cupId, history, selectedStage, cupSlug]);

  const GetRoundName = (round: number, totalRound: number): string => {
    if (round === totalRound) return 'Final';
    if (round === totalRound - 1) return 'Semi Final';
    if (round === totalRound - 2) return 'Perempat Final';
    return `Ronde ${round}`;
  };

  const getTotalRound = (teamLogs: Array<ITeamLog>) => {
    const teamRoundOne = teamLogs?.filter((x) => x.round === 1);
    const len = teamRoundOne.length || 0;
    const totalRound = Math.ceil(Math.log2(len));
    return { totalRound, len };
  };

  const CreateByeTeam = (length: number, tempTeamLog: Array<ITeamLog>) => {
    const temp: Array<ITeamLog> = [...tempTeamLog];
    const len2 = temp.filter((x) => x.round !== 1).length || 0;
    for (let index = temp.length - (length + len2); index <= temp.length - len2; index++) {
      if (index % 2 !== 0) temp.splice(index, 0, {} as ITeamLog);
    }
    return temp;
  };

  React.useEffect(() => {
    const temp = getTotalRound(teamLog.list || []);
    setTotalRound(temp.totalRound);
    if (teamLog.list && teamLog.list.length > 0) {
      const maxTeamInRound = Math.pow(2, totalRound);
      const newTeamLog: Array<ITeamLog> = CreateByeTeam(maxTeamInRound - temp.len, teamLog.list);
      setTeamLogs([...newTeamLog]);
    }
  }, [teamLog.list, totalRound]);

  React.useEffect(() => {
    if (teamLogs) {
      const sorted = Object.assign(
        teamLogs?.filter((x) => x.round === (selectedStage?.type === STAGE_TYPE.bracket ? 1 : 0)),
        [],
      ).sort((a, b) => (a.team?.name && b.team?.name && a.team?.name > b.team?.name ? 1 : -1));
      setSortedTeamLogs(sorted);

      let newRounds: Array<RoundProps> = [];

      for (let i = 0; i < totalRound; i++) {
        const prevRound =
          i > 0 ? newRounds.find((x) => x.title === GetRoundName(i, totalRound)) : undefined;
        const prevTeamLogs = prevRound?.seeds.reduce(
          (acc, curr) => [...acc, curr.teamLogs[0], curr.teamLogs[1]],
          [],
        );
        const seeds: Array<{ teamLogs: Array<ITeamLog> }> = [];

        while (seeds.length < Math.pow(2, totalRound - (i + 1))) {
          const index = seeds.length * (prevTeamLogs ? 4 : 2);
          const firstTeamExistsOnCurrentRound = teamLogs?.find(
            (x, idx) =>
              x.round === i + 1 &&
              (prevTeamLogs &&
              prevTeamLogs.length > 0 &&
              (prevTeamLogs[index]?.id || prevTeamLogs[index + 1]?.id)
                ? [prevTeamLogs[index].team?.id, prevTeamLogs[index + 1].team?.id].includes(
                    x.team?.id,
                  )
                : idx === index),
          );
          const secondTeamExistsOnCurrentRound = teamLogs?.find(
            (x, idx) =>
              x.round === i + 1 &&
              (prevTeamLogs &&
              prevTeamLogs.length > 0 &&
              (prevTeamLogs[index + 2]?.id || prevTeamLogs[index + 3]?.id)
                ? [prevTeamLogs[index + 2].team?.id, prevTeamLogs[index + 3].team?.id].includes(
                    x.team?.id,
                  )
                : idx === index + 1),
          );
          seeds.push({
            teamLogs: [
              firstTeamExistsOnCurrentRound || ({} as ITeamLog),
              secondTeamExistsOnCurrentRound || ({} as ITeamLog),
            ],
          });
        }
        const newRound: RoundProps = {
          title: GetRoundName(i + 1, totalRound),
          seeds,
        };
        newRounds.push(newRound);
      }
      setRounds(newRounds);
    }
  }, [selectedGroup, selectedStage, teamLogs, totalRound]);

  const selectTeam = (teamLog: ITeamLog) => {
    if (teamLog.team) {
      setModalType(STANDING_MODAL_TYPE.player);
      setSelectedTeam(teamLog.team);
      if (isLoggedin) getPlayerList(teamLog.team.id);
    }
  };

  const CustomSeed = (seed: any, breakpoint: number, roundIndex: number) => {
    const isLineConnector =
      rounds[roundIndex].seeds.length === rounds[roundIndex + 1]?.seeds.length;
    const Wrapper = isLineConnector ? SingleLineSeed : Seed;
    const isFirstTeamWin =
      seed.teamLogs[0] &&
      (roundIndex === totalRound - 1
        ? teamLogs?.find(
            (x) => x.round - 2 === roundIndex && x.team?.id === seed.teamLogs[0].team?.id,
          )
        : rounds.find(
            (r) =>
              r.title === GetRoundName(roundIndex + 2, totalRound) &&
              r.seeds
                .reduce((acc, curr) => [...acc, curr.teamLogs[0], curr.teamLogs[1]], [])
                .map((t: ITeamLog) => t.team?.id)
                .filter((x: number) => x)
                .includes(seed.teamLogs[0].team?.id),
          ));
    const isSecondTeamWin =
      seed.teamLogs[1] &&
      (roundIndex === totalRound - 1
        ? teamLogs?.find(
            (x) => x.round - 2 === roundIndex && x.team?.id === seed.teamLogs[1].team?.id,
          )
        : rounds.find(
            (r) =>
              r.title === GetRoundName(roundIndex + 2, totalRound) &&
              r.seeds
                .reduce((acc, curr) => [...acc, curr.teamLogs[0], curr.teamLogs[1]], [])
                .map((t: ITeamLog) => t.team?.id)
                .filter((x: number) => x)
                .includes(seed.teamLogs[1].team?.id),
          ));
    return (
      <Wrapper mobileBreakpoint={breakpoint} style={{ fontSize: 12 }}>
        <SeedItem>
          <div>
            <SeedTeam
              className={`seed ${isFirstTeamWin ? 'winner-seed' : ''}`}
              onClick={() => selectTeam(seed.teamLogs[0])}
            >
              <Typography.Text className="text-size-12 text-height-18">
                {seed.teamLogs[0]?.team?.id ? (
                  <Typography.Text className="text-size-9 text-height-13 mr--5">
                    {(sortedTeamLogs?.findIndex((s) => s.team?.id === seed.teamLogs[0].team?.id) ||
                      0) + 1}
                  </Typography.Text>
                ) : undefined}
                {seed.teamLogs[0]?.team?.name || '-'}
              </Typography.Text>
            </SeedTeam>
            <SeedTeam
              className={`seed ${isSecondTeamWin ? 'winner-seed' : ''}`}
              onClick={() => selectTeam(seed.teamLogs[1])}
            >
              <Typography.Text className="text-size-12 text-height-18">
                {seed.teamLogs[1]?.team?.id ? (
                  <Typography.Text className="text-size-9 text-height-13 mr--5">
                    {(sortedTeamLogs?.findIndex((s) => s.team?.id === seed.teamLogs[1].team?.id) ||
                      0) + 1}
                  </Typography.Text>
                ) : undefined}
                {seed.teamLogs[1]?.team?.name || '-'}
              </Typography.Text>
            </SeedTeam>
          </div>
        </SeedItem>
      </Wrapper>
    );
  };

  React.useEffect(() => {
    let color = 'white';
    const level = teamLog.data?.stage?.level || -1;
    const round = teamLog.data?.round || -1;
    const currentStage: IStage | undefined = stage.list?.find(
      (s) =>
        moment().isSameOrAfter(moment.unix(s.startAt)) &&
        moment().isSameOrBefore(moment.unix(s.endAt)),
    );

    if (currentStage) {
      if (level < currentStage.level) {
        color = 'red';
      }
      if (level === currentStage.level && round < totalRound) {
        color = 'blue';
      }
      if (level === currentStage.level && round >= totalRound) {
        color = 'green';
      }
    }
    setPositionColor(color);
  }, [selectedGroup, sortedTeamLogs, stage.list, teamLog.data, totalRound]);

  const RenderCurrentStage = () => {
    let status = '';
    const currentCup: ICup | undefined =
      selectedGroup?.cup || selectedStage?.cup || cup.data?.value;
    if (currentCup && moment().isBefore(moment.unix(currentCup?.startAt))) {
      status = 'Kompetisi Belum Dimulai';
    }
    if (currentCup && moment().isAfter(moment.unix(currentCup?.startAt))) {
      status = 'Kompetisi Selesai';
    }
    const currentStage: IStage | undefined = stage.list?.find(
      (s) =>
        moment().isSameOrAfter(moment.unix(s.startAt)) &&
        moment().isSameOrBefore(moment.unix(s.endAt)),
    );
    return (
      <>
        <Row>
          <Typography.Text className="text-size-12 text-height-14 text-weight-medium">
            {currentStage?.name || status}
          </Typography.Text>
        </Row>
        <Row className="mt--5">
          <Typography.Text className="text-size-14 text-height-16 text-weight-medium">
            {currentStage?.startAt && currentStage?.endAt
              ? `${moment
                  .unix(currentStage?.startAt)
                  .format(
                    moment.unix(currentStage?.startAt).format('MMM') !==
                      moment.unix(currentStage?.endAt).format('MMM')
                      ? 'DD'
                      : 'DD MMM',
                  )}${
                  moment.unix(currentStage?.startAt).format('DD MMM') !==
                  moment.unix(currentStage?.endAt).format('DD MMM')
                    ? ` - ${moment.unix(currentStage?.endAt).format('DD MMM')}`
                    : ''
                }`
              : '-'}
          </Typography.Text>
        </Row>
      </>
    );
  };

  return (
    <Layout>
      <Row>
        <Col span={24}>
          <Image
            src={selectedGroup?.cup?.image || selectedStage?.cup?.image || cup.data?.value?.image}
            width={'100%'}
            preview={false}
          />
          <Row className="m-1">
            <Typography.Text className="text-size-18 text-height-21 text-weight-bold">
              {selectedGroup?.cup?.name || selectedStage?.cup?.name || cup.data?.value?.name}
            </Typography.Text>
          </Row>
          <Row className="m-1">
            <Col span={12}>{RenderCurrentStage()}</Col>
            <Col span={12}>
              <Row>
                <Typography.Text className="text-size-12 text-height-14 text-weight-medium">
                  Hadiah
                </Typography.Text>
              </Row>
              <Row className="mt--5">
                <Typography.Text className="text-size-14 text-height-16 text-weight-medium">
                  {selectedGroup?.cup?.totalPrize && selectedGroup.cup.totalPrize > 0
                    ? Numberize(selectedGroup.cup.totalPrize || 0, '.', 'Rp')
                    : selectedStage?.cup?.totalPrize && selectedStage.cup.totalPrize > 0
                    ? Numberize(selectedStage.cup.totalPrize || 0, '.', 'Rp')
                    : cup.data?.value?.totalPrize && cup.data?.value.totalPrize > 0
                    ? Numberize(cup.data.value?.totalPrize || 0, '.', 'Rp')
                    : 'Rp 0'}
                </Typography.Text>
              </Row>
            </Col>
          </Row>
          <Row className="mh-1">
            <Col span={24}>
              {[
                ...(teamLog.data?.stage
                  ? [
                      {
                        label: teamLog.data.stage.externalUrlLabel,
                        link: teamLog.data.stage.externalUrl,
                      },
                    ]
                  : []),
                ...(teamLog.data?.group
                  ? [
                      {
                        label: teamLog.data.group.externalUrlLabel,
                        link: teamLog.data.group.externalUrl,
                      },
                    ]
                  : []),
                {
                  label: 'Peraturan Cup',
                  link: `/${
                    selectedGroup?.cup?.slug || selectedStage?.cup?.slug || cup.data?.value?.slug
                  }`,
                },
              ].map((item: { label?: string; link?: string }) => {
                if (item.label && item.link) {
                  return (
                    <Row key={item.label} className="mt-1">
                      <Button type="primary" size="large" block href={item.link}>
                        {item.label}
                      </Button>
                    </Row>
                  );
                }
                return undefined;
              })}
            </Col>
          </Row>
          {isLoggedin && teamLog.data ? (
            <Row className="mh-1 mv--5">
              <Typography.Text className="text-size-12 text-height-18 text-weight-medium">
                Tim Kamu
              </Typography.Text>
            </Row>
          ) : undefined}
          {!isLoggedin || (isLoggedin && teamLog.data) ? (
            <Row className="mh-1 mt-1 mb-1">
              <Col span={24} className={`summary p-1 ${isLoggedin ? '' : 'bg-blue-20'}`}>
                {!isLoggedin ? (
                  <Row align="middle" justify="end">
                    <Col flex="auto">
                      <Typography.Text className="text-size-14 text-height-16 text-weight-medium">
                        Masuk untuk mengetahui skor Kamu
                      </Typography.Text>
                    </Col>
                    <Button
                      type="primary"
                      href={`${ENV.MY_URL}/login?referrer=${Encrypt('', window.location.href)}`}
                      size="large"
                    >
                      Masuk
                    </Button>
                  </Row>
                ) : (
                  <Row align="middle" justify="end">
                    <Col span={16}>
                      <Row>
                        <Typography.Text className="text-size-18 text-height-21 text-weight-medium">
                          {teamLog.data?.team?.name}
                        </Typography.Text>
                      </Row>
                      <Row>
                        <Typography.Text
                          className={`text-color-${positionColor} text-size-12 text-height-19`}
                        >
                          {teamLog.data?.status || '-'}
                        </Typography.Text>
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row justify="end">
                        <Typography.Text className="text-size-12 text-height-19">
                          {teamLog.data?.stage?.name || '-'}
                        </Typography.Text>
                      </Row>
                      <Row justify="end">
                        <Typography.Text className="text-size-12 text-height-19">
                          {teamLog.data?.group?.name || '-'}
                        </Typography.Text>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Divider className="bg-grey p--25" />
      <Row className="m-1">
        <Col span={12}>
          <Row>
            <Typography.Text className="text-size-14 text-height-21">Babak</Typography.Text>
          </Row>
          <Row className="mt--5">
            <Select
              value={selectedStage?.id}
              onChange={(value: number) => {
                const selected = [...(stage.list || []), ...[winnerStage]].find(
                  (s) => s.id === value,
                );
                setSelectedStage(selected);
              }}
              className="standing-selector mr--5"
              dropdownClassName="standing-dropdown"
            >
              {[
                ...(stage.list || []),
                ...(stage.list && stage.list.length > 0 ? [winnerStage] : []),
              ].map((s) => {
                return (
                  <Option key={s.id} value={s.id}>
                    {s.name}
                  </Option>
                );
              })}
            </Select>
          </Row>
        </Col>
        <Col span={12}>
          <Row className="ml--5">
            <Typography.Text className="text-size-14 text-height-21">Grup</Typography.Text>
          </Row>
          <Row className="mt--5">
            <Select
              value={selectedGroup?.id}
              onChange={(value: number) => {
                const selected = group.list?.find((g) => g.id === value);
                setSelectedGroup(selected);
              }}
              className="standing-selector ml--5"
              dropdownClassName="standing-dropdown"
              disabled={selectedStage?.hasPrize}
            >
              {group.list?.map((g) => {
                return (
                  <Option key={g.id} value={g.id}>
                    {g.name}
                  </Option>
                );
              })}
            </Select>
          </Row>
        </Col>
      </Row>
      {selectedStage?.type === STAGE_TYPE.table ? (
        <Row>
          <Table
            className="standing-table"
            rowClassName={(record: ITeamLog) =>
              isLoggedin && record.id === teamLog.data?.id ? 'active' : ''
            }
            columns={
              selectedStage?.hasPrize
                ? [
                    {
                      title: 'Nama Tim',
                      render: (_value, record) => (
                        <Typography.Text className="text-size-16 text-height-24">
                          {record.team?.name}
                        </Typography.Text>
                      ),
                    },
                    {
                      title: 'Juara',
                      render: (_value, record) => (
                        <Typography.Text className="text-size-16 text-height-24 text-weight-medium">
                          {record.teamReward?.rewardTitle || '-'}
                        </Typography.Text>
                      ),
                      align: 'right',
                    },
                  ]
                : [
                    {
                      title: 'No',
                      render: (_value, _record, index) => (
                        <Typography.Text className="text-size-14 text-height-16">
                          {index + 1}.
                        </Typography.Text>
                      ),
                      align: 'left',
                    },
                    {
                      title: 'Nama Tim',
                      render: (_value, record) => (
                        <Typography.Text className="text-size-14 text-height-16">
                          <sup>
                            {(sortedTeamLogs?.findIndex((s) => s.team?.id === record?.team?.id) ||
                              0) + 1}
                          </sup>
                          {record.team?.name}
                        </Typography.Text>
                      ),
                    },
                    {
                      title: 'Skor',
                      render: (_value, record) => (
                        <Typography.Text className="text-size-14 text-height-16">{`${
                          record.score && record.score > 0 ? Numberize(record.score) : 0
                        } poin`}</Typography.Text>
                      ),
                      align: 'right',
                    },
                  ]
            }
            dataSource={teamLogs?.sort((a, b) => ((a.score || 0) < (b.score || 0) ? 1 : -1))}
            rowKey="id"
            pagination={false}
            loading={teamLog.requesting}
            locale={{ emptyText: `Tabel ${selectedStage?.name} belum diperbarui` }}
            onRow={(record) => {
              return {
                onClick: () => {
                  selectTeam(record);
                },
              };
            }}
          />
        </Row>
      ) : (
        <Row className="standing-bracket" justify={rounds.length > 0 ? 'start' : 'center'}>
          {rounds.length > 0 ? (
            <Bracket
              rounds={rounds}
              renderSeedComponent={CustomSeed}
              mobileBreakpoint={300}
              roundClassName="round"
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Row>
      )}
      <Modal
        title={isLoggedin ? selectedTeam?.name : 'Mohon Gunakan Akun EVOS Network'}
        visible={modalType === STANDING_MODAL_TYPE.player}
        onCancel={() => setModalType(STANDING_MODAL_TYPE.undefined)}
        footer={null}
      >
        {isLoggedin ? (
          <List
            grid={{
              column: 1,
            }}
            dataSource={player.list}
            renderItem={(item: IPlayer, index: number) => {
              return (
                <List.Item>
                  <Row>
                    <Col span={24} className="summary p-1">
                      <Row>
                        <Typography.Text className="text-size-16 text-height-24">
                          {DisplayPlayerData(item, true)}
                        </Typography.Text>
                        {index === 0 && player.list && player.list.length > 1 ? (
                          <div className="icon-captain-badge text-color-yellow text-size-14" />
                        ) : undefined}
                      </Row>
                    </Col>
                  </Row>
                </List.Item>
              );
            }}
            loading={player.requesting}
          />
        ) : (
          <>
            <Row>
              <Typography.Paragraph>
                Kamu perlu masuk menggunakan akun EVOS Network untuk melihat konten ini. Silakan
                daftar terlebih dahulu jika belum memiliki.
              </Typography.Paragraph>
            </Row>
            <Row>
              <Button
                type="primary"
                block
                href={`${ENV.MY_URL}/login?referrer=${Encrypt('', window.location.href)}`}
                size="large"
              >
                Masuk
              </Button>
            </Row>
            <Row className="mt-1">
              <Button
                ghost
                block
                href={`${ENV.MY_URL}/login?referrer=${Encrypt('', window.location.href)}`}
                size="large"
              >
                Daftar
              </Button>
            </Row>
          </>
        )}
      </Modal>
    </Layout>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  group: state.group,
  stage: state.stage,
  teamLog: state.teamLog,
  player: state.player,
  cup: state.cup,
});

const mapDispatchToProps = (dispatch: any) => ({
  getGroupList: (stageId: number) =>
    dispatch(Actions.group.GetList(stageId, undefined, undefined, false)),
  getStageList: (cupId: number) =>
    dispatch(Actions.stage.GetList(cupId, undefined, undefined, false)),
  getLatestTeamLog: (cupId: number) => dispatch(Actions.teamLog.GetLatest(cupId, false)),
  getTeamLogList: (cupId: number, stageId?: number, groupId?: number) =>
    dispatch(Actions.teamLog.GetList(cupId, stageId, groupId, false)),
  getPlayerList: (teamId: number) =>
    dispatch(Actions.player.GetList(teamId, undefined, undefined, false)),
  getCupById: (id: number) => dispatch(Actions.cup.GetDetail(id, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StandingPage);
