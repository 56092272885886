import { Reducer } from 'redux';
import ActionTypes from '../../constants/action-types';
import { BaseCupStateDefault, ICupState } from '../../interfaces/states/cup';

const initialState: ICupState = BaseCupStateDefault;

const reducer: Reducer<any> = (state: ICupState = initialState, action): ICupState => {
  switch (action.type) {
    case ActionTypes.cup.GET_CUP_LIST_REQUEST: {
      const { cupSection, cupOwner } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          [`${cupOwner}_${cupSection}`]: {
            ...state.list[`${cupOwner}_${cupSection}`],
            value: state.list[`${cupOwner}_${cupSection}`]?.value || [],
            requesting: true,
            errors: undefined,
          },
        },
      };
    }
    case ActionTypes.cup.GET_CUP_LIST_SUCCESS: {
      const { cupSection, cupOwner, list, paging } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          [`${cupOwner}_${cupSection}`]: {
            ...state.list[`${cupOwner}_${cupSection}`],
            requesting: false,
            paging,
            value:
              paging.currentPage === 1
                ? [...(list || [])]
                : [...(state.list[`${cupOwner}_${cupSection}`].value || []), ...list],
          },
        },
      };
    }
    case ActionTypes.cup.GET_CUP_LIST_FAILED: {
      const { cupSection, cupOwner, error } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          [`${cupOwner}_${cupSection}`]: {
            ...state.list[`${cupOwner}_${cupSection}`],
            requesting: false,
            errors: error,
          },
        },
      };
    }
    case ActionTypes.cup.GET_CUP_DETAIL_REQUEST: {
      return {
        ...state,
        data: {
          ...state.data,
          requesting: true,
          errors: undefined,
        },
      };
    }
    case ActionTypes.cup.GET_CUP_DETAIL_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          requesting: false,
          value: data,
        },
      };
    }
    case ActionTypes.cup.GET_CUP_DETAIL_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          requesting: false,
          errors: error,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
