import IDashboard from '@evos/general-particle/dist/interfaces/efc/dashboard';
import IBaseResponse from '@evos/general-particle/dist/interfaces/api/result';

const GetSummary = (): IBaseResponse<IDashboard> => ({
  success: true,
  data: {
    totalPrizes: 1200000,
    totalCupParticipations: 27,
  },
});

export default {
  GetSummary,
};
