/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import FullStory from 'react-fullstory';
import TagManager from 'react-gtm-module';
import App from './app';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReactPixel from 'react-facebook-pixel';
import { rootReducer } from './store/reducers';
import moment from 'moment';
import 'moment/locale/id';
import { createLogger } from 'redux-logger';
import ENV from './utils/environment';
import STORAGE from './constants/cookie';
import GeneralParticle from '@evos/general-particle';
import * as pkg from '../package.json';

const { Get, Set } = GeneralParticle.Utils.Storage;

moment.locale('id');

const middlewares = [];
middlewares.push(thunkMiddleware);
if (ENV.IS_DEBUG) {
  middlewares.push(createLogger());
}

if (ENV.SENTRY_ENABLE) {
  Sentry.init({
    dsn: 'https://63c63feeab044e4d97be968ff0d5d1bf@o275789.ingest.sentry.io/5638526',
    environment: process.env.REACT_APP_ENV,
    ignoreErrors: [
      'getReadModeExtract',
      'getReadModeRender',
      'getReadModeConfig',
      'ResizeObserver loop limit exceeded',
      'ToutiaoJSBridge',
    ],
  });
  Sentry.setContext('app_info', {
    version: pkg.version,
  });
}

if (ENV.GA_ENABLE) {
  const tagManagerArgs = {
    gtmId: 'GTM-5M6PG8G',
  };
  TagManager.initialize(tagManagerArgs);
}

if (ENV.FBPIXEL_ENABLE) {
  ReactPixel.init('2592320190990486', undefined, { autoConfig: true, debug: false });
  ReactPixel.pageView();
}

// FullStory sampling
const FS_RANDOM_RANGE = 1000;
const FS_RANDOM_THRESHOLD = 50;
let fsRandom = Get(STORAGE.fsRand + ENV.COOKIE_SUFFIX);
if (!fsRandom) {
  const temp = Math.floor(Math.random() * FS_RANDOM_RANGE);
  Set(STORAGE.fsRand + ENV.COOKIE_SUFFIX, temp, ENV.COOKIE_DOMAIN);
  fsRandom = temp;
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));
const root = document.getElementById('root');

if (root) {
  ReactDOM.render(
    <Provider store={store}>
      {ENV.FS_ENABLE && fsRandom < FS_RANDOM_THRESHOLD ? <FullStory org="119XQP" /> : undefined}
      <App />
    </Provider>,
    root,
  );
}
